import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { UserListTable } from 'features/user/table';

const pageParamsSchema = z.object({
  groupId: z.string(),
});

export function UserGroupUserListPage() {
  const { groupId } = useTypedParams(pageParamsSchema);

  return <UserListTable groupId={groupId} />;
}
