import { useSearchParams } from 'react-router-dom';

import { getQueryParams } from 'shared/lib/router/get-query-params';
import { DebouncedInput } from 'shared/ui/debounced-input';

type Props = {
  paramName: string;
  disabled?: boolean;
};

export function InputFilter({ paramName, disabled = false }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const param = searchParams.get(paramName);

  const handleChange = (val: string) => {
    const queryParams = getQueryParams();

    if (val === '') {
      delete queryParams[paramName];

      setSearchParams({ ...queryParams });
    } else {
      setSearchParams({
        ...queryParams,
        [paramName]: val,
      });
    }
  };

  return (
    <DebouncedInput
      value={param || ''}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}
