import { useChargePointFetchingContext } from 'entities/charge-point/charge-point-context';
import { useParams } from 'react-router-dom';

export function ChargePointReservesPage() {
  const { chargePointName, groupId } = useParams() as {
    chargePointName: string;
    groupId: string;
  };

  const { handleSetFetchingTab } = useChargePointFetchingContext();

  return null;
}
