import { Cell, flexRender, Header, Row, Table } from '@tanstack/react-table';
import { Spin, Typography } from 'antd';

import { SortingButton } from '../../sorting/button';
import {
  HeaderCellContainer,
  HeaderCellContent,
  HeaderCellText,
  StyledFixedTable,
  TablaLayoutContainer,
  TableContainer,
  TableControls,
} from './styles';

const { Text } = Typography;

type Props = {
  table: Table<any>;
  loading: boolean;
  handleRowDoubleClick?: (row: Row<any>) => void;
  handleSortChange: (
    header: Header<any, unknown>
  ) => ((event: unknown) => void) | undefined;
  getTdClassName: (cell: Cell<any, unknown>) => string;
  tableControls: JSX.Element;
  tableRef?: React.MutableRefObject<null>;
};

export function TableLayout({
  table,
  loading,
  handleSortChange,
  handleRowDoubleClick,
  getTdClassName,
  tableRef,
  tableControls,
}: Props) {
  return (
    <TablaLayoutContainer>
      <TableContainer>
        <Spin spinning={loading}>
          <StyledFixedTable ref={tableRef}>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const filterElement =
                      header.column.columnDef.meta?.filterElement;

                    return (
                      <th
                        {...{
                          key: header.id,
                          style: {
                            width: header.getSize(),
                          },
                          className: `${
                            header.column.getIsSorted() !== false
                              ? 'sorted'
                              : ''
                          }${header.id === 'settings' ? 'settings' : ''}`,
                        }}
                      >
                        <HeaderCellContainer>
                          <HeaderCellContent
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer'
                                : '',
                              onClick:
                                header.id !== 'settings' &&
                                header.column.getCanSort()
                                  ? handleSortChange(header)
                                  : undefined,
                            }}
                          >
                            <HeaderCellText className="header">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </HeaderCellText>
                            {header.id !== 'settings' &&
                            header.column.getCanSort() ? (
                              <SortingButton
                                isDesc={header.column.getIsSorted() === 'desc'}
                              />
                            ) : null}
                          </HeaderCellContent>
                          {filterElement || null}
                        </HeaderCellContainer>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  {...(handleRowDoubleClick
                    ? {
                        onDoubleClick: () => handleRowDoubleClick(row),
                        className: 'clickable',
                      }
                    : {})}
                >
                  {row.getVisibleCells().map((cell) => {
                    const cellContent = flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    );

                    return (
                      <td
                        key={cell.id}
                        className={getTdClassName(cell)}
                        style={{
                          width: cell.column.getSize(),
                        }}
                      >
                        <Text
                          style={{ width: cell.column.getSize() }}
                          ellipsis={{
                            tooltip: cellContent,
                          }}
                        >
                          {cellContent}
                        </Text>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </StyledFixedTable>
        </Spin>
      </TableContainer>
      <TableControls>{tableControls}</TableControls>
    </TablaLayoutContainer>
  );
}
