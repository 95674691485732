import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import queryString from 'query-string';

import { setCredentials, clearCredentials } from '../slices/auth/authSlice';
import {
  clearSessionCredentials,
  setSessionCredentials,
} from '../slices/auth/authSessionSlice';
import { RootState } from '../store';
import { RefreshTokensResponse } from '../../models/identity';

const mutex = new Mutex();

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: `${__API_BASE__}api/`,
    // credentials: 'include', // если есть кука - цепляем её
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).auth.accessToken ||
        (getState() as RootState).authSession.accessToken;

      if (token && !headers.has('Authorization')) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
    paramsSerializer: (params: Record<string, unknown>) =>
      queryString.stringify(params, { skipEmptyString: true, skipNull: true }),
  }),
  { maxRetries: 0 } //
);

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  const refreshToken =
    (api.getState() as RootState).auth.refreshToken ||
    (api.getState() as RootState).authSession.refreshToken;

  const rememberUser = (api.getState() as RootState).auth.accessToken;

  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          {
            url: '/platform/Identity/RefreshTokens',
            method: 'POST',
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          const { accessToken } = refreshResult.data as RefreshTokensResponse;

          const credentials = {
            accessToken,
          };

          rememberUser
            ? api.dispatch(setCredentials(credentials))
            : api.dispatch(setSessionCredentials(credentials));

          result = await baseQuery(args, api, extraOptions);
        } else {
          rememberUser
            ? api.dispatch(clearCredentials())
            : api.dispatch(clearSessionCredentials());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'BankCardSessions',
    'Banks',
    'Bank',
    'BonusBalance',
    'BonusPrograms',
    'BonusProgram',
    'BonusLevels',
    'CarBrands',
    'CarBrand',
    'CarModels',
    'CarModel',
    'Cars',
    'Car',
    'ChargePoints',
    'ChargePoint',
    'ChargePointGroups',
    'ChargePointPhotoUrls',
    'ChargePointPhoto',
    // 'ChargePointRights',
    'ClientBindings',
    'Commands',
    'Companies',
    'Company',
    'Connectors',
    'Connector',
    'ConnectorInstructions',
    'ConnectorInstruction',
    'Countries',
    'Country',
    'Cpos',
    'Cpo',
    'Discounts',
    'Discount',
    'Firmwares',
    'GraphMetrics', // потом добавить Metrics (POST => GET)
    'Group',
    'Hubs',
    'Hub',
    'UsersGroups',
    'UsersGroup',
    'MobileApps',
    'MobileApp',
    'OcpiHubs',
    'OcpiHub',
    'Owners',
    'Owner',
    'OwnersMaxBonusPay',
    'Persons',
    'Person',
    'PersonsGroups',
    'Packets',
    'PushNotifications',
    'PushNotification',
    'Reports',
    'Role',
    'Roles',
    'RoleClaims',
    'RoleChargePointRights',
    'RoleGroupChargePointRights',
    'RoleRights',
    'Sessions',
    'SessionsReserves',
    'SettingsList',
    'Settings',
    'StatusHistory',
    'Tariffs',
    'Tariff',
    'Transactions',
    'Users',
    'User',
    'UserChargePointRights',
    'UsersGroups',
    'UserGroupChargePointRights',
    'UserRights',
    'UserRoles',
    'UserClaims',
  ],
  endpoints: (builder) => ({}),
});
