import { useAbility } from 'shared/lib/ability/context';

import { SectionNavMenuItem } from 'features/sidebar/types';
import { CountriesIcon } from 'features/sidebar/icons/countries';
import { BanksIcon } from 'features/sidebar/icons/banks';
import { ConnectorInstructionsIcon } from 'features/sidebar/icons/connector-instructions';
import { AppSettingsIcon } from 'features/sidebar/icons/app-settings';
import { CompaniesIcon } from 'features/sidebar/icons/companies';
import { OcpiHubsIcon } from 'features/sidebar/icons/ocpi-hubs';
import { CposIcon } from 'features/sidebar/icons/cpos';
import { CarModelsIcon } from 'features/sidebar/icons/car-models';
import FirmwaresIcon from '../../icons/firmwares.png';
import MobileAppsIcon from '../../icons/mobile-apps.png';

import { SidebarLayout } from '../layout';

// TODO: вынести в константы

const ITEMS: SectionNavMenuItem[] = [
  {
    label: 'Страны',
    path: 'countries',
    icon: <CountriesIcon />,
    permissions: [
      {
        controller: 'Country',
        right: 'Write',
      },
    ],
  },
  {
    label: 'Банки',
    path: 'banks',
    icon: <BanksIcon />,
    permissions: [
      {
        controller: 'Bank',
        right: 'Write',
      },
    ],
  },
  {
    label: 'Инструкции коннекторов',
    path: 'connector-instructions',
    icon: <ConnectorInstructionsIcon />,
    permissions: [
      {
        controller: 'Connector',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Настройки приложения',
    path: 'app-settings',
    icon: <AppSettingsIcon />,
    permissions: [
      {
        controller: 'Settings',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Контрагенты',
    path: 'companies',
    icon: <CompaniesIcon />,
    permissions: [
      {
        controller: 'Person',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Прошивки',
    path: 'firmwares',
    icon: <img src={FirmwaresIcon} />,
    permissions: [
      {
        controller: 'Firmware',
        right: 'Read',
      },
    ],
  },
  {
    label: 'OCPI Хабы',
    path: 'ocpi-hubs',
    icon: <OcpiHubsIcon />,
    permissions: [
      {
        controller: 'OcpiHubs',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Операторы ЭЗС',
    path: 'cpos',
    icon: <CposIcon />,
    permissions: [
      {
        controller: 'Cpo',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Мобильные приложения',
    path: 'mobile-apps',
    icon: <img src={MobileAppsIcon} />,
    permissions: [
      {
        controller: 'MobileApp',
        right: 'Write',
      },
    ],
  },
  {
    label: 'Марки EV',
    path: 'car-brands',
    icon: <CarModelsIcon />,
    permissions: [
      {
        controller: 'Car',
        right: 'Read',
      },
    ],
  },
];

export function SettingsSidebar() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter((item) =>
    item.permissions.every((item) => {
      const { controller, right } = item;

      return ability.can(right, controller);
    })
  );

  return <SidebarLayout items={allowedItems} />;
}
