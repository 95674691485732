import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getQueryParams } from 'shared/lib/router/get-query-params';
import { UiSelect } from 'shared/ui/ui-kit';

import { SelectFilterContainer } from './styles';

type Props = {
  options: Array<{
    label: string;
    value: string;
  }>;
  paramName: string;
  disabled?: boolean;
};

export function SelectFilter({ options, paramName, disabled = false }: Props) {
  const [value, setValue] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  const param = searchParams.get(paramName);

  useEffect(() => {
    if (param) {
      setValue(param);
    }
  }, [param]);

  const handleChange = (val: string) => {
    const queryParams = getQueryParams();

    if (val === '') {
      delete queryParams[paramName];

      setSearchParams({ ...queryParams });
    } else {
      setSearchParams({
        ...queryParams,
        [paramName]: val,
      });
    }
  };

  return (
    <SelectFilterContainer>
      <UiSelect
        onChange={handleChange}
        value={value}
        options={options}
        disabled={disabled}
      />
    </SelectFilterContainer>
  );
}
