import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { UiInput } from 'shared/ui/ui-kit';

import {
  FieldErrorContainer,
  FormItemContainer,
  FormItemLabel,
} from '../styles';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
} & React.ComponentProps<typeof UiInput>;

export function CustomInput<T extends FieldValues>({
  label,
  required,
  disabled,
  errorMessage,
  type = 'text',
  name,
  ...rest
}: Props<T>) {
  const { control } = useFormContext();

  return (
    <FormItemContainer>
      <FormItemLabel required={required}>{label}</FormItemLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <UiInput {...field} type={type} disabled={disabled} {...rest} />
        )}
      />
      <FieldErrorContainer>{errorMessage}</FieldErrorContainer>
    </FormItemContainer>
  );
}
