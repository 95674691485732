import { z } from 'zod';

import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import {
  useGetOwners,
  useGetOwnersCount,
} from 'shared/api/services/chargepoint/enhanced';

import { TableInstance } from './table';

const pageQueryParamsSchema = z.object({
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
  connectorId: z.string().optional(),
  chargePointId: z.string().optional(),
});

// TODO: бэк исправит, чтобы count принимал query параметры

export function TableContainer() {
  const usersqueryParams = useTypedQueryParamsCustom(pageQueryParamsSchema);

  const {
    data: owners,
    error: ownersError,
    isLoading: isOwnersLoading,
    isFetching: isOwnersFetching,
  } = useGetOwners({
    ...usersqueryParams,
    offset: Math.max(usersqueryParams.page - 1, 0) * usersqueryParams.limit,
  });

  const {
    data: ownersCount,
    error: ownersCountError,
    isFetching: isOwnersCountFetching,
    isLoading: isOwnersCountLoading,
  } = useGetOwnersCount();

  const isLoading = isOwnersLoading || isOwnersCountLoading;
  const isFetching = isOwnersFetching || isOwnersCountFetching;

  const apiResponseError = [owners, ownersCount].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [ownersError, ownersCountError].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!owners?.data || !ownersCount || ownersCount.data === undefined) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={owners.data || []}
      totalCount={ownersCount.data}
    />
  );
}
