import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardFormWrapper } from '../../../../common-styles';

import { MainContentLayout } from 'shared/ui/layout';
import { ChargePointFormTabs } from 'entities/charge-point/ui/form/tabs';
import {
  GetGroupVm,
  GetHubVm,
  GetOwnerVm,
} from 'shared/api/services/chargepoint/generated';
import { useAddChargePoint } from 'shared/api/services/chargepoint/enhanced';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { buildOcppConfigObject } from 'entities/charge-point/lib/build-ocpp-config-object';

import { Form as TechInfoForm } from './tech-info';
import { Form as CardSettingsForm } from './card-settings';
import { Form as OcppConfigForm } from './ocpp-config';
import {
  OcppConfigFormOutput,
  CardSettingsFormOutput,
  TechInfoFormOutput,
  FormOutput,
  DEFAULT_VALUES,
} from '../consts/schema';
import { ADD_ERROR, ADD_SUCCESS } from '../consts';

type Props = {
  groups: GetGroupVm[];
  hubs: GetHubVm[];
  owners: GetOwnerVm[];
};

export function Form({ groups, hubs, owners }: Props) {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({ ...DEFAULT_VALUES });

  const updateFields = (fields: Partial<FormOutput>) => {
    setData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const [trigger, { isLoading }] = useAddChargePoint();

  const handleSubmit = async (values: FormOutput) => {
    try {
      const req = {
        ...values,
        ocppConfig: buildOcppConfigObject(values.ocppConfig),
      };

      const res = await trigger(req).unwrap();

      openSuccessNotification(ADD_SUCCESS);

      navigate(CHARGE_POINT_ROUTES.CHARGE_POINTS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  const forms = [
    <TechInfoForm
      data={data}
      groups={groups}
      owners={owners}
      submit={(values: TechInfoFormOutput) => {
        updateFields(values);

        setStep(1);
      }}
    />,
    <CardSettingsForm
      data={data}
      submit={(values: CardSettingsFormOutput) => {
        updateFields(values);

        setStep(2);
      }}
      hubs={hubs}
    />,
    <OcppConfigForm
      data={data}
      submit={(values: OcppConfigFormOutput) => {
        updateFields(values);

        handleSubmit(data);
      }}
    />,
  ];

  const navigate = useNavigate();

  const onCancel = () => navigate(-1);

  // const disabledTabs = useMemo(() => {
  //   let arr: number[] = [];

  //   if (techInfo.name === '') {
  //     arr.push(1, 2, 3);

  //     return arr;
  //   }

  //   if (cardSettings.latitude === '') {
  //     arr.push(3);

  //     return arr;
  //   }

  //   return [];
  // }, [data]);

  return (
    <>
      <ChargePointFormTabs
        activeKey={step}
        onClick={() => {}}
        disabledTabs={[]}
      />
      <MainContentLayout
        element={<CardFormWrapper>{forms[step]}</CardFormWrapper>}
      />
    </>
  );
}
