import { MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';

import { UiPopover } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';
import { GetOwnerVm } from 'shared/api/services/chargepoint/generated';

import { DeleteOwnerModal } from '../../delete';

type Props = {
  owner: GetOwnerVm;
};

export function Actions({ owner }: Props) {
  const ability = useAbility();

  const navigate = useNavigate();

  const showDeleteModal = () => {
    NiceModal.show(DeleteOwnerModal, { owner });
  };

  const content = (
    <>
      <button onClick={() => navigate(owner.id)}>Редактировать</button>
      {ability.can('Write', 'Owner') && (
        <button onClick={showDeleteModal}>Удалить</button>
      )}
    </>
  );

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
