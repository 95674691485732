import { COLOR } from 'shared/consts';

import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 30px;
`;

export const PageControlsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PageSizeLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
`;

export const PagesTotalCount = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
  opacity: 0.5;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
