import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { UiFormCheckbox, UiInput } from 'shared/ui/ui-kit';

import {
  FieldErrorContainer,
  FormItemContainer,
  FormItemLabel,
} from '../styles';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
} & React.ComponentProps<typeof UiFormCheckbox>;

export function CustomCheckbox<T extends FieldValues>({
  label,
  // required,
  disabled,
  errorMessage,
  name,
  ...rest
}: Props<T>) {
  const { control } = useFormContext();

  return (
    <FormItemContainer>
      <Controller
        name={name}
        render={({ field, fieldState }) => {
          return (
            <UiFormCheckbox
              {...field}
              checked={field.value}
              disabled={disabled}
              {...rest}
            >
              {label}
            </UiFormCheckbox>
          );
        }}
      />
      <FieldErrorContainer>{errorMessage}</FieldErrorContainer>
    </FormItemContainer>
  );
}
