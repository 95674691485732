import { Table } from '@tanstack/react-table';

import { useQueryParamsPagination } from 'shared/ui/table-new';

import { PaginationControls } from '../controls';

type Props = {
  table: Table<any>;
  totalCount: number;
};

export function ServerPagination({ table, totalCount }: Props) {
  const { limit, page, setPage, setLimit } = useQueryParamsPagination();

  const prevDisabled = !table.getCanPreviousPage();
  const nextDisabled = !table.getCanNextPage();

  const onPrevClick = () => setPage(page - 1);
  const onNextClick = () => setPage(page + 1);

  const pagesCount = table.getPageCount();

  return (
    <PaginationControls
      prevDisabled={prevDisabled}
      nextDisabled={nextDisabled}
      onPrevClick={onPrevClick}
      onNextClick={onNextClick}
      page={page === 0 ? 1 : page + 1}
      limit={limit}
      setPage={setPage}
      setLimit={setLimit}
      totalCount={totalCount}
      pagesCount={pagesCount}
    />
  );
}
