import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { Form } from './form';
import {
  useGetCountry,
  useGetMobileApps,
} from 'shared/api/services/information/rtk';

const pageParamsSchema = z.object({
  countryId: z.string(),
});

export function FormContainer() {
  const { countryId } = useTypedParams(pageParamsSchema);

  const {
    isFetching: isCountryFetching,
    error: countryError,
    data: country,
  } = useGetCountry(countryId);

  const {
    data: mobileApps,
    isLoading: isMobileAppsLoading,
    error: mobileAppsError,
  } = useGetMobileApps();

  const isLoading = isCountryFetching || isMobileAppsLoading;

  const error = [mobileAppsError, countryError].find(
    (err) => err !== undefined
  );

  const apiResponseError = [mobileApps, country].find(
    (el) => el && el.status === 'ERROR'
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (!country?.data || !mobileApps?.data) {
    return <EmptyData />;
  }

  return <Form country={country.data} mobileApps={mobileApps.data} />;
}
