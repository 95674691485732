import NiceModal from '@ebay/nice-modal-react';

import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';
import { useAbility } from 'shared/lib/ability/context';

import { Modal } from '../modal';
import { StyledButton } from './styles';

type Props = {
  chargePointId: string;
};

export function AddButton({ chargePointId }: Props) {
  const ability = useAbility();

  if (!ability.can('Write', 'Connector')) {
    return null;
  }

  const onClick = () => {
    NiceModal.show(Modal, { chargePointId });
  };

  return (
    <StyledButton onClick={onClick} type="button">
      <PlusInCircleIcon />
    </StyledButton>
  );
}
