import { Link } from 'react-router-dom';

import { PERSON_ROUTES } from 'shared/consts/routes/person';
import { NavigationTabButton } from 'shared/ui';

export function AddUserGroupLink() {
  const path = PERSON_ROUTES.ADD_PERSONS_GROUP;

  return (
    <Link to={path}>
      <NavigationTabButton title="Добавить группу" />
    </Link>
  );
}
