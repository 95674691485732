import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { DraggerProps } from 'antd/lib/upload';

import { StyledButton } from './styles';

export function UiUploadDragger(props: DraggerProps) {
  return (
    <Upload.Dragger {...props}>
      <StyledButton
        htmlType="button"
        type="primary"
        icon={<UploadOutlined />}
        style={{ margin: '0 auto' }}
      >
        Выбрать файл
      </StyledButton>
    </Upload.Dragger>
  );
}
