export function ArrowDownIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 10.125C7.22386 10.125 7 10.3489 7 10.625C7 10.9011 7.22386 11.125 7.5 11.125V10.125ZM13.125 11.125C13.4011 11.125 13.625 10.9011 13.625 10.625C13.625 10.3489 13.4011 10.125 13.125 10.125V11.125ZM7.5 6.375C7.22386 6.375 7 6.59886 7 6.875C7 7.15114 7.22386 7.375 7.5 7.375V6.375ZM11.25 7.375C11.5261 7.375 11.75 7.15114 11.75 6.875C11.75 6.59886 11.5261 6.375 11.25 6.375V7.375ZM7.5 2.625C7.22386 2.625 7 2.84886 7 3.125C7 3.40114 7.22386 3.625 7.5 3.625V2.625ZM9.375 3.625C9.65114 3.625 9.875 3.40114 9.875 3.125C9.875 2.84886 9.65114 2.625 9.375 2.625V3.625ZM2.2487 8.80844C2.06525 8.60205 1.74921 8.58346 1.54282 8.76692C1.33643 8.95038 1.31784 9.26642 1.5013 9.47281L2.2487 8.80844ZM2.81574 10.199L2.44203 10.5311H2.44203L2.81574 10.199ZM4.68426 10.199L5.05797 10.5311L4.68426 10.199ZM5.9987 9.47281C6.18216 9.26642 6.16357 8.95038 5.95718 8.76692C5.75079 8.58346 5.43475 8.60205 5.2513 8.80844L5.9987 9.47281ZM3.25 10.625C3.25 10.9011 3.47386 11.125 3.75 11.125C4.02614 11.125 4.25 10.9011 4.25 10.625H3.25ZM4.25 3.125C4.25 2.84886 4.02614 2.625 3.75 2.625C3.47386 2.625 3.25 2.84886 3.25 3.125H4.25ZM7.5 11.125H13.125V10.125H7.5V11.125ZM7.5 7.375H11.25V6.375H7.5V7.375ZM7.5 3.625H9.375V2.625H7.5V3.625ZM1.5013 9.47281L2.44203 10.5311L3.18944 9.86677L2.2487 8.80844L1.5013 9.47281ZM5.05797 10.5311L5.9987 9.47281L5.2513 8.80844L4.31056 9.86677L5.05797 10.5311ZM2.44203 10.5311C3.13817 11.3143 4.36183 11.3143 5.05797 10.5311L4.31056 9.86677C4.01221 10.2024 3.48779 10.2024 3.18944 9.86677L2.44203 10.5311ZM4.25 10.625V3.125H3.25V10.625H4.25Z"
        fill="#28303F"
      />
    </svg>
  );
}
