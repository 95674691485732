import { ArrowDownIcon } from './arrow-down';
import { StyledSortingButton } from './styles';

type Props = {
  isDesc: boolean;
};

export function SortingButton({ isDesc }: Props) {
  return (
    <StyledSortingButton isDesc={isDesc}>
      <ArrowDownIcon />
    </StyledSortingButton>
  );
}
