import { Link } from 'react-router-dom';

import { CAR_BRAND_ROUTES } from 'shared/consts/routes/car-brands';
import { useAbility } from 'shared/lib/ability/context';
import { NavigationTabButton } from 'shared/ui';

export function AddCarModelLink() {
  const ability = useAbility();

  if (!ability.can('Write', 'Car')) {
    return null;
  }

  return (
    <Link to={CAR_BRAND_ROUTES.ADD_CAR_MODEL}>
      <NavigationTabButton title="Добавить модель" />
    </Link>
  );
}
