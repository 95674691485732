import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { ROOT } from 'shared/consts/names';

import { StyledContainer, StyledMenu } from './styles';
import { MenuItemWithPermissions } from './types';

type Props = {
  items: MenuItemWithPermissions[];
};

// TODO: add spinner

export function TabMenuLayout({ items }: Props) {
  const location = useLocation();

  const [current, setCurrent] = useState(() => {
    const activeKey = items.find((el) => location.pathname.endsWith(el.key));

    return activeKey?.key || ROOT;
  });

  useEffect(() => {
    if (location) {
      const activeKey = items.find((el) => location.pathname.endsWith(el.key));

      setCurrent(activeKey?.key || ROOT);
    }
  }, [location]);

  return (
    <StyledContainer>
      <StyledMenu
        items={items}
        mode="horizontal"
        selectedKeys={[current]}
        disabledOverflow={true}
      />
      {items.find((el) => el.key === current)?.extraContent}
    </StyledContainer>
  );
}
