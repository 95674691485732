import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';
import {
  GetCommandListParams,
  ManualCommandsResponse,
  SendManualCommandParams,
} from 'entities/manual-command';

import { StatusHistoryResponse } from '../../models/status';

import { apiSlice } from './apiSlice';

interface GetStatusHistoryParams {
  page: number;
  limit: number;
  cpName: string;
  type?: string;
  dateFrom?: string;
  dateTo?: string;
  ordering?: string;
}

// interface GetSessionsParams {
//   page: number;
//   limit: number;
//   type?: string;
//   phone?: string;
//   cpName?: string;
//   dateFrom?: string;
//   dateTo?: string;
//   search?: string;
//   searchText?: string;
//   ordering?: string;
//   groupId?: string;
//   isProblemFiscal?: boolean;
// }

// type GetSessionReservesParams = GetSessionsParams;

const KEY = 'PrivateMethods';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const privateMethodsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStatusHistory: builder.query<
      StatusHistoryResponse,
      GetStatusHistoryParams
    >({
      query: (params) => ({
        url: getUrl(`GetStatusHistory`),
        params,
      }),
      providesTags: (result, error, arg) => [{ type: 'StatusHistory' }],
    }),

    // getSessions: builder.query<SessionsResponse, GetSessionsParams>({
    //   query: ({
    //     page,
    //     limit,
    //     type,
    //     phone,
    //     cpName,
    //     dateFrom,
    //     dateTo,
    //     search,
    //     searchText,
    //     ordering,
    //     groupId,
    //     isProblemFiscal,
    //   }) =>
    //     getUrl(
    //       `GetSessions?groupId=${groupId ? groupId : 0}&page=${page}&${
    //         limit ? `limit=${limit}&` : ''
    //       }${type ? `type=${type}&` : ''}${phone ? `phone=${phone}&` : ''}${
    //         cpName ? `cpName=${cpName}&` : ''
    //       }${dateFrom ? `dateFrom=${dateFrom}&` : ''}${
    //         dateTo ? `dateTo=${dateTo}&` : ''
    //       }${search ? `search=${search}&` : ''}${
    //         searchText ? `searchText=${searchText}&` : ''
    //       }${ordering ? `ordering=${ordering}&` : ''}${
    //         isProblemFiscal ? `isProblemFiscal=${isProblemFiscal}&` : ''
    //       }`
    //     ),
    //   providesTags: (result, error, arg) => [{ type: 'Sessions' }],
    // }),
    // getSessionReserves: builder.query<
    //   ReserveSessionBaseResponse,
    //   GetSessionsParams
    // >({
    //   query: ({
    //     page,
    //     limit,
    //     type,
    //     phone,
    //     cpName,
    //     dateFrom,
    //     dateTo,
    //     search,
    //     searchText,
    //     ordering,
    //     groupId,
    //     isProblemFiscal,
    //   }) =>
    //     getUrl(
    //       `GetSessionReserves?groupId=${groupId ? groupId : 0}&page=${page}&${
    //         limit ? `limit=${limit}&` : ''
    //       }${type ? `type=${type}&` : ''}${phone ? `phone=${phone}&` : ''}${
    //         cpName ? `cpName=${cpName}&` : ''
    //       }${dateFrom ? `dateFrom=${dateFrom}&` : ''}${
    //         dateTo ? `dateTo=${dateTo}&` : ''
    //       }${search ? `search=${search}&` : ''}${
    //         searchText ? `searchText=${searchText}&` : ''
    //       }${ordering ? `ordering=${ordering}&` : ''}${
    //         isProblemFiscal ? `isProblemFiscal=${isProblemFiscal}&` : ''
    //       }`
    //     ),
    //   providesTags: (result, error, arg) => [{ type: 'Sessions' }],
    // }),

    sendCommand: builder.mutation<ResponseBase, SendManualCommandParams>({
      query: ({ body, command, cpName }) => ({
        url: getUrl(
          `SendManualCommand?command=${command}&${
            cpName ? `cpName=${cpName}&` : ''
          }`
        ),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Commands'],
    }),
    // getCommandList: builder.query<ManualCommandsResponse, GetCommandListParams>(
    //   {
    //     query: ({
    //       page,
    //       limit,
    //       login,
    //       cpName,
    //       dateFrom,
    //       dateTo,
    //       search,
    //       searchText,
    //       ordering,
    //       type,
    //       groupId,
    //     }) =>
    //       getUrl(
    //         `GetManualCommandList?page=${page}&${
    //           limit ? `limit=${limit}&` : ''
    //         }${type ? `type=${type}&` : ''}${login ? `login=${login}&` : ''}${
    //           cpName ? `cpName=${cpName}&` : ''
    //         }${dateFrom ? `dateFrom=${dateFrom}&` : ''}${
    //           dateTo ? `dateTo=${dateTo}&` : ''
    //         }${search ? `search=${search}&` : ''}${
    //           searchText ? `searchText=${searchText}&` : ''
    //         }${ordering ? `ordering=${ordering}&` : ''}${
    //           groupId ? `groupId=${groupId}&` : ''
    //         }`
    //       ),
    //     providesTags: ['Commands'], // TODO: инвалидация
    //   }
    // ),
  }),
});

export const {
  useGetStatusHistoryQuery,

  // useGetCommandListQuery,
  useSendCommandMutation,
  // useGetSessionReservesQuery,
} = privateMethodsApi;
