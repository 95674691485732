import { z } from 'zod';

import { postApiPaymentBanksBody } from 'shared/api/services/payment/schemas';

export const FormSchema = postApiPaymentBanksBody;

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  countryId: '',
};
