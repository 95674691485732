import {
  EventName,
  EventType,
} from 'shared/api/services/chargepoint/generated';

export const EVENT_NAME: Record<EventName, string> = {
  BOOT_NOTIFICATION: 'BOOT_NOTIFICATION',
  CHANGE_AVAILABILITY: 'CHANGE_AVAILABILITY',
  CHANGE_CONFIGURATION: 'CHANGE_CONFIGURATION',
  DATA_TRANSFER: 'DATA_TRANSFER',
  METER_VALUES: 'METER_VALUES',
  REMOTE_START_TRANSACTION: 'REMOTE_START_TRANSACTION',
  REMOTE_STOP_TRANSACTION: 'REMOTE_STOP_TRANSACTION',
  RESET: 'RESET',
  START_TRANSACTION: 'START_TRANSACTION',
  STATUS_NOTIFICATION: 'STATUS_NOTIFICATION',
  STOP_TRANSACTION: 'STOP_TRANSACTION',
  UNLOCK_CONNECTOR: 'UNLOCK_CONNECTOR',
};

export const EVENT_TYPE: Record<EventType, string> = {
  CONFIRMATION: 'CONFIRMATION',
  REQUEST: 'REQUEST',
};
