import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const FormLayout = styled.div`
  display: flex;
  align-items: start;
  gap: 29px;
`;

export const ImageContainer = styled.div`
  width: 197px;
  padding: 20px;
  background-color: ${COLOR.whiteSmoke};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
`;

export const ImageText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.inputGrey};
  text-align: center;
`;

export const FormContainer = styled.div`
  flex-grow: 1;
`;

export const ConnectorFormWrapper = styled.div`
  width: 570px;
`;
