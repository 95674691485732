import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MainContentLayout } from 'shared/ui/layout';
import { ChargePointFormTabs } from 'entities/charge-point/ui/form/tabs';
import {
  GetChargePointVm,
  GetGroupVm,
  GetHubVm,
  GetOwnerVm,
} from 'shared/api/services/chargepoint/generated';
import { useUpdateChargePoint } from 'shared/api/services/chargepoint/enhanced';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { buildOcppConfigObject } from 'entities/charge-point/lib/build-ocpp-config-object';

import { Form as TechInfoForm } from './tech-info';
import { Form as CardSettingsForm } from './card-settings';
import { Form as OcppConfigForm } from './ocpp-config';
import {
  OcppConfigFormOutput,
  CardSettingsFormOutput,
  TechInfoFormOutput,
  FormOutput,
} from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

import { CardFormWrapper } from '../../../../common-styles';

type Props = {
  groups: GetGroupVm[];
  hubs: GetHubVm[];
  owners: GetOwnerVm[];
  chargePoint: GetChargePointVm;
};

export function Form({ groups, hubs, owners, chargePoint }: Props) {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({ ...chargePoint });

  const [trigger, { isLoading }] = useUpdateChargePoint();

  const handleSubmit = async (values: Partial<FormOutput>) => {
    try {
      const res = await trigger({
        id: chargePoint.id,
        updateChargePointRequest: values,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);

      navigate(CHARGE_POINT_ROUTES.CHARGE_POINTS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  const forms = [
    <TechInfoForm
      data={data}
      groups={groups}
      owners={owners}
      submit={(values: TechInfoFormOutput) => {
        handleSubmit(values);
      }}
    />,
    <CardSettingsForm
      data={data}
      submit={(values: CardSettingsFormOutput) => {
        handleSubmit(values);
      }}
      hubs={hubs}
    />,
    <OcppConfigForm
      data={data}
      submit={(values: OcppConfigFormOutput) => {
        handleSubmit(values);
      }}
    />,
  ];

  const navigate = useNavigate();

  const onCancel = () => navigate(-1);

  // const disabledTabs = useMemo(() => {
  //   let arr: number[] = [];

  //   if (techInfo.name === '') {
  //     arr.push(1, 2, 3);

  //     return arr;
  //   }

  //   if (cardSettings.latitude === '') {
  //     arr.push(3);

  //     return arr;
  //   }

  //   return [];
  // }, [data]);

  return (
    <>
      <ChargePointFormTabs
        activeKey={step}
        onClick={(step: number) => {
          setStep(step);
        }}
        disabledTabs={[]}
      />
      <MainContentLayout
        element={<CardFormWrapper>{forms[step]}</CardFormWrapper>}
      />
    </>
  );
}
