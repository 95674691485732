import { Menu } from 'antd';

import { GetChargePointVm } from 'shared/api/services/chargepoint/generated';
import { CustomLink } from 'shared/ui';

import { StatusCircle } from './styles';

type Props = {
  groupId: string;
  chargePoint: GetChargePointVm;
  selectedChargePointId: string | undefined;
};

export function ChargePointMenuItem({
  chargePoint,
  groupId,
  selectedChargePointId,
}: Props) {
  const { name, status, id } = chargePoint;

  const encodedId = encodeURIComponent(id);

  const chargePointPath = `${groupId}/${encodedId}`;

  const path =
    selectedChargePointId === undefined
      ? chargePointPath
      : location.pathname.replace(
          encodeURIComponent(selectedChargePointId),
          encodedId
        );

  return (
    <CustomLink
      to={path}
      key={chargePointPath}
      checkIsActive={(path) => {
        const pathArr = path.split('/');

        return pathArr.includes(encodedId);
      }}
    >
      <Menu.Item key={chargePointPath} eventKey={chargePointPath}>
        <StatusCircle status={status} />
        {name}
      </Menu.Item>
    </CustomLink>
  );
}
