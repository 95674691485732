import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from './base-query-with-reauth';

export const baseApi = createApi({
  reducerPath: 'newApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Banks',
    'CarBrands',
    'CarModels',
    'ChargePoints',
    'ChargePointGroups',
    'ChargePointPhotos',
    'Companies',
    'Countries',
    'IdentityGroups',
    'IdentityUsers',
    'IdentityRoles',
    'Events',
    'Hubs',
    'Owners',
    'Users',
    'UserGroups',
    'MobileApps',
    'ReserveSessions',
    'IdentityUserRoles',
    'IdentityUserRights',
  ],
  endpoints: (builder) => ({}),
});
