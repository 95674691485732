import { getIdentity } from '../../orval/axios/client';
import { enhancedApi as api } from '../generated';

export const enhancedApi = api
  .enhanceEndpoints({
    endpoints: {
      getApiIdentityUsers: (endpoint) => {
        endpoint.providesTags = [{ type: 'IdentityUsers', id: 'LIST' }];
      },
      getApiIdentityUsersByUserName: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'IdentityUsers', id: arg },
        ];
      },
      postApiIdentityUsers: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityUsers', id: 'LIST' }];
      },
      patchApiIdentityUsers: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'IdentityUsers', id: 'LIST' },
          {
            type: 'IdentityUsers',
            id: arg.userName,
          },
        ];
      },
      deleteApiIdentityUsersByUserName: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityUsers', id: 'LIST' }];
      },
      //
      getApiIdentityUserRolesByUserName: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'IdentityUserRoles', id: arg },
        ];
      },
      getApiIdentityUserRightsByUserName: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'IdentityUserRights', id: arg },
        ];
      },
      // Роли
      getApiIdentityRoles: (endpoint) => {
        endpoint.providesTags = [{ type: 'IdentityRoles', id: 'LIST' }];
      },
      getApiIdentityRolesByRoleName: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'IdentityRoles', id: arg },
        ];
      },
      postApiIdentityRoles: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityRoles', id: 'LIST' }];
      },
      patchApiIdentityRoles: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'IdentityRoles', id: 'LIST' },
          {
            type: 'IdentityRoles',
            id: arg.name,
          },
        ];
      },
      deleteApiIdentityRolesByRoleName: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityRoles', id: 'LIST' }];
      },
      // Группы
      getApiIdentityGroups: (endpoint) => {
        endpoint.providesTags = [{ type: 'IdentityGroups', id: 'LIST' }];
      },
      getApiIdentityGroupsByGroupName: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'IdentityGroups', id: arg },
        ];
      },
      postApiIdentityGroups: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityGroups', id: 'LIST' }];
      },
      patchApiIdentityGroups: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'IdentityGroups', id: 'LIST' },
          {
            type: 'IdentityGroups',
            id: arg.name,
          },
        ];
      },
      deleteApiIdentityGroups: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'IdentityGroups', id: 'LIST' }];
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getRolesRights: builder.query<
        {
          [k: string]: {
            read: boolean;
            write: boolean;
            execute: boolean;
          };
        },
        string[]
      >({
        queryFn: async (roles, _queryApi, _extraOptions, baseQuery) => {
          const { getApiIdentityRoleRightsRoleName } = getIdentity();

          try {
            const res = await Promise.all(
              roles.map((role) => getApiIdentityRoleRightsRoleName(role))
            );

            const rolesRightsObject = res.reduce((acc, item) => {
              // TODO: добавить throw error
              if (!item || item.status === 'Error') return acc;

              item.rights?.forEach(
                ({ controllerName, execute, read, write }) => {
                  const currentObj = acc[controllerName];

                  if (currentObj) {
                    if (read === true) {
                      currentObj.read = true;
                    }

                    if (write === true) {
                      currentObj.write = true;
                    }

                    if (execute === true) {
                      currentObj.execute = true;
                    }
                  } else {
                    acc[controllerName] = {
                      read: false,
                      write: false,
                      execute: false,
                    };

                    if (read === true) {
                      acc[controllerName].read = true;
                    }

                    if (write === true) {
                      acc[controllerName].write = true;
                    }

                    if (execute === true) {
                      acc[controllerName].execute = true;
                    }
                  }
                }
              );

              return acc;
            }, {} as Record<string, { read: boolean; write: boolean; execute: boolean }>);

            return { data: rolesRightsObject };
          } catch (err) {
            return {
              error: {
                error: `Не удалось получить права для роли`,
                status: `CUSTOM_ERROR`, // Не менять
              },
            };
          }
        },
      }),
    }),
  });

export const {
  // Юзеры админки
  useGetApiIdentityUsersQuery: useGetIdentityUsers,
  useGetApiIdentityUsersByUserNameQuery: useGetIdentityUser,
  usePostApiIdentityUsersMutation: useAddIdentityUser,
  usePatchApiIdentityUsersMutation: useUpdateIdentityUser,
  useDeleteApiIdentityUsersByUserNameMutation: useDeleteIdentityUser,
  //
  useGetApiIdentityUserRolesByUserNameQuery: useGetUserRoles,
  useGetApiIdentityUserRightsByUserNameQuery: useGetUserRights,
  useGetRolesRightsQuery: useGetRolesRights,
  // Роли
  useGetApiIdentityRolesQuery: useGetIdentityRoles,
  useGetApiIdentityRolesByRoleNameQuery: useGetIdentityRole,
  usePostApiIdentityRolesMutation: useAddIdentityRole,
  usePatchApiIdentityRolesMutation: useUpdateIdentityRole,
  useDeleteApiIdentityRolesByRoleNameMutation: useDeleteIdentityRole,
  // Группы
  useGetApiIdentityGroupsQuery: useGetIdentityGroups,
  useGetApiIdentityGroupsByGroupNameQuery: useGetIdentityGroup,
  usePostApiIdentityGroupsMutation: useAddIdentityGroup,
  usePatchApiIdentityGroupsMutation: useUpdateIdentityGroup,
  useDeleteApiIdentityGroupsMutation: useDeleteIdentityGroup,
} = enhancedApi;
