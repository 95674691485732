/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * @summary Получить бренд
 */
export const getApiInformationCarBrandsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiInformationCarBrandsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      logoUrl: zod.string().nullish(),
      objectStorageName: zod.string().nullish(),
    })
    .optional(),
});

/**
 * @summary Обновить бренд
 */
export const patchApiInformationCarBrandsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiInformationCarBrandsIdBody = zod.object({
  name: zod.string().min(1),
});

export const patchApiInformationCarBrandsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      logoUrl: zod.string().nullish(),
      objectStorageName: zod.string().nullish(),
    })
    .optional(),
});

/**
 * @summary Удалить бренд
 */
export const deleteApiInformationCarBrandsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiInformationCarBrandsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список брендов
 */
export const getApiInformationCarBrandsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        logoUrl: zod.string().nullish(),
        objectStorageName: zod.string().nullish(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить бренд
 */
export const postApiInformationCarBrandsBody = zod.object({
  name: zod.string().min(1),
});

export const postApiInformationCarBrandsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      logoUrl: zod.string().nullish(),
      objectStorageName: zod.string().nullish(),
    })
    .optional(),
});

/**
 * @summary Добавить или обновить логотип бренда
 */
export const putApiInformationCarBrandsLogoIdParams = zod.object({
  id: zod.string().uuid(),
});

export const putApiInformationCarBrandsLogoIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      logoUrl: zod.string().min(1),
      objectStorageName: zod.string().min(1),
    })
    .optional(),
});

/**
 * @summary Удалить файл логотипа
 */
export const deleteApiInformationCarBrandsLogoIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiInformationCarBrandsLogoIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid().optional(),
      name: zod.string().nullish(),
      logoUrl: zod.string().nullish(),
      objectStorageName: zod.string().nullish(),
    })
    .optional(),
});

/**
 * @summary Получить модель
 */
export const getApiInformationCarModelsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiInformationCarModelsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      carBrandId: zod.string().uuid(),
    })
    .optional(),
});

/**
 * @summary Обновить модель
 */
export const patchApiInformationCarModelsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiInformationCarModelsIdBody = zod.object({
  name: zod.string().nullish(),
  carBrandId: zod.string().uuid().nullish(),
});

export const patchApiInformationCarModelsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      carBrandId: zod.string().uuid(),
    })
    .optional(),
});

/**
 * @summary Удалить модель
 */
export const deleteApiInformationCarModelsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiInformationCarModelsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить модели
 */
export const getApiInformationCarModelsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        carBrandId: zod.string().uuid(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить модель
 */
export const postApiInformationCarModelsBody = zod.object({
  carBrandId: zod.string().uuid(),
  name: zod.string().min(1),
});

export const postApiInformationCarModelsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      carBrandId: zod.string().uuid(),
    })
    .optional(),
});

/**
 * @summary Получить страну
 */
export const getApiInformationCountriesIdParams = zod.object({
  id: zod.string(),
});

export const getApiInformationCountriesIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      displayName: zod.string().min(1),
      currencySymbol: zod.string().min(1),
      currencyText: zod.string().min(1),
      description: zod.string().nullish(),
      code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phonePrefix: zod.string().min(1),
      urlFlag: zod.string().min(1),
      language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phoneMask: zod.string().min(1),
      privacyPolicyUrl: zod.string().min(1),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()),
    })
    .optional(),
});

/**
 * @summary Обновить страну
 */
export const patchApiInformationCountriesIdParams = zod.object({
  id: zod.string(),
});

export const patchApiInformationCountriesIdBody = zod.object({
  name: zod.string().nullish(),
  displayName: zod.string().nullish(),
  currencySymbol: zod.string().nullish(),
  currencyText: zod.string().nullish(),
  description: zod.string().nullish(),
  code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']).optional(),
  phonePrefix: zod.string().nullish(),
  urlFlag: zod.string().nullish(),
  language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']).optional(),
  phoneMask: zod.string().nullish(),
  privacyPolicyUrl: zod.string().nullish(),
  isDefault: zod.boolean().nullish(),
  mobileAppIds: zod.array(zod.string().uuid()).nullish(),
});

export const patchApiInformationCountriesIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      displayName: zod.string().min(1),
      currencySymbol: zod.string().min(1),
      currencyText: zod.string().min(1),
      description: zod.string().nullish(),
      code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phonePrefix: zod.string().min(1),
      urlFlag: zod.string().min(1),
      language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phoneMask: zod.string().min(1),
      privacyPolicyUrl: zod.string().min(1),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()),
    })
    .optional(),
});

/**
 * @summary Удалить страну
 */
export const deleteApiInformationCountriesIdParams = zod.object({
  id: zod.string(),
});

export const deleteApiInformationCountriesIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список стран
 */
export const getApiInformationCountriesQueryParams = zod.object({
  mobileAppIds: zod.array(zod.string().uuid()).optional(),
});

export const getApiInformationCountriesResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().min(1),
        name: zod.string().min(1),
        displayName: zod.string().min(1),
        currencySymbol: zod.string().min(1),
        currencyText: zod.string().min(1),
        description: zod.string().nullish(),
        code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
        phonePrefix: zod.string().min(1),
        urlFlag: zod.string().min(1),
        language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
        phoneMask: zod.string().min(1),
        privacyPolicyUrl: zod.string().min(1),
        isDefault: zod.boolean(),
        mobileAppIds: zod.array(zod.string().uuid()),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить страну
 */
export const postApiInformationCountriesBody = zod.object({
  id: zod.string().min(1),
  name: zod.string().min(1),
  displayName: zod.string().min(1),
  currencySymbol: zod.string().min(1),
  currencyText: zod.string().min(1),
  description: zod.string().min(1),
  code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
  phonePrefix: zod.string().min(1),
  urlFlag: zod.string().min(1),
  language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
  phoneMask: zod.string().min(1),
  privacyPolicyUrl: zod.string().min(1),
  isDefault: zod.boolean(),
  mobileAppIds: zod.array(zod.string().uuid()).nullish(),
});

export const postApiInformationCountriesResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      displayName: zod.string().min(1),
      currencySymbol: zod.string().min(1),
      currencyText: zod.string().min(1),
      description: zod.string().nullish(),
      code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phonePrefix: zod.string().min(1),
      urlFlag: zod.string().min(1),
      language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phoneMask: zod.string().min(1),
      privacyPolicyUrl: zod.string().min(1),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()).nullish(),
    })
    .optional(),
});

/**
 * @summary Получить мобильное приложение
 */
export const getApiInformationMobileAppsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiInformationMobileAppsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      isDefault: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Обновить существующее мобильное приложение
 */
export const patchApiInformationMobileAppsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiInformationMobileAppsIdBody = zod.object({
  name: zod.string().nullish(),
  description: zod.string().nullish(),
  isDefault: zod.boolean().nullish(),
});

export const patchApiInformationMobileAppsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      isDefault: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Удалить мобильное приложение
 */
export const deleteApiInformationMobileAppsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiInformationMobileAppsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список мобильных приложений
 */
export const getApiInformationMobileAppsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        description: zod.string().nullish(),
        isDefault: zod.boolean(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить мобильное приложение
 */
export const postApiInformationMobileAppsBody = zod.object({
  name: zod.string().min(1),
  description: zod.string().min(1),
  isDefault: zod.boolean(),
});

export const postApiInformationMobileAppsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      isDefault: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Получить саппорт
 */
export const getApiInformationSupportIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiInformationSupportIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userAgreementUrl: zod.string().min(1),
      urlFaq: zod.string().min(1),
      supportPhone: zod.string().min(1),
      telegrammBot: zod.string().nullish(),
      countryId: zod.string().min(1),
    })
    .optional(),
});

/**
 * @summary Обновить саппорта
 */
export const patchApiInformationSupportIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiInformationSupportIdBody = zod.object({
  userAgreementUrl: zod.string().nullish(),
  urlFaq: zod.string().nullish(),
  supportPhone: zod.string().nullish(),
  telegrammBot: zod.string().nullish(),
});

export const patchApiInformationSupportIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userAgreementUrl: zod.string().min(1),
      urlFaq: zod.string().min(1),
      supportPhone: zod.string().min(1),
      telegrammBot: zod.string().min(1),
      countryId: zod.string().min(1),
    })
    .optional(),
});

/**
 * @summary Удалить саппорт
 */
export const deleteApiInformationSupportIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiInformationSupportIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список саппорт
 */
export const getApiInformationSupportResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userAgreementUrl: zod.string().min(1),
        urlFaq: zod.string().min(1),
        supportPhone: zod.string().min(1),
        telegrammBot: zod.string().nullish(),
        countryId: zod.string().min(1),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить саппорта
 */
export const postApiInformationSupportBody = zod.object({
  userAgreementUrl: zod.string().min(1),
  urlFaq: zod.string().min(1),
  supportPhone: zod.string().min(1),
  telegrammBot: zod.string().nullish(),
  countryId: zod.string().uuid(),
});

export const postApiInformationSupportResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userAgreementUrl: zod.string().min(1),
      urlFaq: zod.string().min(1),
      supportPhone: zod.string().min(1),
      telegrammBot: zod.string().nullish(),
      countryId: zod.string().min(1),
    })
    .optional(),
});
