import { Actions } from './actions';
import { Layout } from './layout';

type Props = Omit<React.ComponentProps<typeof Layout>, 'actionsSlot'>;

export function BaseInfoWidget(props: Props) {
  return (
    <Layout
      {...props}
      actionsSlot={<Actions chargePointId={props.chargePoint.id} />}
    />
  );
}
