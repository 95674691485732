import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetChargePointGroups } from 'shared/api/services/chargepoint/enhanced';

import { Form } from './form';
import { useGetMobileApps } from 'shared/api/services/information/rtk';

export function FormContainer() {
  const {
    isFetching: isGroupsFetching,
    error: groupsError,
    data: groups,
  } = useGetChargePointGroups({});

  const {
    data: mobileApps,
    error: mobileAppsError,
    isFetching: isMobileAppsFetching,
  } = useGetMobileApps();

  const error = [groupsError, mobileAppsError].find((err) => err !== undefined);

  const isLoading = isGroupsFetching || isMobileAppsFetching;

  const apiResponseError = [groups, mobileApps].find(
    (el) => el && el.status == 'ERROR'
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (!mobileApps?.data || !groups?.data) {
    return <EmptyData />;
  }

  return <Form groups={groups.data} mobileApps={mobileApps.data} />;
}
