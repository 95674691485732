import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';

import { UiModal } from 'shared/ui';

import { selectShowCharts, setShowCharts } from '../../store';
import { PageSettingsForm } from '../page-settings-form';

export const PageSettingsModal = NiceModal.create(() => {
  const modal = useModal();

  const showCharts = useAppSelector(selectShowCharts);

  const dispatch = useAppDispatch();

  const handleShowCharts = (val: boolean) => dispatch(setShowCharts(val));

  return (
    <UiModal
      title={`Настройка страницы`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <PageSettingsForm
        onCancel={modal.hide}
        onConfirm={handleShowCharts}
        showCharts={showCharts}
      />
    </UiModal>
  );
});
