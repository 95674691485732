import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Col } from 'antd';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomTextarea } from 'shared/ui/form/custom-textarea';

import {
  OcppConfigFormSchema as FormSchema,
  OcppConfigFormInput as FormInput,
  OcppConfigFormOutput as FormOutput,
} from '../../consts/schema';

import { FormRow } from '../../../../../common-styles';

type Props = {
  submit: (data: FormOutput) => void;
  data: FormInput;
};

export function Form({ data, submit }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...data },
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    submit(data);
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <Form.Fields />
        <Form.Buttons />
      </form>
    </FormProvider>
  );
}

Form.Fields = function Fields() {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomTextarea<FormInput>
            name="ocppConfig"
            label="OCPP конфиг"
            errorMessage={errors.ocppConfig?.message}
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
