import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { AuthState, initialState } from './auth-state';

const slice = createSlice({
  name: 'session-slice',
  initialState,
  reducers: {
    setSessionCredentials: (state, { payload }: PayloadAction<AuthState>) => {
      return { ...state, ...payload };
    },
    clearSessionCredentials: (_state) => {
      return initialState;
    },
  },
});

export const { setSessionCredentials, clearSessionCredentials } = slice.actions;

export const selectSessionAccessToken = (state: RootState) =>
  state.authSession.accessToken;

export default slice.reducer;
