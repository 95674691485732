export function LogoutIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M20.8333 14.5835L22.2095 13.2073C22.6 12.8167 22.6 12.1836 22.2095 11.7931L20.8333 10.4168"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.8749 12.4998H13.5416M6.24992 20.8332C3.94873 20.8332 2.08325 18.9677 2.08325 16.6665V8.33317C2.08325 6.03198 3.94873 4.1665 6.24992 4.1665M6.24992 20.8332C8.5511 20.8332 10.4166 18.9677 10.4166 16.6665V8.33317C10.4166 6.03198 8.5511 4.1665 6.24992 4.1665M6.24992 20.8332H14.5833C16.8844 20.8332 18.7499 18.9677 18.7499 16.6665M6.24992 4.1665H14.5833C16.8844 4.1665 18.7499 6.03198 18.7499 8.33317"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}
