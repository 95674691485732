import { Route } from 'react-router-dom';

import { SETTINGS } from 'shared/consts/routes/common';

import { SettingsSidebar } from 'features/sidebar';

import { ChapterLayout } from 'widgets/layout/chapter';

import { AppSettingsRoutes } from './app-settings';
import { CountryRoutes } from './country';
import { CompanyRoutes } from './company';
import { BankRoutes } from './bank';
import { MobileAppRoutes } from './mobile-app';
import { CarModelRoutes } from './car-model';

export const SettingsRoutes = (
  <Route
    path={SETTINGS}
    element={<ChapterLayout siderbar={<SettingsSidebar />} />}
  >
    {BankRoutes}
    {CountryRoutes}
    {CompanyRoutes}
    {/* {AppSettingsRoutes} */}
    {MobileAppRoutes}
    {CarModelRoutes}
  </Route>
);
