import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetOwner } from 'shared/api/services/chargepoint/enhanced';

import { Form } from './form';

const pageParamsSchema = z.object({
  ownerId: z.string(),
});

export function FormContainer() {
  const { ownerId } = useTypedParams(pageParamsSchema);

  const { isFetching, error, data } = useGetOwner(ownerId);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data?.data) {
    return <EmptyData />;
  }

  return <Form owner={data.data} />;
}
