import { MainLayout } from 'shared/ui/layout/main';

import { PersonsFetchingContextProvider } from '../../users-context';
import { TabMenu } from '../users-tab-menu';

export function UsersLayout() {
  return (
    <PersonsFetchingContextProvider>
      <MainLayout tabs={<TabMenu />} />
    </PersonsFetchingContextProvider>
  );
}
