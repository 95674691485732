import { useParams } from 'react-router-dom';
import { Menu, Spin } from 'antd';

import { CustomLink } from 'shared/ui';
import { StyledNavMenu } from 'shared/ui/section-menu/menu/styles';
import { HUB_ROUTES } from 'shared/consts/routes/hub';
import {
  GetChargePointVm,
  GetHubVm,
} from 'shared/api/services/chargepoint/generated';

import { ChargePointDTO } from 'entities/charge-point';
import { SECTION } from 'entities/navigation';

import { AddButton } from './add-button';

const { label } = SECTION['hubs'];

type Props = {
  isHubsLoading: boolean;
  isChargePointsLoading: boolean;
  items: GetHubVm[];
  chargePoints: GetChargePointVm[];
};

export function MenuInstance({
  items,
  isHubsLoading,
  isChargePointsLoading,
  chargePoints,
}: Props) {
  const { hubId } = useParams() as {
    hubId: string;
  };

  const renderChargePointMenuItem = (chargePoint: ChargePointDTO) => {
    const { name, idGroup } = chargePoint;

    const path = `/${
      SECTION['charge-points'].path
    }/${idGroup}/${encodeURIComponent(name)}`;

    return (
      <CustomLink to={path}>
        <Menu.Item key={path} eventKey={path}>
          {name}
        </Menu.Item>
      </CustomLink>
    );
  };

  const hubsMenuItems = items.map((hub) => {
    const { id, name } = hub;

    const innerMenuContent = chargePoints.map((cp) =>
      renderChargePointMenuItem(cp)
    );

    const path = String(id) === hubId ? HUB_ROUTES.HUBS : String(id);

    return (
      <CustomLink to={path} key={path}>
        <Spin spinning={isChargePointsLoading && String(id) === hubId}>
          <Menu.SubMenu key={String(id)} title={name} eventKey={String(id)}>
            {innerMenuContent}
          </Menu.SubMenu>
        </Spin>
      </CustomLink>
    );
  });

  return (
    <>
      <AddButton />
      <StyledNavMenu
        openKeys={
          hubId && Boolean(!isChargePointsLoading) ? ['root', hubId] : ['root']
        }
        mode="inline"
        inlineIndent={15}
      >
        <Spin spinning={isHubsLoading}>
          <CustomLink
            to={HUB_ROUTES.HUBS}
            checkIsActive={(path) => path === HUB_ROUTES.HUBS}
          >
            <Menu.SubMenu
              key="root"
              title={label}
              eventKey="root"
              disabled={isHubsLoading}
            >
              {hubsMenuItems}
            </Menu.SubMenu>
          </CustomLink>
        </Spin>
      </StyledNavMenu>
    </>
  );
}
