import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AbilityBuilder,
  createMongoAbility,
  defineAbility,
  MongoQuery,
  PureAbility,
} from '@casl/ability';
import jwt_decode from 'jwt-decode';

import { selectCurrentToken } from 'entities/authorization';
import { Controller, Right } from 'entities/authorization';
import { AccessToken } from 'entities/authorization/model/token';
import { createStrictContext, useStrictContext } from '../react';

type Actions = Right | 'manage';
type Subjects = Controller | 'all';

type Ability = [Actions, Subjects];

type AppAbility = PureAbility<Ability, MongoQuery>;

const abilityContext = createStrictContext<AppAbility>();

type Props = {
  children?: React.ReactNode;
};

const ROLE_ADMIN_ID = '3fa85f64-5717-4562-b3fc-2c963f66afa6';

export function AbilityContextProvider({ children }: Props) {
  // const token = useSelector(selectCurrentToken) as AccessToken | '';

  const ability = defineAbility<AppAbility>((can) => {
    // Заглушка пока нет авторизации

    if (true) {
      can('manage', 'all');
    } else {
      const {
        Roles,
        Read,
        Execute,
        ReadExecute,
        ReadWrite,
        ReadWriteExecute,
        Write,
        WriteExecute,
      } = jwt_decode(token) as AccessToken;

      if (Array.isArray(Roles) && Roles.includes(ROLE_ADMIN_ID)) {
        can('manage', 'all');
      } else {
        Read && can('Read', Read);
        Write && can('Write', Write);
        Execute && can('Execute', Execute);

        ReadWrite && can(['Read', 'Write'], ReadWrite);
        ReadExecute && can(['Read', 'Execute'], ReadExecute);
        WriteExecute && can(['Write', 'Execute'], WriteExecute);

        ReadWriteExecute && can('manage', ReadWriteExecute);
      }
    }

    //
  });

  return (
    <abilityContext.Provider value={ability}>
      {children}
    </abilityContext.Provider>
  );
}

export const useAbility = () => useStrictContext(abilityContext);
