/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Identity
 * Сервис авторизации и прав пользователя
 * OpenAPI spec version: v1
 */
import type {
  DeleteApiIdentityGroupsParams,
  WebApiMiddlewareResponseBase,
  WebApiRequestAddGroupRequest,
  WebApiRequestAddRoleClaimRequest,
  WebApiRequestAddRoleRequest,
  WebApiRequestAddUserClaimRequest,
  WebApiRequestAddUserGroupRequest,
  WebApiRequestAddUserRequest,
  WebApiRequestAddUserRoleRequest,
  WebApiRequestDeleteRoleClaimRequest,
  WebApiRequestDeleteUserClaimRequest,
  WebApiRequestDeleteUserGroupRequest,
  WebApiRequestDeleteUserRoleRequest,
  WebApiRequestLoginRequest,
  WebApiRequestSetUserRightsRequest,
  WebApiRequestUpdateGroupRequest,
  WebApiRequestUpdateRoleRequest,
  WebApiRequestUpdateUserRequest,
  WebApiRequestUpdateUserRoleRequest,
  WebApiResponseAddRoleResponse,
  WebApiResponseGetGroupResponse,
  WebApiResponseGetGroupsResponse,
  WebApiResponseGetRightsResponse,
  WebApiResponseGetRoleClaimsResponse,
  WebApiResponseGetRoleResponse,
  WebApiResponseGetRolesResponse,
  WebApiResponseGetUserResponse,
  WebApiResponseGetUserRolesResponse,
  WebApiResponseGetUsersResponse,
  WebApiResponseLoginResponse,
  WebApiResponseRefreshTokensResponse,
  WebApiResponseUpdateGroupResponse,
  WebApiResponseUpdateRoleResponse
} from './schemas'
import { customInstance } from '../../../../../axios';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getIdentity = () => {
/**
 * @summary Получить токен доступа
 */
const postApiIdentityUsersLogin = (
    webApiRequestLoginRequest: WebApiRequestLoginRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseLoginResponse>(
      {url: `/Api/Identity/Users/Login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestLoginRequest
    },
      options);
    }
  
/**
 * @summary Получить новый токен доступа с помощью refresh token(в хедере)
 */
const postApiIdentityUsersRefreshTokens = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseRefreshTokensResponse>(
      {url: `/Api/Identity/Users/RefreshTokens`, method: 'POST'
    },
      options);
    }
  
/**
 * @summary Заблокировать пользователя
 */
const postApiIdentityUsersLoginLockoutUser = (
    login: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/Users/${login}/LockoutUser`, method: 'POST'
    },
      options);
    }
  
/**
 * @summary Разблокировать пользователя
 */
const postApiIdentityUsersLoginUnlockUser = (
    login: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/Users/${login}/UnlockUser`, method: 'POST'
    },
      options);
    }
  
/**
 * @summary Получить пользоватлея
 */
const getApiIdentityUsersUserName = (
    userName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseGetUserResponse>(
      {url: `/Api/Identity/Users/${userName}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Удалить пользователя
 */
const deleteApiIdentityUsersUserName = (
    userName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/Users/${userName}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить всех пользователей
 */
const getApiIdentityUsers = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseGetUsersResponse>(
      {url: `/Api/Identity/Users`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить пользователя
 */
const postApiIdentityUsers = (
    webApiRequestAddUserRequest: WebApiRequestAddUserRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/Users`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestAddUserRequest
    },
      options);
    }
  
/**
 * @summary Обновить пользователя
 */
const patchApiIdentityUsers = (
    webApiRequestUpdateUserRequest: WebApiRequestUpdateUserRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/Users`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestUpdateUserRequest
    },
      options);
    }
  
/**
 * @summary Добавить роли пользователю
 */
const postApiIdentityUserRoles = (
    webApiRequestAddUserRoleRequest: WebApiRequestAddUserRoleRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/UserRoles`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestAddUserRoleRequest
    },
      options);
    }
  
/**
 * @summary Обновить роли пользователю
 */
const putApiIdentityUserRoles = (
    webApiRequestUpdateUserRoleRequest: WebApiRequestUpdateUserRoleRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/UserRoles`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestUpdateUserRoleRequest
    },
      options);
    }
  
/**
 * @summary Удалить роли пользователю
 */
const deleteApiIdentityUserRoles = (
    webApiRequestDeleteUserRoleRequest: WebApiRequestDeleteUserRoleRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/UserRoles`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestDeleteUserRoleRequest
    },
      options);
    }
  
/**
 * @summary Получить роли пользователя
 */
const getApiIdentityUserRolesUserName = (
    userName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseGetUserRolesResponse>(
      {url: `/Api/Identity/UserRoles/${userName}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить группу пользователю
 */
const postApiIdentityUserGroups = (
    webApiRequestAddUserGroupRequest: WebApiRequestAddUserGroupRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/UserGroups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestAddUserGroupRequest
    },
      options);
    }
  
/**
 * @summary Удалить роль пользователю
 */
const deleteApiIdentityUserGroups = (
    webApiRequestDeleteUserGroupRequest: WebApiRequestDeleteUserGroupRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/UserGroups`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestDeleteUserGroupRequest
    },
      options);
    }
  
/**
 * @summary Получить все утверждения пользователя
 */
const getApiIdentityUserClaimsUserName = (
    userName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseGetRoleClaimsResponse>(
      {url: `/Api/Identity/UserClaims/${userName}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить утверждение пользователю
 */
const postApiIdentityUserClaims = (
    webApiRequestAddUserClaimRequest: WebApiRequestAddUserClaimRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/UserClaims`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestAddUserClaimRequest
    },
      options);
    }
  
/**
 * @summary Удалить утверждение пользователя
 */
const deleteApiIdentityUserClaims = (
    webApiRequestDeleteUserClaimRequest: WebApiRequestDeleteUserClaimRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/UserClaims`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestDeleteUserClaimRequest
    },
      options);
    }
  
/**
 * @summary Получить роль системы
 */
const getApiIdentityRolesRoleName = (
    roleName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseGetRoleResponse>(
      {url: `/Api/Identity/Roles/${roleName}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Удалить роль системы
 */
const deleteApiIdentityRolesRoleName = (
    roleName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/Roles/${roleName}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить все роли системы
 */
const getApiIdentityRoles = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseGetRolesResponse>(
      {url: `/Api/Identity/Roles`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить роль в систему
 */
const postApiIdentityRoles = (
    webApiRequestAddRoleRequest: WebApiRequestAddRoleRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseAddRoleResponse>(
      {url: `/Api/Identity/Roles`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestAddRoleRequest
    },
      options);
    }
  
/**
 * @summary Обновить роль системы
 */
const patchApiIdentityRoles = (
    webApiRequestUpdateRoleRequest: WebApiRequestUpdateRoleRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseUpdateRoleResponse>(
      {url: `/Api/Identity/Roles`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestUpdateRoleRequest
    },
      options);
    }
  
/**
 * @summary Получить все утверждения роли
 */
const getApiIdentityRoleClaimsRoleName = (
    roleName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseGetRoleClaimsResponse>(
      {url: `/Api/Identity/RoleClaims/${roleName}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить утверждения для роли в систему
 */
const postApiIdentityRoleClaims = (
    webApiRequestAddRoleClaimRequest: WebApiRequestAddRoleClaimRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/RoleClaims`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestAddRoleClaimRequest
    },
      options);
    }
  
/**
 * @summary Удалить утверждение роли
 */
const deleteApiIdentityRoleClaims = (
    webApiRequestDeleteRoleClaimRequest: WebApiRequestDeleteRoleClaimRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/RoleClaims`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestDeleteRoleClaimRequest
    },
      options);
    }
  
/**
 * @summary Получить все права пользователя (только личные права пользователя, права его ролей не выдаются)
 */
const getApiIdentityUserRightsUserName = (
    userName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseGetRightsResponse>(
      {url: `/Api/Identity/UserRights/${userName}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Получить все права роли
 */
const getApiIdentityRoleRightsRoleName = (
    roleName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseGetRightsResponse>(
      {url: `/Api/Identity/RoleRights/${roleName}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Автоматически устанавливает или удаляет все необходимые утверждения пользователю для заданных прав
 */
const postApiIdentityUserRights = (
    webApiRequestSetUserRightsRequest: WebApiRequestSetUserRightsRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/UserRights`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestSetUserRightsRequest
    },
      options);
    }
  
/**
 * @summary Получить группу системы
 */
const getApiIdentityGroupsGroupName = (
    groupName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseGetGroupResponse>(
      {url: `/Api/Identity/Groups/${groupName}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Получить все группы системы
 */
const getApiIdentityGroups = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseGetGroupsResponse>(
      {url: `/Api/Identity/Groups`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить группу в систему
 */
const postApiIdentityGroups = (
    webApiRequestAddGroupRequest: WebApiRequestAddGroupRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/Groups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestAddGroupRequest
    },
      options);
    }
  
/**
 * @summary Обновить группу в системе
 */
const patchApiIdentityGroups = (
    webApiRequestUpdateGroupRequest: WebApiRequestUpdateGroupRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiResponseUpdateGroupResponse>(
      {url: `/Api/Identity/Groups`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestUpdateGroupRequest
    },
      options);
    }
  
/**
 * @summary Удалить группу из системы
 */
const deleteApiIdentityGroups = (
    params?: DeleteApiIdentityGroupsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/Groups`, method: 'DELETE',
        params
    },
      options);
    }
  
/**
 * @summary Разлогинить всех пользователей(Сделать невалидными все выпущенные токены).
 */
const headApiIdentitySystemActionsLogOutAllUsers = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WebApiMiddlewareResponseBase>(
      {url: `/Api/Identity/System/Actions/LogOutAllUsers`, method: 'HEAD'
    },
      options);
    }
  
/**
 * @summary Получить ключ подписи
 */
const postApiIdentitySystemActionsGetSicretKey = (
    webApiRequestLoginRequest: WebApiRequestLoginRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<string>(
      {url: `/Api/Identity/System/Actions/GetSicretKey`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: webApiRequestLoginRequest
    },
      options);
    }
  
return {postApiIdentityUsersLogin,postApiIdentityUsersRefreshTokens,postApiIdentityUsersLoginLockoutUser,postApiIdentityUsersLoginUnlockUser,getApiIdentityUsersUserName,deleteApiIdentityUsersUserName,getApiIdentityUsers,postApiIdentityUsers,patchApiIdentityUsers,postApiIdentityUserRoles,putApiIdentityUserRoles,deleteApiIdentityUserRoles,getApiIdentityUserRolesUserName,postApiIdentityUserGroups,deleteApiIdentityUserGroups,getApiIdentityUserClaimsUserName,postApiIdentityUserClaims,deleteApiIdentityUserClaims,getApiIdentityRolesRoleName,deleteApiIdentityRolesRoleName,getApiIdentityRoles,postApiIdentityRoles,patchApiIdentityRoles,getApiIdentityRoleClaimsRoleName,postApiIdentityRoleClaims,deleteApiIdentityRoleClaims,getApiIdentityUserRightsUserName,getApiIdentityRoleRightsRoleName,postApiIdentityUserRights,getApiIdentityGroupsGroupName,getApiIdentityGroups,postApiIdentityGroups,patchApiIdentityGroups,deleteApiIdentityGroups,headApiIdentitySystemActionsLogOutAllUsers,postApiIdentitySystemActionsGetSicretKey}};
export type PostApiIdentityUsersLoginResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityUsersLogin']>>>
export type PostApiIdentityUsersRefreshTokensResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityUsersRefreshTokens']>>>
export type PostApiIdentityUsersLoginLockoutUserResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityUsersLoginLockoutUser']>>>
export type PostApiIdentityUsersLoginUnlockUserResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityUsersLoginUnlockUser']>>>
export type GetApiIdentityUsersUserNameResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityUsersUserName']>>>
export type DeleteApiIdentityUsersUserNameResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityUsersUserName']>>>
export type GetApiIdentityUsersResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityUsers']>>>
export type PostApiIdentityUsersResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityUsers']>>>
export type PatchApiIdentityUsersResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['patchApiIdentityUsers']>>>
export type PostApiIdentityUserRolesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityUserRoles']>>>
export type PutApiIdentityUserRolesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['putApiIdentityUserRoles']>>>
export type DeleteApiIdentityUserRolesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityUserRoles']>>>
export type GetApiIdentityUserRolesUserNameResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityUserRolesUserName']>>>
export type PostApiIdentityUserGroupsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityUserGroups']>>>
export type DeleteApiIdentityUserGroupsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityUserGroups']>>>
export type GetApiIdentityUserClaimsUserNameResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityUserClaimsUserName']>>>
export type PostApiIdentityUserClaimsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityUserClaims']>>>
export type DeleteApiIdentityUserClaimsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityUserClaims']>>>
export type GetApiIdentityRolesRoleNameResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityRolesRoleName']>>>
export type DeleteApiIdentityRolesRoleNameResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityRolesRoleName']>>>
export type GetApiIdentityRolesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityRoles']>>>
export type PostApiIdentityRolesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityRoles']>>>
export type PatchApiIdentityRolesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['patchApiIdentityRoles']>>>
export type GetApiIdentityRoleClaimsRoleNameResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityRoleClaimsRoleName']>>>
export type PostApiIdentityRoleClaimsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityRoleClaims']>>>
export type DeleteApiIdentityRoleClaimsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityRoleClaims']>>>
export type GetApiIdentityUserRightsUserNameResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityUserRightsUserName']>>>
export type GetApiIdentityRoleRightsRoleNameResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityRoleRightsRoleName']>>>
export type PostApiIdentityUserRightsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityUserRights']>>>
export type GetApiIdentityGroupsGroupNameResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityGroupsGroupName']>>>
export type GetApiIdentityGroupsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['getApiIdentityGroups']>>>
export type PostApiIdentityGroupsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentityGroups']>>>
export type PatchApiIdentityGroupsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['patchApiIdentityGroups']>>>
export type DeleteApiIdentityGroupsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['deleteApiIdentityGroups']>>>
export type HeadApiIdentitySystemActionsLogOutAllUsersResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['headApiIdentitySystemActionsLogOutAllUsers']>>>
export type PostApiIdentitySystemActionsGetSicretKeyResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getIdentity>['postApiIdentitySystemActionsGetSicretKey']>>>
