import { BaseResponse, enhancedApi as api } from '../generated/car-brands';

import { getWebApi } from '../../orval/axios/client';

const enhancedApi = api
  .enhanceEndpoints({
    endpoints: {
      getApiInformationCarBrands: (endpoint) => {
        endpoint.providesTags = [{ type: 'CarBrands', id: 'LIST' }];
      },
      getApiInformationCarBrandsById: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'CarBrands', id: arg },
        ];
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      addCarBrand: builder.mutation<
        BaseResponse,
        { name: string; file?: Blob }
      >({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          console.log('args', args);

          const { name, file } = args;

          const {
            postApiInformationCarBrands,
            putApiInformationCarBrandsLogoId,
          } = getWebApi();

          try {
            const addCarBrand = await postApiInformationCarBrands({ name });

            const putCarBrandLogo = await putApiInformationCarBrandsLogoId(
              addCarBrand.data?.id,
              {
                file,
              }
            );

            return { data: { status: 'SUCCESS', statusMessage: '' } };
          } catch (err) {
            // TODO
            return { error: err };
          }
        },
        invalidatesTags: ['CarBrands'],
      }),
      updateCarBrand: builder.mutation<
        BaseResponse,
        { name: string; id: string; file?: Blob }
      >({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          const { name, file, id } = args;

          const {
            patchApiInformationCarBrandsId,
            putApiInformationCarBrandsLogoId,
          } = getWebApi();

          try {
            const patchCarBrand = await patchApiInformationCarBrandsId(id, {
              name,
            });

            if (file) {
              const putCarBrandLogo = await putApiInformationCarBrandsLogoId(
                id,
                {
                  file,
                }
              );
            }

            return { data: { status: 'SUCCESS', statusMessage: '' } };
          } catch (err) {
            return { error: err };
          }
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'CarBrands', id: 'LIST' },
          {
            type: 'CarBrands',
            id: arg.id,
          },
        ],
      }),
    }),
  });

export const {
  useGetApiInformationCarBrandsQuery: useGetCarBrands,
  useGetApiInformationCarBrandsByIdQuery: useGetCarBrand,
  useAddCarBrandMutation: useAddCarBrand,
  useUpdateCarBrandMutation: useUpdateCarBrand,
} = enhancedApi;
