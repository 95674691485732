import { UploadProps } from 'antd';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { UiUploadDragger } from 'shared/ui/ui-kit/upload-dragger';

import {
  FieldErrorContainer,
  FormItemContainer,
  FormItemLabel,
} from '../styles';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
} & React.ComponentProps<typeof UiUploadDragger>;

export function CustomUploadDragger<T extends FieldValues>({
  label,
  required,
  disabled,
  errorMessage,
  name,
  ...rest
}: Props<T>) {
  const { control } = useFormContext();

  return (
    <FormItemContainer>
      <FormItemLabel required={required}>{label}</FormItemLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          const { value, onChange } = field;

          const handleChange: UploadProps['onChange'] = (info) => {
            onChange(info.fileList);
          };

          // console.log('field', field);

          return (
            <UiUploadDragger
              {...field}
              {...rest}
              fileList={value?.fileList}
              onChange={handleChange}
            />
          );
        }}
      />
      <FieldErrorContainer>{errorMessage}</FieldErrorContainer>
    </FormItemContainer>
  );
}
