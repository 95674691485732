import { ChargePointListFiltersContainer, MetricsContainer } from './styles';

import { ConnectedChargedTotal } from 'features/charge-point/charged-total';

import { ShorthandedFilters } from 'widgets/charge-point/filters';
import { ChargePointListWidget } from 'widgets/charge-point/list';

// import { ChargePointListDashboards } from '../../../components/MetricsCard/ChargePointListDashboards';

export function ChargePointListPage() {
  return (
    <>
      <ChargePointListFiltersContainer>
        <ShorthandedFilters />
      </ChargePointListFiltersContainer>
      {/* <MetricsContainer>
        <ChargePointListDashboards />
        <ConnectedChargedTotal />
      </MetricsContainer> */}
      <ChargePointListWidget />
    </>
  );
}
