import {
  useGetCarBrands,
  useGetCarModels,
} from 'shared/api/services/information/rtk';
import { EmptyData, NavMenuError } from 'shared/ui';

import { CarModelsMenu } from './menu';

export function MenuContainer() {
  const carBrandsQuery = useGetCarBrands();

  // На бэке нет фильтра carBrandId для carModelsQuery
  // Временный костыль
  const carModelsQuery = useGetCarModels();

  const isFetching = carBrandsQuery.isFetching || carModelsQuery.isFetching;

  const apiResponseError = [carBrandsQuery.data, carModelsQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [carBrandsQuery.error, carModelsQuery.error].find(
    (err) => err !== undefined
  );

  if (apiResponseError) {
    return <NavMenuError text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (
    !isFetching &&
    (!carBrandsQuery.data?.data || !carModelsQuery.data?.data)
  ) {
    return <EmptyData />;
  }

  const brands = carBrandsQuery.data?.data || [];
  const models = carModelsQuery.data?.data || [];

  return <CarModelsMenu loading={isFetching} brands={brands} models={models} />;
}
