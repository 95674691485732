import { createColumnHelper } from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { useAppDispatch } from 'shared/redux/types';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import {
  selectTableColumns,
  setTableColumns,
} from 'shared/redux/slices/car-models/slice';
import {
  GetCarBrandVm,
  GetCarModelVm,
} from 'shared/api/services/information/generated';

import { Actions } from './actions';

const columnHelper = createColumnHelper<GetCarModelVm>();

type Props = {
  carBrands: GetCarBrandVm[];
};

export const useColumns = ({ carBrands }: Props) => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectTableColumns);

  const DATA_COLUMNS = [
    columnHelper.accessor('id', {
      id: 'id',
      header: 'Id',
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Наименование',
    }),
    columnHelper.accessor('carBrandId', {
      id: 'carBrandId',
      header: 'Марка',
      cell: (props) => {
        const val = props.getValue();

        const carBrandName =
          carBrands.find((brand) => brand.id === val)?.name || '';

        return carBrandName;
      },
    }),
  ];

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      const carModel = props.row.original;

      return <Actions carModel={carModel} />;
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataColumns = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataColumns, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
