import { baseApi as api } from '../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointChargePointsById: build.query<
      GetApiChargePointChargePointsByIdApiResponse,
      GetApiChargePointChargePointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/ChargePoints/${queryArg}`,
      }),
    }),
    patchApiChargePointChargePointsById: build.mutation<
      PatchApiChargePointChargePointsByIdApiResponse,
      PatchApiChargePointChargePointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/ChargePoints/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateChargePointRequest,
      }),
    }),
    deleteApiChargePointChargePointsById: build.mutation<
      DeleteApiChargePointChargePointsByIdApiResponse,
      DeleteApiChargePointChargePointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/ChargePoints/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointChargePoints: build.query<
      GetApiChargePointChargePointsApiResponse,
      GetApiChargePointChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/ChargePoints`,
        params: {
          OwnersId: queryArg.ownersId,
          GroupId: queryArg.groupId,
          HubId: queryArg.hubId,
          MobileAppId: queryArg.mobileAppId,
          Name: queryArg.name,
          WithTestCp: queryArg.withTestCp,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          Statuses: queryArg.statuses,
          OwnerTypes: queryArg.ownerTypes,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    postApiChargePointChargePoints: build.mutation<
      PostApiChargePointChargePointsApiResponse,
      PostApiChargePointChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/ChargePoints`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointChargePointsCount: build.query<
      GetApiChargePointChargePointsCountApiResponse,
      GetApiChargePointChargePointsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/ChargePoints/Count`,
        params: {
          OwnersId: queryArg.ownersId,
          GroupId: queryArg.groupId,
          HubId: queryArg.hubId,
          MobileAppId: queryArg.mobileAppId,
          WithTestCp: queryArg.withTestCp,
          Statuses: queryArg.statuses,
          OwnerTypes: queryArg.ownerTypes,
          Name: queryArg.name,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    getApiChargePointChargePointsCpStatuses: build.query<
      GetApiChargePointChargePointsCpStatusesApiResponse,
      GetApiChargePointChargePointsCpStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/ChargePoints/_cpStatuses`,
        params: {
          OwnersId: queryArg.ownersId,
          GroupId: queryArg.groupId,
          HubId: queryArg.hubId,
          MobileAppId: queryArg.mobileAppId,
          WithTestCp: queryArg.withTestCp,
          WithVisibleCp: queryArg.withVisibleCp,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    getApiChargePointChargePointsInfo: build.query<
      GetApiChargePointChargePointsInfoApiResponse,
      GetApiChargePointChargePointsInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/ChargePoints/_info`,
        params: { chargeBoxIdentity: queryArg },
      }),
    }),
    postApiChargePointCommandsStart: build.mutation<
      PostApiChargePointCommandsStartApiResponse,
      PostApiChargePointCommandsStartApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Commands/START`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargePointCommandsStop: build.mutation<
      PostApiChargePointCommandsStopApiResponse,
      PostApiChargePointCommandsStopApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Commands/STOP`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointChargePointsByChargePintsIdAndConnectorId: build.query<
      GetApiChargePointChargePointsByChargePintsIdAndConnectorIdApiResponse,
      GetApiChargePointChargePointsByChargePintsIdAndConnectorIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/ChargePoints/${queryArg.chargePintsId}/${queryArg.connectorId}`,
      }),
    }),
    postApiChargePointChargePointsByChargePointsIdConnector: build.mutation<
      PostApiChargePointChargePointsByChargePointsIdConnectorApiResponse,
      PostApiChargePointChargePointsByChargePointsIdConnectorApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/ChargePoints/${queryArg.chargePointsId}/Connector`,
        method: 'POST',
        body: queryArg.addConnectorRequest,
      }),
    }),
    patchApiChargePointChargePointsByChargePointsIdAndConnectorId:
      build.mutation<
        PatchApiChargePointChargePointsByChargePointsIdAndConnectorIdApiResponse,
        PatchApiChargePointChargePointsByChargePointsIdAndConnectorIdApiArg
      >({
        query: (queryArg) => ({
          url: `/Api/ChargePoint/ChargePoints/${queryArg.chargePointsId}/${queryArg.connectorId}`,
          method: 'PATCH',
          body: queryArg.updateConnectorRequest,
        }),
      }),
    deleteApiChargePointChargePointsByChargePointsIdAndConnectorId:
      build.mutation<
        DeleteApiChargePointChargePointsByChargePointsIdAndConnectorIdApiResponse,
        DeleteApiChargePointChargePointsByChargePointsIdAndConnectorIdApiArg
      >({
        query: (queryArg) => ({
          url: `/Api/ChargePoint/ChargePoints/${queryArg.chargePointsId}/${queryArg.connectorId}`,
          method: 'DELETE',
        }),
      }),
    getApiChargePointConnectorsTypes: build.query<
      GetApiChargePointConnectorsTypesApiResponse,
      GetApiChargePointConnectorsTypesApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/Connectors/Types` }),
    }),
    getApiChargePointEventsById: build.query<
      GetApiChargePointEventsByIdApiResponse,
      GetApiChargePointEventsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/Events/${queryArg}` }),
    }),
    deleteApiChargePointEventsById: build.mutation<
      DeleteApiChargePointEventsByIdApiResponse,
      DeleteApiChargePointEventsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointEvents: build.query<
      GetApiChargePointEventsApiResponse,
      GetApiChargePointEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events`,
        params: {
          Name: queryArg.name,
          Type: queryArg['type'],
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          TransactionId: queryArg.transactionId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    getApiChargePointEventsCount: build.query<
      GetApiChargePointEventsCountApiResponse,
      GetApiChargePointEventsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/Count`,
        params: {
          Name: queryArg.name,
          Type: queryArg['type'],
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          TransactionId: queryArg.transactionId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    postApiChargePointEventsDisconnectEvent: build.mutation<
      PostApiChargePointEventsDisconnectEventApiResponse,
      PostApiChargePointEventsDisconnectEventApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/DisconnectEvent`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargePointEventsBootNotificationRequest: build.mutation<
      PostApiChargePointEventsBootNotificationRequestApiResponse,
      PostApiChargePointEventsBootNotificationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/BOOT_NOTIFICATION/REQUEST`,
        method: 'POST',
        body: queryArg.bootNotificationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsBootNotificationConfirmation: build.mutation<
      PostApiChargePointEventsBootNotificationConfirmationApiResponse,
      PostApiChargePointEventsBootNotificationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/BOOT_NOTIFICATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.bootNotificationConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsRemoteStartTransactionRequest: build.mutation<
      PostApiChargePointEventsRemoteStartTransactionRequestApiResponse,
      PostApiChargePointEventsRemoteStartTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/REMOTE_START_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.remoteStartTransactionRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsRemoteStartTransactionConfirmation: build.mutation<
      PostApiChargePointEventsRemoteStartTransactionConfirmationApiResponse,
      PostApiChargePointEventsRemoteStartTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/REMOTE_START_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.remoteStartTransactionConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStartTransactionRequest: build.mutation<
      PostApiChargePointEventsStartTransactionRequestApiResponse,
      PostApiChargePointEventsStartTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/START_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.startTransactionRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStartTransactionConfirmation: build.mutation<
      PostApiChargePointEventsStartTransactionConfirmationApiResponse,
      PostApiChargePointEventsStartTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/START_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.startTransactionConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsRemoteStopTransactionRequest: build.mutation<
      PostApiChargePointEventsRemoteStopTransactionRequestApiResponse,
      PostApiChargePointEventsRemoteStopTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/REMOTE_STOP_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.remoteStopTransactionRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsRemoteStopTransactionConfirmation: build.mutation<
      PostApiChargePointEventsRemoteStopTransactionConfirmationApiResponse,
      PostApiChargePointEventsRemoteStopTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/REMOTE_STOP_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.remoteStopTransactionConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStopTransactionRequest: build.mutation<
      PostApiChargePointEventsStopTransactionRequestApiResponse,
      PostApiChargePointEventsStopTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/STOP_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.stopTransactionRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStopTransactionConfirmation: build.mutation<
      PostApiChargePointEventsStopTransactionConfirmationApiResponse,
      PostApiChargePointEventsStopTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/STOP_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.stopTransactionConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStatusNotificationRequest: build.mutation<
      PostApiChargePointEventsStatusNotificationRequestApiResponse,
      PostApiChargePointEventsStatusNotificationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/STATUS_NOTIFICATION/REQUEST`,
        method: 'POST',
        body: queryArg.statusNotificationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStatusNotificationConfirmation: build.mutation<
      PostApiChargePointEventsStatusNotificationConfirmationApiResponse,
      PostApiChargePointEventsStatusNotificationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/STATUS_NOTIFICATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.statusNotificationConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsMeterValuesRequest: build.mutation<
      PostApiChargePointEventsMeterValuesRequestApiResponse,
      PostApiChargePointEventsMeterValuesRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/METER_VALUES/REQUEST`,
        method: 'POST',
        body: queryArg.meterValuesRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsMeterValuesConfirmation: build.mutation<
      PostApiChargePointEventsMeterValuesConfirmationApiResponse,
      PostApiChargePointEventsMeterValuesConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/METER_VALUES/CONFIRMATION`,
        method: 'POST',
        body: queryArg.meterValuesConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsChangeConfigurationRequest: build.mutation<
      PostApiChargePointEventsChangeConfigurationRequestApiResponse,
      PostApiChargePointEventsChangeConfigurationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/CHANGE_CONFIGURATION/REQUEST`,
        method: 'POST',
        body: queryArg.changeConfigurationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsChangeConfigurationConfirmation: build.mutation<
      PostApiChargePointEventsChangeConfigurationConfirmationApiResponse,
      PostApiChargePointEventsChangeConfigurationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/CHANGE_CONFIGURATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.changeConfigurationConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsUnlockConnectorRequest: build.mutation<
      PostApiChargePointEventsUnlockConnectorRequestApiResponse,
      PostApiChargePointEventsUnlockConnectorRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/UNLOCK_CONNECTOR/REQUEST`,
        method: 'POST',
        body: queryArg.unlockConnectorRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsUnlockConnectorConfirmation: build.mutation<
      PostApiChargePointEventsUnlockConnectorConfirmationApiResponse,
      PostApiChargePointEventsUnlockConnectorConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/UNLOCK_CONNECTOR/CONFIRMATION`,
        method: 'POST',
        body: queryArg.unlockConnectorConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsChangeAvailabilityRequest: build.mutation<
      PostApiChargePointEventsChangeAvailabilityRequestApiResponse,
      PostApiChargePointEventsChangeAvailabilityRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/CHANGE_AVAILABILITY/REQUEST`,
        method: 'POST',
        body: queryArg.changeAvailablilityRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsChangeAvailabilityConfirmation: build.mutation<
      PostApiChargePointEventsChangeAvailabilityConfirmationApiResponse,
      PostApiChargePointEventsChangeAvailabilityConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/CHANGE_AVAILABILITY/CONFIRMATION`,
        method: 'POST',
        body: queryArg.changeAvailablilityConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsDataTransferRequest: build.mutation<
      PostApiChargePointEventsDataTransferRequestApiResponse,
      PostApiChargePointEventsDataTransferRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/DATA_TRANSFER/REQUEST`,
        method: 'POST',
        body: queryArg.dataTransferRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsDataTransferConfirmation: build.mutation<
      PostApiChargePointEventsDataTransferConfirmationApiResponse,
      PostApiChargePointEventsDataTransferConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/DATA_TRANSFER/CONFIRMATION`,
        method: 'POST',
        body: queryArg.dataTransferConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsResetRequest: build.mutation<
      PostApiChargePointEventsResetRequestApiResponse,
      PostApiChargePointEventsResetRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/RESET/REQUEST`,
        method: 'POST',
        body: queryArg.resetRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsResetConfirmation: build.mutation<
      PostApiChargePointEventsResetConfirmationApiResponse,
      PostApiChargePointEventsResetConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/RESET/CONFIRMATION`,
        method: 'POST',
        body: queryArg.resetConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    getApiChargePointFirmwaresById: build.query<
      GetApiChargePointFirmwaresByIdApiResponse,
      GetApiChargePointFirmwaresByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/Firmwares/${queryArg}` }),
    }),
    patchApiChargePointFirmwaresById: build.mutation<
      PatchApiChargePointFirmwaresByIdApiResponse,
      PatchApiChargePointFirmwaresByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Firmwares/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateFirmwareRequest,
      }),
    }),
    deleteApiChargePointFirmwaresById: build.mutation<
      DeleteApiChargePointFirmwaresByIdApiResponse,
      DeleteApiChargePointFirmwaresByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Firmwares/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointFirmwares: build.query<
      GetApiChargePointFirmwaresApiResponse,
      GetApiChargePointFirmwaresApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/Firmwares` }),
    }),
    postApiChargePointFirmwares: build.mutation<
      PostApiChargePointFirmwaresApiResponse,
      PostApiChargePointFirmwaresApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Firmwares`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointFirmwaresCount: build.query<
      GetApiChargePointFirmwaresCountApiResponse,
      GetApiChargePointFirmwaresCountApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/Firmwares/Count` }),
    }),
    getApiChargePointGroupsById: build.query<
      GetApiChargePointGroupsByIdApiResponse,
      GetApiChargePointGroupsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/Groups/${queryArg}` }),
    }),
    patchApiChargePointGroupsById: build.mutation<
      PatchApiChargePointGroupsByIdApiResponse,
      PatchApiChargePointGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Groups/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateGroupRequest,
      }),
    }),
    deleteApiChargePointGroupsById: build.mutation<
      DeleteApiChargePointGroupsByIdApiResponse,
      DeleteApiChargePointGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Groups/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointGroups: build.query<
      GetApiChargePointGroupsApiResponse,
      GetApiChargePointGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Groups`,
        params: {
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    postApiChargePointGroups: build.mutation<
      PostApiChargePointGroupsApiResponse,
      PostApiChargePointGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Groups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointGroupsCount: build.query<
      GetApiChargePointGroupsCountApiResponse,
      GetApiChargePointGroupsCountApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/Groups/Count` }),
    }),
    getApiChargePointHubsById: build.query<
      GetApiChargePointHubsByIdApiResponse,
      GetApiChargePointHubsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/Hubs/${queryArg}` }),
    }),
    patchApiChargePointHubsById: build.mutation<
      PatchApiChargePointHubsByIdApiResponse,
      PatchApiChargePointHubsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Hubs/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateHubRequest,
      }),
    }),
    deleteApiChargePointHubsById: build.mutation<
      DeleteApiChargePointHubsByIdApiResponse,
      DeleteApiChargePointHubsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Hubs/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointHubs: build.query<
      GetApiChargePointHubsApiResponse,
      GetApiChargePointHubsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Hubs`,
        params: { Limit: queryArg.limit, Offset: queryArg.offset },
      }),
    }),
    postApiChargePointHubs: build.mutation<
      PostApiChargePointHubsApiResponse,
      PostApiChargePointHubsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Hubs`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointHubsCount: build.query<
      GetApiChargePointHubsCountApiResponse,
      GetApiChargePointHubsCountApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/Hubs/Count` }),
    }),
    getApiChargePointOwnersById: build.query<
      GetApiChargePointOwnersByIdApiResponse,
      GetApiChargePointOwnersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/Owners/${queryArg}` }),
    }),
    patchApiChargePointOwnersById: build.mutation<
      PatchApiChargePointOwnersByIdApiResponse,
      PatchApiChargePointOwnersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Owners/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateOwnerRequest,
      }),
    }),
    deleteApiChargePointOwnersById: build.mutation<
      DeleteApiChargePointOwnersByIdApiResponse,
      DeleteApiChargePointOwnersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Owners/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointOwners: build.query<
      GetApiChargePointOwnersApiResponse,
      GetApiChargePointOwnersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Owners`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          ConnectorId: queryArg.connectorId,
          ChargePointId: queryArg.chargePointId,
        },
      }),
    }),
    postApiChargePointOwners: build.mutation<
      PostApiChargePointOwnersApiResponse,
      PostApiChargePointOwnersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Owners`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointOwnersCount: build.query<
      GetApiChargePointOwnersCountApiResponse,
      GetApiChargePointOwnersCountApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/Owners/Count` }),
    }),
    getApiChargePointPhotosById: build.query<
      GetApiChargePointPhotosByIdApiResponse,
      GetApiChargePointPhotosByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/Photos/${queryArg}` }),
    }),
    patchApiChargePointPhotosById: build.mutation<
      PatchApiChargePointPhotosByIdApiResponse,
      PatchApiChargePointPhotosByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Photos/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updatePhotoRequest,
      }),
    }),
    deleteApiChargePointPhotosById: build.mutation<
      DeleteApiChargePointPhotosByIdApiResponse,
      DeleteApiChargePointPhotosByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Photos/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointPhotos: build.query<
      GetApiChargePointPhotosApiResponse,
      GetApiChargePointPhotosApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Photos`,
        params: { ChargePointId: queryArg },
      }),
    }),
    postApiChargePointPhotos: build.mutation<
      PostApiChargePointPhotosApiResponse,
      PostApiChargePointPhotosApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Photos`,
        method: 'POST',
        body: queryArg.body,
        params: {
          ChargePointId: queryArg.chargePointId,
          Priority: queryArg.priority,
        },
      }),
    }),
    getApiChargePointPhotosCount: build.query<
      GetApiChargePointPhotosCountApiResponse,
      GetApiChargePointPhotosCountApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/Photos/Count` }),
    }),
    getApiChargePointRoleCpRightsById: build.query<
      GetApiChargePointRoleCpRightsByIdApiResponse,
      GetApiChargePointRoleCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleCpRights/${queryArg}`,
      }),
    }),
    patchApiChargePointRoleCpRightsById: build.mutation<
      PatchApiChargePointRoleCpRightsByIdApiResponse,
      PatchApiChargePointRoleCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleCpRights/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateRoleCpRightsRequest,
      }),
    }),
    deleteApiChargePointRoleCpRightsById: build.mutation<
      DeleteApiChargePointRoleCpRightsByIdApiResponse,
      DeleteApiChargePointRoleCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleCpRights/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointRoleCpRights: build.query<
      GetApiChargePointRoleCpRightsApiResponse,
      GetApiChargePointRoleCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleCpRights`,
        params: { roleId: queryArg },
      }),
    }),
    postApiChargePointRoleCpRights: build.mutation<
      PostApiChargePointRoleCpRightsApiResponse,
      PostApiChargePointRoleCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargePointRoleCpRightsSetRoleCpRights: build.mutation<
      PostApiChargePointRoleCpRightsSetRoleCpRightsApiResponse,
      PostApiChargePointRoleCpRightsSetRoleCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleCpRights/SetRoleCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointRoleGroupCpRightsById: build.query<
      GetApiChargePointRoleGroupCpRightsByIdApiResponse,
      GetApiChargePointRoleGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleGroupCpRights/${queryArg}`,
      }),
    }),
    patchApiChargePointRoleGroupCpRightsById: build.mutation<
      PatchApiChargePointRoleGroupCpRightsByIdApiResponse,
      PatchApiChargePointRoleGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleGroupCpRights/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateRoleGroupCpRightRequest,
      }),
    }),
    deleteApiChargePointRoleGroupCpRightsById: build.mutation<
      DeleteApiChargePointRoleGroupCpRightsByIdApiResponse,
      DeleteApiChargePointRoleGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleGroupCpRights/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointRoleGroupCpRights: build.query<
      GetApiChargePointRoleGroupCpRightsApiResponse,
      GetApiChargePointRoleGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleGroupCpRights`,
        params: { groupId: queryArg },
      }),
    }),
    postApiChargePointRoleGroupCpRights: build.mutation<
      PostApiChargePointRoleGroupCpRightsApiResponse,
      PostApiChargePointRoleGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleGroupCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargePointRoleGroupCpRightsSetRoleGroupCpRights: build.mutation<
      PostApiChargePointRoleGroupCpRightsSetRoleGroupCpRightsApiResponse,
      PostApiChargePointRoleGroupCpRightsSetRoleGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/RoleGroupCpRights/SetRoleGroupCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointSessionsById: build.query<
      GetApiChargePointSessionsByIdApiResponse,
      GetApiChargePointSessionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/Sessions/${queryArg}` }),
    }),
    getApiChargePointSessions: build.query<
      GetApiChargePointSessionsApiResponse,
      GetApiChargePointSessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Sessions`,
        params: {
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          TransactionId: queryArg.transactionId,
          DateCreateFrom: queryArg.dateCreateFrom,
          DateCreateTo: queryArg.dateCreateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          ChargePointGroupId: queryArg.chargePointGroupId,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    getApiChargePointSessionsCount: build.query<
      GetApiChargePointSessionsCountApiResponse,
      GetApiChargePointSessionsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Sessions/Count`,
        params: {
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          TransactionId: queryArg.transactionId,
          DateCreateFrom: queryArg.dateCreateFrom,
          DateCreateTo: queryArg.dateCreateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    getApiChargePointSessionsByIdGraphMeterValues: build.query<
      GetApiChargePointSessionsByIdGraphMeterValuesApiResponse,
      GetApiChargePointSessionsByIdGraphMeterValuesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Sessions/${queryArg.id}/_graphMeterValues`,
        params: { DateFrom: queryArg.dateFrom },
      }),
    }),
    getApiChargePointUserCpRightsById: build.query<
      GetApiChargePointUserCpRightsByIdApiResponse,
      GetApiChargePointUserCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserCpRights/${queryArg}`,
      }),
    }),
    patchApiChargePointUserCpRightsById: build.mutation<
      PatchApiChargePointUserCpRightsByIdApiResponse,
      PatchApiChargePointUserCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserCpRights/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateUserCpRightRequest,
      }),
    }),
    deleteApiChargePointUserCpRightsById: build.mutation<
      DeleteApiChargePointUserCpRightsByIdApiResponse,
      DeleteApiChargePointUserCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserCpRights/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointUserCpRights: build.query<
      GetApiChargePointUserCpRightsApiResponse,
      GetApiChargePointUserCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserCpRights`,
        params: { userId: queryArg },
      }),
    }),
    postApiChargePointUserCpRights: build.mutation<
      PostApiChargePointUserCpRightsApiResponse,
      PostApiChargePointUserCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargePointUserCpRightsSetUserCpRights: build.mutation<
      PostApiChargePointUserCpRightsSetUserCpRightsApiResponse,
      PostApiChargePointUserCpRightsSetUserCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserCpRights/SetUserCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointUserGroupCpRightsById: build.query<
      GetApiChargePointUserGroupCpRightsByIdApiResponse,
      GetApiChargePointUserGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserGroupCpRights/${queryArg}`,
      }),
    }),
    patchApiChargePointUserGroupCpRightsById: build.mutation<
      PatchApiChargePointUserGroupCpRightsByIdApiResponse,
      PatchApiChargePointUserGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserGroupCpRights/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateUserGroupCpRightRequest,
      }),
    }),
    deleteApiChargePointUserGroupCpRightsById: build.mutation<
      DeleteApiChargePointUserGroupCpRightsByIdApiResponse,
      DeleteApiChargePointUserGroupCpRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserGroupCpRights/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointUserGroupCpRights: build.query<
      GetApiChargePointUserGroupCpRightsApiResponse,
      GetApiChargePointUserGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserGroupCpRights`,
        params: { userId: queryArg },
      }),
    }),
    postApiChargePointUserGroupCpRights: build.mutation<
      PostApiChargePointUserGroupCpRightsApiResponse,
      PostApiChargePointUserGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserGroupCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargePointUserGroupCpRightsSetUserGroupCpRights: build.mutation<
      PostApiChargePointUserGroupCpRightsSetUserGroupCpRightsApiResponse,
      PostApiChargePointUserGroupCpRightsSetUserGroupCpRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/UserGroupCpRights/SetUserGroupCpRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointChargePointsByIdApiResponse =
  /** status 200 Success */ GetChargePointVmBaseResponse;
export type GetApiChargePointChargePointsByIdApiArg =
  /** Идентитфикатор сущности */ string;
export type PatchApiChargePointChargePointsByIdApiResponse =
  /** status 200 Success */ UpdateChargePointVmBaseResponse;
export type PatchApiChargePointChargePointsByIdApiArg = {
  /** Идентификатор сущности */
  id: string;
  /** Параметры */
  updateChargePointRequest: UpdateChargePointRequest;
};
export type DeleteApiChargePointChargePointsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointChargePointsByIdApiArg =
  /** Идентификатор сущности */ string;
export type GetApiChargePointChargePointsApiResponse =
  /** status 200 Success */ GetChargePointVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointChargePointsApiArg = {
  ownersId?: string[];
  groupId?: string;
  hubId?: string;
  mobileAppId?: string;
  name?: string;
  withTestCp?: boolean;
  dateFrom?: string;
  dateTo?: string;
  statuses?: ChargePointStatus[];
  ownerTypes?: OwnerType[];
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type PostApiChargePointChargePointsApiResponse =
  /** status 200 Success */ GetChargePointVmBaseResponse;
export type PostApiChargePointChargePointsApiArg = AddChargePointRequest;
export type GetApiChargePointChargePointsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointChargePointsCountApiArg = {
  ownersId?: string[];
  groupId?: string;
  hubId?: string;
  mobileAppId?: string;
  withTestCp?: boolean;
  statuses?: ChargePointStatus[];
  ownerTypes?: OwnerType[];
  name?: string;
  dateFrom?: string;
  dateTo?: string;
  limit?: number;
  offset?: number;
};
export type GetApiChargePointChargePointsCpStatusesApiResponse =
  /** status 200 Success */ GetStatusesVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointChargePointsCpStatusesApiArg = {
  ownersId?: string[];
  groupId?: string;
  hubId?: string;
  mobileAppId?: string;
  withTestCp?: boolean;
  withVisibleCp?: boolean;
  dateFrom?: string;
  dateTo?: string;
  offset?: number;
  limit?: number;
};
export type GetApiChargePointChargePointsInfoApiResponse =
  /** status 200 Success */ GetChargeBoxIdentityVmBaseResponse;
export type GetApiChargePointChargePointsInfoApiArg =
  /** Спецальное уникальное название станции на уровне протокола(защита от перебора). Когда станция подключается к контроллеру она сообщает этот алиас */ string;
export type PostApiChargePointCommandsStartApiResponse =
  /** status 200 Success */ StartCommandVmBaseResponse;
export type PostApiChargePointCommandsStartApiArg =
  /** Параметры */ StartCommandRequest;
export type PostApiChargePointCommandsStopApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointCommandsStopApiArg =
  /** Параметры */ StopCommandRequest;
export type GetApiChargePointChargePointsByChargePintsIdAndConnectorIdApiResponse =
  /** status 200 Success */ GetConnectorsVmBaseResponse;
export type GetApiChargePointChargePointsByChargePintsIdAndConnectorIdApiArg = {
  /** индетификатор зарядки */
  chargePintsId: string;
  /** индетификатор коннектора */
  connectorId: string;
};
export type PostApiChargePointChargePointsByChargePointsIdConnectorApiResponse =
  /** status 200 Success */ GetConnectorsVmBaseResponse;
export type PostApiChargePointChargePointsByChargePointsIdConnectorApiArg = {
  /** Идентификатор зарядной станции */
  chargePointsId: string;
  /** Параметры */
  addConnectorRequest: AddConnectorRequest;
};
export type PatchApiChargePointChargePointsByChargePointsIdAndConnectorIdApiResponse =
  /** status 200 Success */ GetConnectorsVmBaseResponse;
export type PatchApiChargePointChargePointsByChargePointsIdAndConnectorIdApiArg =
  {
    /** Идентификатор зарядки */
    chargePointsId: string;
    /** Идентификатор коннектора */
    connectorId: string;
    /** Параметры */
    updateConnectorRequest: UpdateConnectorRequest;
  };
export type DeleteApiChargePointChargePointsByChargePointsIdAndConnectorIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointChargePointsByChargePointsIdAndConnectorIdApiArg =
  {
    /** Идентификатор зарядной станции */
    chargePointsId: string;
    /** Идентификатор коннектора */
    connectorId: string;
  };
export type GetApiChargePointConnectorsTypesApiResponse =
  /** status 200 Success */ StringArrayBaseResponse;
export type GetApiChargePointConnectorsTypesApiArg = void;
export type GetApiChargePointEventsByIdApiResponse =
  /** status 200 Success */ GetEventVmBaseResponse;
export type GetApiChargePointEventsByIdApiArg = /** Идентификтаор */ string;
export type DeleteApiChargePointEventsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointEventsByIdApiArg = /** Идентификатор */ string;
export type GetApiChargePointEventsApiResponse =
  /** status 200 Success */ GetEventVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointEventsApiArg = {
  name?: EventName;
  type?: EventType;
  chargePointId?: string;
  connectorId?: string;
  transactionId?: string;
  dateFrom?: string;
  dateTo?: string;
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type GetApiChargePointEventsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointEventsCountApiArg = {
  name?: EventName;
  type?: EventType;
  chargePointId?: string;
  connectorId?: string;
  transactionId?: string;
  dateFrom?: string;
  dateTo?: string;
};
export type PostApiChargePointEventsDisconnectEventApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsDisconnectEventApiArg =
  DisconnectEventRequest;
export type PostApiChargePointEventsBootNotificationRequestApiResponse =
  /** status 200 Success */ BootNotificationEventVmBaseResponse;
export type PostApiChargePointEventsBootNotificationRequestApiArg = {
  idempotencyKey?: string;
  bootNotificationRequest: BootNotificationRequest;
};
export type PostApiChargePointEventsBootNotificationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsBootNotificationConfirmationApiArg = {
  idempotencyKey?: string;
  bootNotificationConfirmationRequest: BootNotificationConfirmationRequest;
};
export type PostApiChargePointEventsRemoteStartTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsRemoteStartTransactionRequestApiArg = {
  idempotencyKey?: string;
  remoteStartTransactionRequest: RemoteStartTransactionRequest;
};
export type PostApiChargePointEventsRemoteStartTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsRemoteStartTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  remoteStartTransactionConfirmationRequest: RemoteStartTransactionConfirmationRequest;
};
export type PostApiChargePointEventsStartTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStartTransactionRequestApiArg = {
  idempotencyKey?: string;
  startTransactionRequest: StartTransactionRequest;
};
export type PostApiChargePointEventsStartTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStartTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  startTransactionConfirmationRequest: StartTransactionConfirmationRequest;
};
export type PostApiChargePointEventsRemoteStopTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsRemoteStopTransactionRequestApiArg = {
  idempotencyKey?: string;
  remoteStopTransactionRequest: RemoteStopTransactionRequest;
};
export type PostApiChargePointEventsRemoteStopTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsRemoteStopTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  remoteStopTransactionConfirmationRequest: RemoteStopTransactionConfirmationRequest;
};
export type PostApiChargePointEventsStopTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStopTransactionRequestApiArg = {
  idempotencyKey?: string;
  stopTransactionRequest: StopTransactionRequest;
};
export type PostApiChargePointEventsStopTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStopTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  stopTransactionConfirmationRequest: StopTransactionConfirmationRequest;
};
export type PostApiChargePointEventsStatusNotificationRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStatusNotificationRequestApiArg = {
  idempotencyKey?: string;
  statusNotificationRequest: StatusNotificationRequest;
};
export type PostApiChargePointEventsStatusNotificationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStatusNotificationConfirmationApiArg = {
  idempotencyKey?: string;
  statusNotificationConfirmationRequest: StatusNotificationConfirmationRequest;
};
export type PostApiChargePointEventsMeterValuesRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsMeterValuesRequestApiArg = {
  idempotencyKey?: string;
  meterValuesRequest: MeterValuesRequest;
};
export type PostApiChargePointEventsMeterValuesConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsMeterValuesConfirmationApiArg = {
  idempotencyKey?: string;
  meterValuesConfirmationRequest: MeterValuesConfirmationRequest;
};
export type PostApiChargePointEventsChangeConfigurationRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsChangeConfigurationRequestApiArg = {
  idempotencyKey?: string;
  changeConfigurationRequest: ChangeConfigurationRequest;
};
export type PostApiChargePointEventsChangeConfigurationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsChangeConfigurationConfirmationApiArg = {
  idempotencyKey?: string;
  changeConfigurationConfirmationRequest: ChangeConfigurationConfirmationRequest;
};
export type PostApiChargePointEventsUnlockConnectorRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsUnlockConnectorRequestApiArg = {
  idempotencyKey?: string;
  unlockConnectorRequest: UnlockConnectorRequest;
};
export type PostApiChargePointEventsUnlockConnectorConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsUnlockConnectorConfirmationApiArg = {
  idempotencyKey?: string;
  unlockConnectorConfirmationRequest: UnlockConnectorConfirmationRequest;
};
export type PostApiChargePointEventsChangeAvailabilityRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsChangeAvailabilityRequestApiArg = {
  idempotencyKey?: string;
  changeAvailablilityRequest: ChangeAvailablilityRequest;
};
export type PostApiChargePointEventsChangeAvailabilityConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsChangeAvailabilityConfirmationApiArg = {
  idempotencyKey?: string;
  changeAvailablilityConfirmationRequest: ChangeAvailablilityConfirmationRequest;
};
export type PostApiChargePointEventsDataTransferRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsDataTransferRequestApiArg = {
  idempotencyKey?: string;
  dataTransferRequest: DataTransferRequest;
};
export type PostApiChargePointEventsDataTransferConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsDataTransferConfirmationApiArg = {
  idempotencyKey?: string;
  dataTransferConfirmationRequest: DataTransferConfirmationRequest;
};
export type PostApiChargePointEventsResetRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsResetRequestApiArg = {
  idempotencyKey?: string;
  resetRequest: ResetRequest;
};
export type PostApiChargePointEventsResetConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsResetConfirmationApiArg = {
  idempotencyKey?: string;
  resetConfirmationRequest: ResetConfirmationRequest;
};
export type GetApiChargePointFirmwaresByIdApiResponse =
  /** status 200 Success */ GetFirmwareVmBaseResponse;
export type GetApiChargePointFirmwaresByIdApiArg = /** Идентификатор */ string;
export type PatchApiChargePointFirmwaresByIdApiResponse =
  /** status 200 Success */ GetFirmwareVmBaseResponse;
export type PatchApiChargePointFirmwaresByIdApiArg = {
  /** Идентификатор */
  id: string;
  updateFirmwareRequest: UpdateFirmwareRequest;
};
export type DeleteApiChargePointFirmwaresByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointFirmwaresByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargePointFirmwaresApiResponse =
  /** status 200 Success */ GetFirmwareVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointFirmwaresApiArg = void;
export type PostApiChargePointFirmwaresApiResponse =
  /** status 200 Success */ GetFirmwareVmBaseResponse;
export type PostApiChargePointFirmwaresApiArg =
  /** Параметры */ AddFirmwareRequest;
export type GetApiChargePointFirmwaresCountApiResponse =
  /** status 200 Success */ UInt32BaseResponse;
export type GetApiChargePointFirmwaresCountApiArg = void;
export type GetApiChargePointGroupsByIdApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type GetApiChargePointGroupsByIdApiArg = string;
export type PatchApiChargePointGroupsByIdApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type PatchApiChargePointGroupsByIdApiArg = {
  /** Идентификатор сущности */
  id: string;
  /** Параметры */
  updateGroupRequest: UpdateGroupRequest;
};
export type DeleteApiChargePointGroupsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointGroupsByIdApiArg =
  /** Идентификатор сущности */ string;
export type GetApiChargePointGroupsApiResponse =
  /** status 200 Success */ GetGroupVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointGroupsApiArg = {
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type PostApiChargePointGroupsApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type PostApiChargePointGroupsApiArg = AddGroupRequest;
export type GetApiChargePointGroupsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointGroupsCountApiArg = void;
export type GetApiChargePointHubsByIdApiResponse =
  /** status 200 Success */ GetHubVmBaseResponse;
export type GetApiChargePointHubsByIdApiArg =
  /** Идентификатор сущности */ string;
export type PatchApiChargePointHubsByIdApiResponse =
  /** status 200 Success */ GetHubVmBaseResponse;
export type PatchApiChargePointHubsByIdApiArg = {
  /** Идентификатор сущности */
  id: string;
  /** Параметры */
  updateHubRequest: UpdateHubRequest;
};
export type DeleteApiChargePointHubsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointHubsByIdApiArg =
  /** Идентификатор сущности */ string;
export type GetApiChargePointHubsApiResponse =
  /** status 200 Success */ GetHubVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointHubsApiArg = {
  limit?: number;
  offset?: number;
};
export type PostApiChargePointHubsApiResponse =
  /** status 200 Success */ GetHubVmBaseResponse;
export type PostApiChargePointHubsApiArg = /** Параметры */ AddHubRequest;
export type GetApiChargePointHubsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointHubsCountApiArg = void;
export type GetApiChargePointOwnersByIdApiResponse =
  /** status 200 Success */ GetOwnerVmBaseResponse;
export type GetApiChargePointOwnersByIdApiArg =
  /** Идентификатор сущности */ string;
export type PatchApiChargePointOwnersByIdApiResponse =
  /** status 200 Success */ GetOwnerVmBaseResponse;
export type PatchApiChargePointOwnersByIdApiArg = {
  /** Идентификатор сущности */
  id: string;
  /** Параметры */
  updateOwnerRequest: UpdateOwnerRequest;
};
export type DeleteApiChargePointOwnersByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointOwnersByIdApiArg =
  /** Идентификатор сущности */ string;
export type GetApiChargePointOwnersApiResponse =
  /** status 200 Success */ GetOwnerVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointOwnersApiArg = {
  limit?: number;
  offset?: number;
  connectorId?: string;
  chargePointId?: string;
};
export type PostApiChargePointOwnersApiResponse =
  /** status 200 Success */ GetOwnerVmBaseResponse;
export type PostApiChargePointOwnersApiArg = /** Параметры */ AddOwnerRequest;
export type GetApiChargePointOwnersCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointOwnersCountApiArg = void;
export type GetApiChargePointPhotosByIdApiResponse =
  /** status 200 Success */ GetPhotoVmBaseResponse;
export type GetApiChargePointPhotosByIdApiArg =
  /** Идентификатор сущности */ string;
export type PatchApiChargePointPhotosByIdApiResponse =
  /** status 200 Success */ GetPhotoVmBaseResponse;
export type PatchApiChargePointPhotosByIdApiArg = {
  /** Идентификатор */
  id: string;
  updatePhotoRequest: UpdatePhotoRequest;
};
export type DeleteApiChargePointPhotosByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointPhotosByIdApiArg = /** Идентификатор */ string;
export type GetApiChargePointPhotosApiResponse =
  /** status 200 Success */ GetPhotoVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointPhotosApiArg = string;
export type PostApiChargePointPhotosApiResponse =
  /** status 200 Success */ GetPhotoVmBaseResponse;
export type PostApiChargePointPhotosApiArg = {
  chargePointId: string;
  priority: number;
  body: {
    File?: Blob;
  };
};
export type GetApiChargePointPhotosCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointPhotosCountApiArg = void;
export type GetApiChargePointRoleCpRightsByIdApiResponse =
  /** status 200 Success */ GetRoleCpRightVmBaseResponse;
export type GetApiChargePointRoleCpRightsByIdApiArg =
  /** Идентификтаор */ string;
export type PatchApiChargePointRoleCpRightsByIdApiResponse =
  /** status 200 Success */ GetRoleCpRightVmBaseResponse;
export type PatchApiChargePointRoleCpRightsByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Параметры */
  updateRoleCpRightsRequest: UpdateRoleCpRightsRequest;
};
export type DeleteApiChargePointRoleCpRightsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointRoleCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargePointRoleCpRightsApiResponse =
  /** status 200 Success */ GetRoleCpRightVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointRoleCpRightsApiArg =
  /** Идентификтаор пользователя */ string;
export type PostApiChargePointRoleCpRightsApiResponse =
  /** status 200 Success */ GetRoleCpRightVmBaseResponse;
export type PostApiChargePointRoleCpRightsApiArg =
  /** Параметры */ AddRoleCpRightsRequest;
export type PostApiChargePointRoleCpRightsSetRoleCpRightsApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointRoleCpRightsSetRoleCpRightsApiArg =
  /** Параметры */ SetRoleCpRightRequest;
export type GetApiChargePointRoleGroupCpRightsByIdApiResponse =
  /** status 200 Success */ GetRoleGroupCpRightVmBaseResponse;
export type GetApiChargePointRoleGroupCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type PatchApiChargePointRoleGroupCpRightsByIdApiResponse =
  /** status 200 Success */ GetRoleGroupCpRightVmBaseResponse;
export type PatchApiChargePointRoleGroupCpRightsByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Параметры */
  updateRoleGroupCpRightRequest: UpdateRoleGroupCpRightRequest;
};
export type DeleteApiChargePointRoleGroupCpRightsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointRoleGroupCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargePointRoleGroupCpRightsApiResponse =
  /** status 200 Success */ GetRoleGroupCpRightVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointRoleGroupCpRightsApiArg = /** Id группы */ string;
export type PostApiChargePointRoleGroupCpRightsApiResponse =
  /** status 200 Success */ GetRoleGroupCpRightVmBaseResponse;
export type PostApiChargePointRoleGroupCpRightsApiArg =
  /** Параметры */ AddRoleGroupCpRightRequest;
export type PostApiChargePointRoleGroupCpRightsSetRoleGroupCpRightsApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointRoleGroupCpRightsSetRoleGroupCpRightsApiArg =
  /** Параметры */ SetRoleGroupCpRightsRequest;
export type GetApiChargePointSessionsByIdApiResponse =
  /** status 200 Success */ GetSessionVmBaseResponse;
export type GetApiChargePointSessionsByIdApiArg = /** Идентификатор */ string;
export type GetApiChargePointSessionsApiResponse =
  /** status 200 Success */ GetSessionVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointSessionsApiArg = {
  chargePointId?: string;
  connectorId?: string;
  userId?: string;
  status?: SessionStatus;
  transactionId?: string;
  dateCreateFrom?: string;
  dateCreateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  dateFrom?: string;
  dateTo?: string;
  chargePointGroupId?: string;
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type GetApiChargePointSessionsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointSessionsCountApiArg = {
  chargePointId?: string;
  connectorId?: string;
  userId?: string;
  status?: SessionStatus;
  transactionId?: string;
  dateCreateFrom?: string;
  dateCreateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  dateFrom?: string;
  dateTo?: string;
};
export type GetApiChargePointSessionsByIdGraphMeterValuesApiResponse =
  /** status 200 Success */ GetGraphMeterVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointSessionsByIdGraphMeterValuesApiArg = {
  id: string;
  /** Временной промежуток ОТ */
  dateFrom?: string;
};
export type GetApiChargePointUserCpRightsByIdApiResponse =
  /** status 200 Success */ GetUserCpRightVmBaseResponse;
export type GetApiChargePointUserCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type PatchApiChargePointUserCpRightsByIdApiResponse =
  /** status 200 Success */ GetUserCpRightVmBaseResponse;
export type PatchApiChargePointUserCpRightsByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Параметры */
  updateUserCpRightRequest: UpdateUserCpRightRequest;
};
export type DeleteApiChargePointUserCpRightsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointUserCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargePointUserCpRightsApiResponse =
  /** status 200 Success */ GetUserCpRightVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointUserCpRightsApiArg =
  /** Идентификатор пользователя */ string;
export type PostApiChargePointUserCpRightsApiResponse =
  /** status 200 Success */ GetUserCpRightVmBaseResponse;
export type PostApiChargePointUserCpRightsApiArg =
  /** Параметры */ AddUserCpRightRequest;
export type PostApiChargePointUserCpRightsSetUserCpRightsApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointUserCpRightsSetUserCpRightsApiArg =
  /** Параметры */ SetUserCpRightRequest;
export type GetApiChargePointUserGroupCpRightsByIdApiResponse =
  /** status 200 Success */ GetUserGroupCpRightVmBaseResponse;
export type GetApiChargePointUserGroupCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type PatchApiChargePointUserGroupCpRightsByIdApiResponse =
  /** status 200 Success */ GetUserGroupCpRightVmBaseResponse;
export type PatchApiChargePointUserGroupCpRightsByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Параметры */
  updateUserGroupCpRightRequest: UpdateUserGroupCpRightRequest;
};
export type DeleteApiChargePointUserGroupCpRightsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointUserGroupCpRightsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargePointUserGroupCpRightsApiResponse =
  /** status 200 Success */ GetUserGroupCpRightVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointUserGroupCpRightsApiArg =
  /** Id пользователя */ string;
export type PostApiChargePointUserGroupCpRightsApiResponse =
  /** status 200 Success */ GetUserGroupCpRightVmBaseResponse;
export type PostApiChargePointUserGroupCpRightsApiArg =
  /** Параметры */ AddUserGroupCpRightRequest;
export type PostApiChargePointUserGroupCpRightsSetUserGroupCpRightsApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointUserGroupCpRightsSetUserGroupCpRightsApiArg =
  /** Параметры */ SetUserGroupCpRightsRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type SpeedType = 'UNDEFINED' | 'LOW_SPEED_AC' | 'HIGH_SPEED_DC';
export type ChargePointProtocol = 'OCPP16' | 'OCPP16J' | 'OCPP20';
export type ChargePointStatus =
  | 'NO_CONNECT'
  | 'AVAILABLE'
  | 'FAULTED'
  | 'UNAVAILABLE';
export type CountryCode = 'RU' | 'BY' | 'AM' | 'KZ' | 'KG';
export type GroupVm = {
  id: string;
  name: string;
};
export type OwnerVm = {
  id: string;
  name: string;
};
export type HubVm = {
  id: string;
  name: string;
  description?: string | null;
  address: string;
  latitude: number;
  longitude: number;
};
export type ConnectorType =
  | 'SCHUKO'
  | 'TYPE2'
  | 'CCS2'
  | 'CHADEMO'
  | 'GBTDC'
  | 'GBTAC'
  | 'CCS1'
  | 'TYPE1'
  | 'TESLA';
export type ConnectorFormat = 'SOCKET' | 'CABLE';
export type ConnectorStatus =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'PREPARING'
  | 'CHARGING'
  | 'SUSPENDED_EVSE'
  | 'SUSPENDED_EV'
  | 'FINISHING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type StatusForUser =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'CHARGING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type ConnectorVm = {
  id: string;
  type: ConnectorType;
  format: ConnectorFormat;
  status: ConnectorStatus;
  statusForUser: StatusForUser;
  innerConnectorId: number;
  maxPower: number;
  maxAmperage: number;
  maxVoltage?: number | null;
  manualBlock: boolean;
  lastUpdate: string;
  liter?: string | null;
};
export type PhotoVm = {
  url?: string | null;
  priority: number;
};
export type GetChargePointVm = {
  id: string;
  number: number;
  name: string;
  speedType: SpeedType;
  multiconnectorSupport: boolean;
  chargePointProtocol: ChargePointProtocol;
  status: ChargePointStatus;
  isVisible: boolean;
  maxPower: number;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode: CountryCode;
  city: string;
  address: string;
  latitude: number;
  longitude: number;
  model?: string | null;
  dipLinkCharby?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  chargeBoxIdentity: string;
  supportContact?: string | null;
  createdDate: string;
  lastUpdate: string;
  isTestCp: boolean;
  isBlocked: boolean;
  ocppConnectionBlockDate: string;
  group?: GroupVm;
  owner?: OwnerVm;
  hub?: HubVm;
  connectors?: ConnectorVm[] | null;
  photos?: PhotoVm[] | null;
};
export type GetChargePointVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetChargePointVm;
};
export type UpdateChargePointVm = {
  id: string;
  number: number;
  name: string;
  speedType: SpeedType;
  multiconnectorSupport: boolean;
  chargePointProtocol: ChargePointProtocol;
  status: ChargePointStatus;
  isVisible: boolean;
  maxPower: number;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  isBlocked: boolean;
  ocppConnectionBlockDate: string;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode: CountryCode;
  city: string;
  address: string;
  latitude: number;
  longitude: number;
  model?: string | null;
  dipLinkCharby?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  chargeBoxIdentity: string;
  supportContact?: string | null;
  createdDate: string;
  lastUpdate: string;
  isTestCp: boolean;
  group?: GroupVm;
  owner?: OwnerVm;
  hub?: HubVm;
  connectors?: ConnectorVm[] | null;
  photos?: PhotoVm[] | null;
};
export type UpdateChargePointVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateChargePointVm;
};
export type UpdateChargePointRequest = {
  number?: number | null;
  speedType?: SpeedType;
  multiconnectorSupport?: boolean | null;
  name?: string | null;
  chargePointProtocol?: ChargePointProtocol;
  status?: ChargePointStatus;
  isVisible?: boolean | null;
  maxPower?: number;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode?: CountryCode;
  city?: string | null;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  model?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  dipLinkCharby?: string | null;
  chargeBoxIdentity?: string | null;
  supportContact?: string | null;
  groupId?: string | null;
  ownerId?: string | null;
  hubId?: string | null;
  isTestCp?: boolean | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetChargePointVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetChargePointVm[] | null;
};
export type OwnerType = 'MAIN' | 'AGENT' | 'PARTNER';
export type OrderBy = 'Asc' | 'Desc';
export type AddChargePointRequest = {
  number?: number | null;
  speedType: SpeedType;
  multiconnectorSupport: boolean;
  chargePointProtocol: ChargePointProtocol;
  name: string;
  status: ChargePointStatus;
  isVisible: boolean;
  maxPower: number;
  commentary?: string | null;
  publicDescription?: string | null;
  userLocationCheckDistance?: number | null;
  warningText?: string | null;
  workingTime?: string | null;
  countryCode: CountryCode;
  city: string;
  address: string;
  latitude: number;
  longitude: number;
  model?: string | null;
  manufacturer?: string | null;
  softwareRevision?: string | null;
  ocppConfig?: string | null;
  ipAddress?: string | null;
  simNumber?: string | null;
  chargeBoxIdentity: string;
  supportContact?: string | null;
  dipLinkCharby?: string | null;
  groupId?: string | null;
  ownerId?: string | null;
  hubId?: string | null;
  isTestCp: boolean;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type GetStatusesConnectorVm = {
  id: string;
  status: ConnectorStatus;
  type: ConnectorType;
  innerConnectorId: number;
  statusForUser: StatusForUser;
  maxPower: number;
};
export type GetStatusesVm = {
  chargePointId: string;
  status: ChargePointStatus;
  multiconnectorSupport: boolean;
  name: string;
  maxPower: number;
  city: string;
  address: string;
  number: number;
  warningText?: string | null;
  dipLinkCharby?: string | null;
  speedType: SpeedType;
  hubId?: string | null;
  latitude: number;
  longitude: number;
  lastUpdate: string;
  connectors?: GetStatusesConnectorVm[] | null;
};
export type GetStatusesVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetStatusesVm[] | null;
};
export type GetChargeBoxIdentityVm = {
  chargePointId: string;
  isBlocked: boolean;
};
export type GetChargeBoxIdentityVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetChargeBoxIdentityVm;
};
export type StartCommandVm = {
  sessionId?: string;
};
export type StartCommandVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: StartCommandVm;
};
export type StartCommandRequest = {
  userId: string;
  chargePointId: string;
  connectorId: string;
};
export type StopCommandRequest = {
  userId: string;
  chargePointId: string;
  connectorId: string;
};
export type GetConnectorsVm = {
  id: string;
  type: ConnectorType;
  format: ConnectorFormat;
  chargePointId: string;
  status: ConnectorStatus;
  statusForUser: StatusForUser;
  innerConnectorId: number;
  maxPower: number;
  maxAmperage: number;
  maxVoltage: number;
  manualBlock: boolean;
  lastUpdate: string;
  liter?: string | null;
};
export type GetConnectorsVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetConnectorsVm;
};
export type AddConnectorRequest = {
  type: ConnectorType;
  format: ConnectorFormat;
  status: ConnectorStatus;
  innerConnectorId: number;
  maxPower: number;
  maxAmperage?: number | null;
  maxVoltage?: number | null;
  liter?: string | null;
};
export type UpdateConnectorRequest = {
  type: ConnectorType;
  format: ConnectorFormat;
  status?: ConnectorStatus;
  innerConnectorId: number;
  maxPower: number;
  maxAmperage: number;
  maxVoltage: number;
  liter: string;
};
export type StringArrayBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: string[] | null;
};
export type EventName =
  | 'BOOT_NOTIFICATION'
  | 'REMOTE_START_TRANSACTION'
  | 'START_TRANSACTION'
  | 'REMOTE_STOP_TRANSACTION'
  | 'STOP_TRANSACTION'
  | 'STATUS_NOTIFICATION'
  | 'METER_VALUES'
  | 'RESET'
  | 'CHANGE_CONFIGURATION'
  | 'UNLOCK_CONNECTOR'
  | 'CHANGE_AVAILABILITY'
  | 'DATA_TRANSFER';
export type EventType = 'REQUEST' | 'CONFIRMATION';
export type RemoteStartStopStatus = 'ACCEPTED' | 'REJECTED';
export type GetEventVm = {
  id: string;
  chargePointProtocol: ChargePointProtocol;
  name: EventName;
  type: EventType;
  chargePointId: string;
  createdDate: string;
  connectorId?: string | null;
  connectorStatus?: ConnectorStatus;
  remoteStartStopStatus?: RemoteStartStopStatus;
  transactionId?: string | null;
  idempotencyKey: string;
  sessionId?: string | null;
  instantPower?: number | null;
  instantCurrent?: number | null;
  chargingPercentage?: number | null;
  energy?: number | null;
  meterStop?: number | null;
  meterStart?: number | null;
  data?: string | null;
};
export type GetEventVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetEventVm;
};
export type GetEventVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetEventVm[] | null;
};
export type DisconnectEventRequest = {
  reason: string;
  chargePointId: string;
};
export type BootNotificationEventVm = {
  date: string;
  isBlocked: boolean;
};
export type BootNotificationEventVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: BootNotificationEventVm;
};
export type BootNotificationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type BootNotificationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type RemoteStartTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
};
export type RemoteStartTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  remoteStartStopStatus: RemoteStartStopStatus;
};
export type StartTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  innerConnectorId: number;
  meterStart: number;
  data: string;
};
export type StartTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
};
export type RemoteStopTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
};
export type RemoteStopTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  remoteStartStopStatus: RemoteStartStopStatus;
};
export type StopTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  meterStop: number;
  reason: string;
  transactionId: string;
  instantPower?: number | null;
  chargingPercentage?: number | null;
  energy?: number | null;
};
export type StopTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type StatusNotificationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  status: ConnectorStatus;
  data: string;
  innerConnectorId: number;
};
export type StatusNotificationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type MeterValuesRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  innerConnectorId: number;
  transactionId: string;
  instantPower: number;
  instantCurrent: number;
  chargingPercentage: number;
  energy: number;
};
export type MeterValuesConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeConfigurationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeConfigurationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type UnlockConnectorRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type UnlockConnectorConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeAvailablilityRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeAvailablilityConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type DataTransferRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type DataTransferConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ResetRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ResetConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type FormatUrl = 'SOAP' | 'JSON';
export type GetFirmwareVm = {
  id: string;
  name: string;
  description?: string | null;
  url: string;
  objectStorageName: string;
  formatUrl: FormatUrl;
  lastUpdate: string;
};
export type GetFirmwareVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetFirmwareVm;
};
export type UpdateFirmwareRequest = {
  name: string;
  description: string;
  url: string;
  formatUrl: FormatUrl;
};
export type GetFirmwareVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetFirmwareVm[] | null;
};
export type AddFirmwareRequest = {
  name: string;
  description: string;
  url: string;
  formatUrl: FormatUrl;
};
export type UInt32BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type GetGroupVm = {
  id: string;
  name: string;
  comment?: string | null;
  children?: any[] | null;
  parentId?: string | null;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
  createdDate: string;
  lastUpdate: string;
};
export type GetGroupVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGroupVm;
};
export type UpdateGroupRequest = {
  name?: string | null;
  comment?: string | null;
  parentId?: string | null;
  isDefault?: boolean | null;
  mobileAppIds?: string[] | null;
};
export type GetGroupVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGroupVm[] | null;
};
export type AddGroupRequest = {
  name: string;
  comment?: string | null;
  parentId?: string | null;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
};
export type GetHubVm = {
  id: string;
  name: string;
  description?: string | null;
  address: string;
  latitude: number;
  longitude: number;
};
export type GetHubVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetHubVm;
};
export type UpdateHubRequest = {
  name?: string | null;
  description?: string | null;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
};
export type GetHubVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetHubVm[] | null;
};
export type AddHubRequest = {
  name: string;
  description?: string | null;
  address: string;
  latitude: number;
  longitude: number;
};
export type GetOwnerVm = {
  id: string;
  name: string;
  email: string;
  type: OwnerType;
  phoneNumber?: string | null;
  description?: string | null;
  legalName: string;
  inn: string;
  kpp: string;
  directorName?: string | null;
  telegram?: string | null;
};
export type GetOwnerVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetOwnerVm;
};
export type UpdateOwnerRequest = {
  name?: string | null;
  email?: string | null;
  type?: OwnerType;
  phoneNumber?: string | null;
  description?: string | null;
  legalName?: string | null;
  directorName?: string | null;
  inn?: string | null;
  kpp?: string | null;
  telegram?: string | null;
};
export type GetOwnerVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetOwnerVm[] | null;
};
export type AddOwnerRequest = {
  name: string;
  email?: string | null;
  type: OwnerType;
  phoneNumber?: string | null;
  description?: string | null;
  legalName: string;
  inn: string;
  directorName?: string | null;
  kpp: string;
  telegram?: string | null;
};
export type GetPhotoVm = {
  id: string;
  url: string;
  objectStorageName: string;
  chargePointId: string;
  priority: number;
};
export type GetPhotoVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetPhotoVm;
};
export type UpdatePhotoRequest = {
  url?: string | null;
  objectStorageName?: string | null;
  chargePointId?: string | null;
  priority?: number | null;
};
export type GetPhotoVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetPhotoVm[] | null;
};
export type GetRoleCpRightVm = {
  id: string;
  roleId: string;
  roleName: string;
  chargePointId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type GetRoleCpRightVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetRoleCpRightVm;
};
export type UpdateRoleCpRightsRequest = {
  roleId: string;
  roleName: string;
  chargePointId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type GetRoleCpRightVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetRoleCpRightVm[] | null;
};
export type AddRoleCpRightsRequest = {
  roleId: string;
  roleName: string;
  chargePointId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type RoleCpRightsRequest = {
  chargePointId: string;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type SetRoleCpRightRequest = {
  roleId: string;
  allCpRight?: boolean | null;
  cpRights?: RoleCpRightsRequest[] | null;
};
export type GetRoleGroupCpRightVm = {
  id: string;
  userId: string;
  userName: string;
  groupId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type GetRoleGroupCpRightVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetRoleGroupCpRightVm;
};
export type UpdateRoleGroupCpRightRequest = {
  userId: string;
  userName: string;
  groupId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type GetRoleGroupCpRightVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetRoleGroupCpRightVm[] | null;
};
export type AddRoleGroupCpRightRequest = {
  userId: string;
  userName: string;
  groupId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type RoleGroupCpRightRequest = {
  groupId: string;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type SetRoleGroupCpRightsRequest = {
  roleId: string;
  allCpRight?: boolean | null;
  groupCpRights?: RoleGroupCpRightRequest[] | null;
};
export type GetSessionVmChargePointVm = {
  chargePointId: string;
  name: string;
  groupId: string;
};
export type SessionStatus =
  | 'NEW'
  | 'PREPARATION'
  | 'CHARGING'
  | 'CHARGING_COMPLETED'
  | 'CHARGING_NOT_COMPLETED';
export type GetSessionVm = {
  id: string;
  chargePoint: GetSessionVmChargePointVm;
  chargePointId: string;
  connectorId: string;
  userId: string;
  status: SessionStatus;
  meterStart: number;
  meterStop?: number | null;
  energy: number;
  instantPower: number;
  instantCurrent: number;
  startChargingPercentage?: number | null;
  chargingPercentage?: number | null;
  maxPower: number;
  maxCurrent: number;
  stopReason?: string | null;
  transactionId: string;
  createdDate: string;
  closedDate: string;
  lastUpdate: string;
};
export type GetSessionVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionVm;
};
export type GetSessionVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionVm[] | null;
};
export type GetGraphMeterVm = {
  date: string;
  power?: number | null;
  chargingPercentage: number;
  energy?: number | null;
};
export type GetGraphMeterVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGraphMeterVm[] | null;
};
export type GetUserCpRightVm = {
  id: string;
  userId: string;
  userName: string;
  chargePointId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type GetUserCpRightVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetUserCpRightVm;
};
export type UpdateUserCpRightRequest = {
  userId: string;
  userName: string;
  chargePointId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type GetUserCpRightVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetUserCpRightVm[] | null;
};
export type AddUserCpRightRequest = {
  userId: string;
  userName: string;
  chargePointId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type UserCpRightRequest = {
  chargePointId: string;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type SetUserCpRightRequest = {
  userId: string;
  allCpRight?: boolean | null;
  cpRights?: UserCpRightRequest[] | null;
};
export type GetUserGroupCpRightVm = {
  id: string;
  userId: string;
  userName: string;
  groupId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type GetUserGroupCpRightVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetUserGroupCpRightVm;
};
export type UpdateUserGroupCpRightRequest = {
  userId?: string | null;
  userName?: string | null;
  groupId?: string | null;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type GetUserGroupCpRightVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetUserGroupCpRightVm[] | null;
};
export type AddUserGroupCpRightRequest = {
  userId: string;
  userName: string;
  groupId: string;
  read: boolean;
  write: boolean;
  execute: boolean;
};
export type UserGroupCpRightRequest = {
  groupId: string;
  read?: boolean | null;
  write?: boolean | null;
  execute?: boolean | null;
};
export type SetUserGroupCpRightsRequest = {
  userId: string;
  allCpRight?: boolean | null;
  groupCpRights?: UserGroupCpRightRequest[] | null;
};
export const {
  useGetApiChargePointChargePointsByIdQuery,
  usePatchApiChargePointChargePointsByIdMutation,
  useDeleteApiChargePointChargePointsByIdMutation,
  useGetApiChargePointChargePointsQuery,
  usePostApiChargePointChargePointsMutation,
  useGetApiChargePointChargePointsCountQuery,
  useGetApiChargePointChargePointsCpStatusesQuery,
  useGetApiChargePointChargePointsInfoQuery,
  usePostApiChargePointCommandsStartMutation,
  usePostApiChargePointCommandsStopMutation,
  useGetApiChargePointChargePointsByChargePintsIdAndConnectorIdQuery,
  usePostApiChargePointChargePointsByChargePointsIdConnectorMutation,
  usePatchApiChargePointChargePointsByChargePointsIdAndConnectorIdMutation,
  useDeleteApiChargePointChargePointsByChargePointsIdAndConnectorIdMutation,
  useGetApiChargePointConnectorsTypesQuery,
  useGetApiChargePointEventsByIdQuery,
  useDeleteApiChargePointEventsByIdMutation,
  useGetApiChargePointEventsQuery,
  useGetApiChargePointEventsCountQuery,
  usePostApiChargePointEventsDisconnectEventMutation,
  usePostApiChargePointEventsBootNotificationRequestMutation,
  usePostApiChargePointEventsBootNotificationConfirmationMutation,
  usePostApiChargePointEventsRemoteStartTransactionRequestMutation,
  usePostApiChargePointEventsRemoteStartTransactionConfirmationMutation,
  usePostApiChargePointEventsStartTransactionRequestMutation,
  usePostApiChargePointEventsStartTransactionConfirmationMutation,
  usePostApiChargePointEventsRemoteStopTransactionRequestMutation,
  usePostApiChargePointEventsRemoteStopTransactionConfirmationMutation,
  usePostApiChargePointEventsStopTransactionRequestMutation,
  usePostApiChargePointEventsStopTransactionConfirmationMutation,
  usePostApiChargePointEventsStatusNotificationRequestMutation,
  usePostApiChargePointEventsStatusNotificationConfirmationMutation,
  usePostApiChargePointEventsMeterValuesRequestMutation,
  usePostApiChargePointEventsMeterValuesConfirmationMutation,
  usePostApiChargePointEventsChangeConfigurationRequestMutation,
  usePostApiChargePointEventsChangeConfigurationConfirmationMutation,
  usePostApiChargePointEventsUnlockConnectorRequestMutation,
  usePostApiChargePointEventsUnlockConnectorConfirmationMutation,
  usePostApiChargePointEventsChangeAvailabilityRequestMutation,
  usePostApiChargePointEventsChangeAvailabilityConfirmationMutation,
  usePostApiChargePointEventsDataTransferRequestMutation,
  usePostApiChargePointEventsDataTransferConfirmationMutation,
  usePostApiChargePointEventsResetRequestMutation,
  usePostApiChargePointEventsResetConfirmationMutation,
  useGetApiChargePointFirmwaresByIdQuery,
  usePatchApiChargePointFirmwaresByIdMutation,
  useDeleteApiChargePointFirmwaresByIdMutation,
  useGetApiChargePointFirmwaresQuery,
  usePostApiChargePointFirmwaresMutation,
  useGetApiChargePointFirmwaresCountQuery,
  useGetApiChargePointGroupsByIdQuery,
  usePatchApiChargePointGroupsByIdMutation,
  useDeleteApiChargePointGroupsByIdMutation,
  useGetApiChargePointGroupsQuery,
  usePostApiChargePointGroupsMutation,
  useGetApiChargePointGroupsCountQuery,
  useGetApiChargePointHubsByIdQuery,
  usePatchApiChargePointHubsByIdMutation,
  useDeleteApiChargePointHubsByIdMutation,
  useGetApiChargePointHubsQuery,
  usePostApiChargePointHubsMutation,
  useGetApiChargePointHubsCountQuery,
  useGetApiChargePointOwnersByIdQuery,
  usePatchApiChargePointOwnersByIdMutation,
  useDeleteApiChargePointOwnersByIdMutation,
  useGetApiChargePointOwnersQuery,
  usePostApiChargePointOwnersMutation,
  useGetApiChargePointOwnersCountQuery,
  useGetApiChargePointPhotosByIdQuery,
  usePatchApiChargePointPhotosByIdMutation,
  useDeleteApiChargePointPhotosByIdMutation,
  useGetApiChargePointPhotosQuery,
  usePostApiChargePointPhotosMutation,
  useGetApiChargePointPhotosCountQuery,
  useGetApiChargePointRoleCpRightsByIdQuery,
  usePatchApiChargePointRoleCpRightsByIdMutation,
  useDeleteApiChargePointRoleCpRightsByIdMutation,
  useGetApiChargePointRoleCpRightsQuery,
  usePostApiChargePointRoleCpRightsMutation,
  usePostApiChargePointRoleCpRightsSetRoleCpRightsMutation,
  useGetApiChargePointRoleGroupCpRightsByIdQuery,
  usePatchApiChargePointRoleGroupCpRightsByIdMutation,
  useDeleteApiChargePointRoleGroupCpRightsByIdMutation,
  useGetApiChargePointRoleGroupCpRightsQuery,
  usePostApiChargePointRoleGroupCpRightsMutation,
  usePostApiChargePointRoleGroupCpRightsSetRoleGroupCpRightsMutation,
  useGetApiChargePointSessionsByIdQuery,
  useGetApiChargePointSessionsQuery,
  useGetApiChargePointSessionsCountQuery,
  useGetApiChargePointSessionsByIdGraphMeterValuesQuery,
  useGetApiChargePointUserCpRightsByIdQuery,
  usePatchApiChargePointUserCpRightsByIdMutation,
  useDeleteApiChargePointUserCpRightsByIdMutation,
  useGetApiChargePointUserCpRightsQuery,
  usePostApiChargePointUserCpRightsMutation,
  usePostApiChargePointUserCpRightsSetUserCpRightsMutation,
  useGetApiChargePointUserGroupCpRightsByIdQuery,
  usePatchApiChargePointUserGroupCpRightsByIdMutation,
  useDeleteApiChargePointUserGroupCpRightsByIdMutation,
  useGetApiChargePointUserGroupCpRightsQuery,
  usePostApiChargePointUserGroupCpRightsMutation,
  usePostApiChargePointUserGroupCpRightsSetUserGroupCpRightsMutation,
} = injectedRtkApi;
