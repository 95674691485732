import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInformationCarBrandsById: build.query<
      GetApiInformationCarBrandsByIdApiResponse,
      GetApiInformationCarBrandsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Information/CarBrands/${queryArg}` }),
    }),
    patchApiInformationCarBrandsById: build.mutation<
      PatchApiInformationCarBrandsByIdApiResponse,
      PatchApiInformationCarBrandsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/CarBrands/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateCarBrandRequest,
      }),
    }),
    deleteApiInformationCarBrandsById: build.mutation<
      DeleteApiInformationCarBrandsByIdApiResponse,
      DeleteApiInformationCarBrandsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/CarBrands/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiInformationCarBrands: build.query<
      GetApiInformationCarBrandsApiResponse,
      GetApiInformationCarBrandsApiArg
    >({
      query: () => ({ url: `/Api/Information/CarBrands` }),
    }),
    postApiInformationCarBrands: build.mutation<
      PostApiInformationCarBrandsApiResponse,
      PostApiInformationCarBrandsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/CarBrands`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    putApiInformationCarBrandsLogoById: build.mutation<
      PutApiInformationCarBrandsLogoByIdApiResponse,
      PutApiInformationCarBrandsLogoByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/CarBrands/Logo/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiInformationCarBrandsLogoById: build.mutation<
      DeleteApiInformationCarBrandsLogoByIdApiResponse,
      DeleteApiInformationCarBrandsLogoByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/CarBrands/Logo/${queryArg}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiInformationCarBrandsByIdApiResponse =
  /** status 200 Success */ GetCarBrandVmBaseResponse;
export type GetApiInformationCarBrandsByIdApiArg =
  /** Идентификатор бренда */ string;
export type PatchApiInformationCarBrandsByIdApiResponse =
  /** status 200 Success */ UpdateCarBrandVmBaseResponse;
export type PatchApiInformationCarBrandsByIdApiArg = {
  /** Идентификатор бренда */
  id: string;
  /** Параметры */
  updateCarBrandRequest: UpdateCarBrandRequest;
};
export type DeleteApiInformationCarBrandsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiInformationCarBrandsByIdApiArg =
  /** Идентификатор бренда */ string;
export type GetApiInformationCarBrandsApiResponse =
  /** status 200 Success */ GetCarBrandVmIReadOnlyCollectionBaseResponse;
export type GetApiInformationCarBrandsApiArg = void;
export type PostApiInformationCarBrandsApiResponse =
  /** status 200 Success */ AddCarBrandVmBaseResponse;
export type PostApiInformationCarBrandsApiArg =
  /** Параметры */ AddCarBrandRequest;
export type PutApiInformationCarBrandsLogoByIdApiResponse =
  /** status 200 Success */ AddOrUpdateLogoCarBrandVmBaseResponse;
export type PutApiInformationCarBrandsLogoByIdApiArg = {
  /** Идентификатор бренда */
  id: string;
  body: {
    file?: Blob;
  };
};
export type DeleteApiInformationCarBrandsLogoByIdApiResponse =
  /** status 200 Success */ DeleteCarBrandLogoVmBaseResponse;
export type DeleteApiInformationCarBrandsLogoByIdApiArg =
  /** Идентификатор бренда */ string;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetCarBrandVm = {
  id: string;
  name: string;
  logoUrl?: string | null;
  objectStorageName?: string | null;
};
export type GetCarBrandVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCarBrandVm;
};
export type UpdateCarBrandVm = {
  id: string;
  name: string;
  logoUrl?: string | null;
  objectStorageName?: string | null;
};
export type UpdateCarBrandVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateCarBrandVm;
};
export type UpdateCarBrandRequest = {
  name: string;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetCarBrandVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCarBrandVm[] | null;
};
export type AddCarBrandVm = {
  id: string;
  name: string;
  logoUrl?: string | null;
  objectStorageName?: string | null;
};
export type AddCarBrandVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddCarBrandVm;
};
export type AddCarBrandRequest = {
  name: string;
};
export type AddOrUpdateLogoCarBrandVm = {
  id: string;
  name: string;
  logoUrl: string;
  objectStorageName: string;
};
export type AddOrUpdateLogoCarBrandVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddOrUpdateLogoCarBrandVm;
};
export type DeleteCarBrandLogoVm = {
  id?: string;
  name?: string | null;
  logoUrl?: string | null;
  objectStorageName?: string | null;
};
export type DeleteCarBrandLogoVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: DeleteCarBrandLogoVm;
};
export const {
  useGetApiInformationCarBrandsByIdQuery,
  usePatchApiInformationCarBrandsByIdMutation,
  useDeleteApiInformationCarBrandsByIdMutation,
  useGetApiInformationCarBrandsQuery,
  usePostApiInformationCarBrandsMutation,
  usePutApiInformationCarBrandsLogoByIdMutation,
  useDeleteApiInformationCarBrandsLogoByIdMutation,
} = injectedRtkApi;
