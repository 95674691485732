import { LogoutButton } from '../logout-button';
import { NavMenu } from '../nav-menu';
import { NavMenuItem } from '../nav-menu/types';
import { StyledContainer, StyledLayout } from './styles';

type Props = { items: NavMenuItem[] };

export function SidebarLayout({ items }: Props) {
  return (
    <StyledLayout>
      <StyledContainer>
        <NavMenu items={items} />
        <LogoutButton />
      </StyledContainer>
    </StyledLayout>
  );
}
