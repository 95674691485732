import { MainLayout } from 'shared/ui/layout/main';

import { ChargePointsFetchingContextProvider } from '../../charge-points-context';
import { TabMenu } from '../charge-points-tab-menu';

export function ChargePointsLayout() {
  return (
    <ChargePointsFetchingContextProvider>
      <MainLayout tabs={<TabMenu />} />
    </ChargePointsFetchingContextProvider>
  );
}
