import { Jumper } from '../jumper';
import { NavButtons } from '../nav-buttons';
import { PageSize } from '../size';
import {
  PageControlsContainer,
  PageSizeLabel,
  PagesTotalCount,
  PaginationContainer,
} from './styles';

type Props = {
  limit: number;
  setLimit: (val: number) => void;
  totalCount: number;
  pagesCount: number;
  page: number;
  setPage: (val: number) => void;
  prevDisabled: boolean;
  nextDisabled: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
};

export function PaginationControls({
  limit,
  setLimit,
  page,
  setPage,
  totalCount,
  pagesCount,
  prevDisabled,
  nextDisabled,
  onPrevClick,
  onNextClick,
}: Props) {
  return (
    <PaginationContainer>
      <PageSize size={limit} totalCount={totalCount} setSize={setLimit} />
      <PageControlsContainer>
        <PageSizeLabel>Страница</PageSizeLabel>
        <Jumper page={String(page)} setPage={setPage} />
        <PagesTotalCount>{`из ${pagesCount}`}</PagesTotalCount>
        <NavButtons
          prevDisabled={prevDisabled}
          nextDisabled={nextDisabled}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
        />
      </PageControlsContainer>
    </PaginationContainer>
  );
}
