export const ADD_PREFIX = 'create';

export const SETTINGS = 'settings';
export const SECURITY = 'security';

export const GROUPS = 'groups';
export const GROUP = ':groupId';
export const RESERVES = 'reserves';
export const MANUAL_COMMANDS = 'manual-commands';

export const CONTROLLER_CLAIMS = 'controller-claims';
export const CHARGE_POINTS_RIGHTS = 'charge-point-rights';
