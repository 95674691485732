import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetCompany } from 'shared/api/services/company/enhanced';

import { Form } from './form';

const pageParamsSchema = z.object({
  companyId: z.string(),
});

export function FormContainer() {
  const { companyId } = useTypedParams(pageParamsSchema);

  const {
    isFetching: isCompanyFetching,
    error: companyError,
    data: company,
  } = useGetCompany(companyId);

  const error = [companyError].find((err) => err !== undefined);

  const isLoading = isCompanyFetching;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!company?.data) {
    return <EmptyData />;
  }

  return <Form company={company.data} />;
}
