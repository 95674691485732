import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInformationCarModelsById: build.query<
      GetApiInformationCarModelsByIdApiResponse,
      GetApiInformationCarModelsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Information/CarModels/${queryArg}` }),
    }),
    patchApiInformationCarModelsById: build.mutation<
      PatchApiInformationCarModelsByIdApiResponse,
      PatchApiInformationCarModelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/CarModels/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateCarModelRequest,
      }),
    }),
    deleteApiInformationCarModelsById: build.mutation<
      DeleteApiInformationCarModelsByIdApiResponse,
      DeleteApiInformationCarModelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/CarModels/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiInformationCarModels: build.query<
      GetApiInformationCarModelsApiResponse,
      GetApiInformationCarModelsApiArg
    >({
      query: () => ({ url: `/Api/Information/CarModels` }),
    }),
    postApiInformationCarModels: build.mutation<
      PostApiInformationCarModelsApiResponse,
      PostApiInformationCarModelsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/CarModels`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiInformationCarModelsByIdApiResponse =
  /** status 200 Success */ GetCarModelVmBaseResponse;
export type GetApiInformationCarModelsByIdApiArg = /** Идентификатор */ string;
export type PatchApiInformationCarModelsByIdApiResponse =
  /** status 200 Success */ UpdateCarModelVmBaseResponse;
export type PatchApiInformationCarModelsByIdApiArg = {
  /** Идентификатор модели */
  id: string;
  /** Параметры */
  updateCarModelRequest: UpdateCarModelRequest;
};
export type DeleteApiInformationCarModelsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiInformationCarModelsByIdApiArg =
  /** Идентификатор модели */ string;
export type GetApiInformationCarModelsApiResponse =
  /** status 200 Success */ GetCarModelVmIReadOnlyCollectionBaseResponse;
export type GetApiInformationCarModelsApiArg = void;
export type PostApiInformationCarModelsApiResponse =
  /** status 200 Success */ AddCarModelVmBaseResponse;
export type PostApiInformationCarModelsApiArg =
  /** Параметры */ AddCarModelRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetCarModelVm = {
  id: string;
  name: string;
  carBrandId: string;
};
export type GetCarModelVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCarModelVm;
};
export type UpdateCarModelVm = {
  id: string;
  name: string;
  carBrandId: string;
};
export type UpdateCarModelVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateCarModelVm;
};
export type UpdateCarModelRequest = {
  name?: string | null;
  carBrandId?: string | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetCarModelVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCarModelVm[] | null;
};
export type AddCarModelVm = {
  id: string;
  name: string;
  carBrandId: string;
};
export type AddCarModelVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddCarModelVm;
};
export type AddCarModelRequest = {
  carBrandId: string;
  name: string;
};
export const {
  useGetApiInformationCarModelsByIdQuery,
  usePatchApiInformationCarModelsByIdMutation,
  useDeleteApiInformationCarModelsByIdMutation,
  useGetApiInformationCarModelsQuery,
  usePostApiInformationCarModelsMutation,
} = injectedRtkApi;
