import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { OWNER_ROUTES } from 'shared/consts/routes/owner';

import { SECTION } from 'entities/navigation';
import { useGetIdentityUsers } from 'shared/api/services/identity/rtk/enhanced';

const { label, path } = SECTION['users'];

export function MenuContainer() {
  const { data, isFetching, error } = useGetIdentityUsers();

  if (data && data.status === 'Error') {
    return <NavMenuError text={data.statusMessage} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = (data?.users || []).map(({ userName }) => ({
    id: encodeURIComponent(userName),
    name: userName,
  }));

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={path}
      absolutePath={OWNER_ROUTES.OWNERS}
      label={label}
    />
  );
}
