import { useState } from 'react';
import { Select } from 'antd';
// import { useParams } from 'react-router-dom';

import { RequiredLabel } from 'shared/ui';

import {
  ManualCommandType,
  MANUAL_COMMAND_TYPE,
} from 'entities/manual-command';

import { InputContainer, InputLabel } from '../../../../common-styles';

import { FinishChargingForm } from '../forms/FinishCharging';
import { ChangeAvailabilityForm } from '../forms/ChangeAvailability';
import { UnlockConnectorForm } from '../forms/UnlockConnector';
import { RebootForm } from '../forms/Reboot';
import { UpdateSoftwareForm } from '../forms/UpdateSoftware';
import { SendSessionForRepaymentForm } from '../forms/SendSessionForRepayment';
import { ForceSessionStatusPaidForm } from '../forms/ForceSessionStatusPaid';
import { ConnectedChangeConfigurationForm } from '../forms/ConnectedChangeConfigurationForm';
import { GetDiagnosticsForm } from '../forms/GetDiagnosticsForm';
// import { SetChargeTimeLimitForm } from '../forms/SetChargeTimelimit';

import { ManualCommandFormContainer } from './styles';
import { UiSelect } from 'shared/ui/ui-kit';

// import DateIcon from './images/date.svg';
// import TimeIcon from './images/time.svg';

type Props = {
  closeModal: () => void;
  commandType?: ManualCommandType;
  chargePointName?: string;
  transactionId?: string;
  connectorId?: string;
  chargePointId?: string;
};

export function ManualCommandForm({
  commandType,
  closeModal,
  chargePointName,
  transactionId,
  connectorId,
  chargePointId,
}: Props) {
  const [type, setType] = useState(commandType);

  const handleTypeChange = (value: ManualCommandType) => setType(value);

  const cpName = chargePointName ?? '';

  const COMMAND_TYPE_PARAMETERS_MAPPING: Record<
    Exclude<ManualCommandType, 4>,
    JSX.Element
  > = {
    0: (
      <FinishChargingForm
        commandType={0}
        closeModal={closeModal}
        cpName={cpName}
        transactionId={transactionId ?? ''}
        isDisabled={!(chargePointName && transactionId)}
      />
    ),
    1: (
      <ChangeAvailabilityForm
        commandType={1}
        closeModal={closeModal}
        cpName={cpName}
        connectorId={connectorId ?? ''}
      />
    ),
    2: (
      <UnlockConnectorForm
        commandType={2}
        closeModal={closeModal}
        cpName={cpName}
        connectorId={connectorId ?? ''}
        isDisabled={!(chargePointName && connectorId)}
      />
    ),
    3: <RebootForm commandType={3} closeModal={closeModal} cpName={cpName} />,
    // 4: (
    //   <SetChargeTimeLimitForm
    //     commandType={4}
    //     closeModal={closeModal}
    //     cpName={cpName}
    //   />
    // ),
    // 5: (
    //   <UpdateSoftwareForm
    //     commandType={5}
    //     closeModal={closeModal}
    //     cpName={cpName}
    //     chargePointId={chargePointId}
    //   />
    // ),
    6: (
      <SendSessionForRepaymentForm
        commandType={6}
        closeModal={closeModal}
        transactionId={transactionId ?? ''}
        isDisabled={!transactionId}
      />
    ),
    7: (
      <ForceSessionStatusPaidForm
        commandType={7}
        closeModal={closeModal}
        transactionId={transactionId ?? ''}
        isDisabled={!transactionId}
      />
    ),
    8: (
      <ConnectedChangeConfigurationForm
        commandType={8}
        closeModal={closeModal}
        isDisabled={!chargePointName}
        cpName={cpName}
      />
    ),
    // 9: (
    //   <GetDiagnosticsForm
    //     commandType={9}
    //     closeModal={closeModal}
    //     cpName={cpName}
    //     chargePointId={chargePointId}
    //   />
    // ),
  };

  return (
    <ManualCommandFormContainer>
      <InputContainer>
        <InputLabel>
          <RequiredLabel>Команда</RequiredLabel>
        </InputLabel>
        <UiSelect
          value={type}
          onChange={handleTypeChange}
          style={{ width: '100%' }}
          options={Object.entries(MANUAL_COMMAND_TYPE).map(
            ([value, label]) => ({
              value,
              label,
            })
          )}
        />
      </InputContainer>
      {type !== undefined ? COMMAND_TYPE_PARAMETERS_MAPPING[type] : null}
    </ManualCommandFormContainer>
  );
}
