import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { SessionListTable } from 'features/sessions/table';

const pageParamsSchema = z.object({
  groupId: z.string(),
});

export function ChargePointGroupSessionsPage() {
  const { groupId } = useTypedParams(pageParamsSchema);

  // TODO: фильтр добавят на бэке позже
  return <SessionListTable groupId={groupId} />;
}
