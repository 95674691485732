import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { BANK, BANKS } from 'shared/consts/routes/bank';
import { ADD_PREFIX } from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';

import { BanksMenu } from 'features/bank/menu';

import { AddBankPage, EditBankPage } from 'pages/bank';

export const BankRoutes = (
  <Route element={<RequireAuth controllerName="Bank" rightType="Write" />}>
    <Route path={BANKS} element={<SectionLayout menu={<BanksMenu />} />}>
      <Route element={<RequireAuth controllerName="Bank" rightType="Write" />}>
        <Route path={ADD_PREFIX} element={<AddBankPage />} />
      </Route>
      <Route path={BANK} element={<EditBankPage />} />
    </Route>
  </Route>
);
