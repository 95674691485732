import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import { openErrorNotification, openSuccessNotification } from 'shared/lib';
import { GroupDto } from 'shared/api/services/user/generated';
import { useDeleteUserGroup } from 'shared/api/services/user/enhanced';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';

type Props = {
  group: GroupDto;
};

export const DeleteUserGroupModal = NiceModal.create(({ group }: Props) => {
  const modal = useModal();

  const [trigger, { isLoading }] = useDeleteUserGroup();

  const handleDelete = async () => {
    try {
      const res = await trigger(group.id!).unwrap();

      res.status === 'SUCCESS'
        ? openSuccessNotification(DELETE_SUCCESS)
        : openErrorNotification(res.statusMessage);
    } catch (e) {
      openErrorNotification(DELETE_ERROR);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={`Удалить группу юзеров "${group.name}"?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={() => handleDelete()}
        loading={isLoading}
      />
    </UiModal>
  );
});
