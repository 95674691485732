import styled from 'styled-components';

import {
  ChargePointStatus,
  CHARGE_POINT_STATUS_COLOR,
} from 'entities/charge-point';
import { GetChargePointVm } from 'shared/api/services/chargepoint/generated';

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  status: GetChargePointVm['status'];
};

export const ChargePointStatusBar = styled.div<Props>`
  width: 5px;
  height: 15px;
  border-radius: 1px;
  display: inline-block;
  margin-right: 8px;
  background-color: ${(props) => CHARGE_POINT_STATUS_COLOR[props.status]};
`;
