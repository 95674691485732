import { Table } from '@tanstack/react-table';

import { PaginationControls } from '../controls';

type Props = {
  table: Table<any>;
};

export function ClientPagination({ table }: Props) {
  const prevDisabled = !table.getCanPreviousPage();
  const nextDisabled = !table.getCanNextPage();

  const onPrevClick = () => table.previousPage();
  const onNextClick = () => table.nextPage();

  const page = table.getState().pagination.pageIndex + 1;
  const pagesCount = table.getPageCount();

  const setPage = (val: number) => table.setPageIndex(val);

  const limit = table.getState().pagination.pageSize;
  const setLimit = (val: number) => table.setPageSize(val);

  // undoced
  const totalCount = table.getRowModel().rows.length;

  return (
    <PaginationControls
      prevDisabled={prevDisabled}
      nextDisabled={nextDisabled}
      onPrevClick={onPrevClick}
      onNextClick={onNextClick}
      page={page}
      setPage={setPage}
      limit={limit}
      setLimit={setLimit}
      totalCount={totalCount}
      pagesCount={pagesCount}
    />
  );
}
