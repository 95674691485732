import { z } from 'zod';

import { nonEmptyString } from 'shared/lib/schema';

export const FormSchema = z.object({
  name: nonEmptyString,
  description: nonEmptyString,
  isDefault: z.boolean(),
});

export type FormSchemaType = z.infer<typeof FormSchema>;

export const DEFAULT_VALUES: FormSchemaType = {
  name: '',
  description: '',
  isDefault: false,
};
