import { z } from 'zod';

import { postApiChargePointGroupsBody } from 'shared/api/services/chargepoint/schemas';

export const FormSchema = postApiChargePointGroupsBody;

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  isDefault: false,
  comment: '',
  parentId: '',
};
