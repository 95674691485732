import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { z } from 'zod';

import { getQueryParams } from './get-query-params';

function useQueryParams() {
  console.log('window loc search', window.location.search);
  return useMemo(() => getQueryParams(), [window.location.search]);
}

export function useTypedQueryParams<T extends z.ZodTypeAny>(
  schema: T
): ReturnType<T['parse']> {
  const queryParams = useQueryParams();

  console.log('useTypedQueryParams', queryParams);

  return useMemo(() => schema.parse(queryParams), [queryParams]);
}

export function useTypedQueryParamsCustom<T extends z.ZodTypeAny>(
  schema: T
): ReturnType<T['parse']> {
  const [searchParams] = useSearchParams();

  // console.log('CUSTOM useTypedQueryParams', searchParams);

  return schema.parse(Object.fromEntries(searchParams.entries()));

  // return useMemo(() => schema.parse(searchParams), [schema.parse(searchParams)]);
}
