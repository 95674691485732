import { enhancedApi as api } from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiPaymentBanks: (endpoint) => {
      endpoint.providesTags = [{ type: 'Banks', id: 'LIST' }];
    },
    getApiPaymentBanksById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Banks', id: arg },
      ];
    },
    postApiPaymentBanks: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Banks', id: 'LIST' }];
    },
    patchApiPaymentBanksById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Banks', id: 'LIST' },
        {
          type: 'Banks',
          id: arg.id,
        },
      ];
    },
    // Банковские карты
    getApiPaymentCards: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'BankCards', id: 'LIST' }];
    },
    // Привязки банковских карт
  },
});

export const {
  useGetApiPaymentBanksQuery: useGetBanks,
  useGetApiPaymentBanksByIdQuery: useGetBank,
  usePostApiPaymentBanksMutation: useAddBank,
  usePatchApiPaymentBanksByIdMutation: useUpdateBank,
  // Банковские карты
  useGetApiPaymentCardsQuery: useGetBankCards,
  useGetApiPaymentCardsCountQuery: useGetBankCardsCount,
} = enhancedApi;
