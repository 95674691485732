import { z } from 'zod';

import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { useTypedParams } from 'shared/lib/router/use-typed-params';
import {
  useGetIdentityGroups,
  useGetIdentityUser,
} from 'shared/api/services/identity/rtk';

import { Form } from './form';

const pageParamsSchema = z.object({
  userName: z.string(),
});

export function FormContainer() {
  const { userName } = useTypedParams(pageParamsSchema);

  const identityGroupsQuery = useGetIdentityGroups();
  const identityUserQuery = useGetIdentityUser(userName);

  const isLoading =
    identityGroupsQuery.isFetching || identityUserQuery.isFetching;

  const apiResponseError = [
    identityGroupsQuery.data,
    identityUserQuery.data,
  ].find((el) => el && el.status === 'Error');

  const error = [identityGroupsQuery.error, identityUserQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!identityGroupsQuery?.data?.groups || !identityUserQuery?.data?.user) {
    return <EmptyData />;
  }

  return (
    <Form
      groups={identityGroupsQuery.data.groups}
      user={identityUserQuery.data.user}
    />
  );
}
