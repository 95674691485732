import { combineReducers } from 'redux';

import chargePointStatusesReducer from 'features/charge-point/statuses-table/store';
import manualCommandsReducer from 'features/manual-command/table/store';

export const tablesReducer = combineReducers({
  chargePointStatuses: chargePointStatusesReducer,
  manualCommands: manualCommandsReducer,
});

export const selectChargePointStatusesTable = (state: RootState) =>
  state.tables.chargePointStatuses;
export const selectManualCommandsTable = (state: RootState) =>
  state.tables.manualCommands;
