import { z } from 'zod';

export const FormSchema = z.object({
  userName: z.string(),
  email: z.string().email(), //
  password: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string(),
  // для кастомной мутации
  groupName: z.string(),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.infer<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  email: '',
  firstName: '',
  lastName: '',
  middleName: '',
  password: '',
  userName: '',
  groupName: '',
};
