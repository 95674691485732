import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';
import {
  useGetChargePointGroups,
  useGetHubs,
  useGetOwners,
} from 'shared/api/services/chargepoint/enhanced';

import { Form } from './form';

export function FormContainer() {
  const {
    data: owners,
    isFetching: isOwnersLoading,
    error: ownersError,
  } = useGetOwners({});

  const {
    data: hubs,
    isFetching: isHubsLoading,
    error: hubsError,
  } = useGetHubs({});

  const {
    data: chargePointGroups,
    isFetching: isChargePointGroupsLoading,
    error: chargePointGroupsError,
    currentData,
  } = useGetChargePointGroups({});

  const isLoading =
    isOwnersLoading || isHubsLoading || isChargePointGroupsLoading;

  const error = [ownersError, hubsError, chargePointGroupsError].find(
    (err) => err !== undefined
  );

  const apiResponseError = [owners, hubs, chargePointGroups].find(
    (el) => el && el.status === 'ERROR'
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (!hubs?.data || !owners?.data || !chargePointGroups?.data) {
    return <EmptyData />;
  }

  return (
    <Form
      groups={chargePointGroups.data}
      hubs={hubs.data}
      owners={owners.data}
    />
  );
}
