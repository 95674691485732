import { enhancedApi as api } from '../generated/mobile-apps';

const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiInformationMobileApps: (endpoint) => {
      endpoint.providesTags = [{ type: 'MobileApps', id: 'LIST' }];
    },
    getApiInformationMobileAppsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'MobileApps', id: arg },
      ];
    },
    postApiInformationMobileApps: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'MobileApps', id: 'LIST' }];
    },
    patchApiInformationMobileAppsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'MobileApps', id: 'LIST' },
        {
          type: 'MobileApps',
          id: arg.id,
        },
      ];
    },
  },
});

export const {
  useGetApiInformationMobileAppsQuery: useGetMobileApps,
  useGetApiInformationMobileAppsByIdQuery: useGetMobileApp,
  usePostApiInformationMobileAppsMutation: useAddMobileApp,
  usePatchApiInformationMobileAppsByIdMutation: useUpdateMobileApp,
} = enhancedApi;
