import { MainLayout } from 'shared/ui/layout/main';

import { ChargePointsGroupFetchingContextProvider } from '../../group-context';
import { TabMenu } from '../group-tab-menu';

export function ChargePointsGroupLayout() {
  return (
    <ChargePointsGroupFetchingContextProvider>
      <MainLayout tabs={<TabMenu />} />
    </ChargePointsGroupFetchingContextProvider>
  );
}
