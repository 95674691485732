import { useGetUserGroups } from 'shared/api/services/user/enhanced';
import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { TableInstance } from './table';

export function TableContainer() {
  const { data, isLoading, error, isFetching, currentData } =
    useGetUserGroups();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data && !currentData) {
    return <EmptyData />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  return <TableInstance data={data?.data || []} loading={isFetching} />;
}
