import { useNavigate } from 'react-router-dom';
import useWindowFocus from 'use-window-focus';

import { ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { POLLING_INTERVAL } from 'shared/consts';
import { useGetChargePoint } from 'shared/api/services/chargepoint/enhanced';

import ChargePointInfo from '../ChargePointInfo';
import { SpinnerContent } from '../ChargePointInfo/styles';

type Props = {
  chargePointId: string;
};

export function ConnectedChargePointInfo({ chargePointId }: Props) {
  const isWindowFocused = useWindowFocus();

  const navigate = useNavigate();

  const { isFetching, error, data, currentData, isSuccess } = useGetChargePoint(
    chargePointId,
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  //

  if (isFetching && !currentData) {
    return (
      <div>Загрузка данных...</div>
      // <Spin spinning={true}>
      //   <SpinnerContent>Загрузка данных...</SpinnerContent>
      // </Spin>
    );
  }

  if (data && data.status === 'ERROR') {
    return <ErrorMessage text={data.statusMessage} />;
  }

  // TODO:
  if (!isSuccess) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return <ChargePointInfo chargePoint={data.data} />;
}
