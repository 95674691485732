import { z } from 'zod';

import {
  useGetChargePointGroups,
  useGetChargePointGroupsCount,
} from 'shared/api/services/chargepoint/enhanced';
import { getServerErrorText } from 'shared/lib';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { TableInstance } from './table';

const pageQueryParamsSchema = z.object({
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
});

export function TableContainer() {
  const { limit, page } = useTypedQueryParamsCustom(pageQueryParamsSchema);

  const {
    data: groups,
    isLoading: isGroupsLoading,
    error: groupsError,
    isFetching,
    currentData,
  } = useGetChargePointGroups({
    limit,
    offset: Math.max(page - 1, 0) * limit,
  });

  const {
    data: groupsCount,
    error: groupsCountError,
    isLoading: isGroupsCountLoading,
  } = useGetChargePointGroupsCount();

  const isLoading = isGroupsLoading || isGroupsCountLoading;

  const apiResponseError = [groups, groupsCount].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [groupsError, groupsCountError].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!groups?.data || !groupsCount || groupsCount.data === undefined) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      data={groups?.data || []}
      loading={isFetching}
      totalCount={groupsCount?.data}
    />
  );
}
