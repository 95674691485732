import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { ROLES, USER, USERS } from 'shared/consts/routes/user';
import {
  ADD_PREFIX,
  CHARGE_POINTS_RIGHTS,
  CONTROLLER_CLAIMS,
} from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';
import { IdentityUserLayout } from 'entities/identity-user/ui/user-layout';
import { IdentityUsersLayout } from 'entities/identity-user/ui/users-layout';

import { IdentityUsersMenu } from 'features/identity-user/menu';
// TODO
// import { ConnectedUserChargePointRights } from '../../../components/Security/User/pages/ConnectedUserChargePointRights';
// import { ConnectedUserControllerRights } from '../../../components/Security/User/pages/ConnectedUserControllerRights';
// import { ConnectedUserRoles } from '../../../components/Security/User/pages/ConnectedUserRoles';
//

import { AddIdentityUserPage } from 'pages/identity-user/add';
import { EditIdentityUserPage } from 'pages/identity-user/edit';

export const IdentityUserRoutes = (
  <Route element={<RequireAuth controllerName="Identity" rightType="Read" />}>
    <Route
      path={USERS}
      element={<SectionLayout menu={<IdentityUsersMenu />} />}
    >
      <Route element={<IdentityUsersLayout />}>
        <Route index element={<div>user list table</div>} />
        {/* <Route index element={<ConnectedUserListTable />} /> */}
      </Route>
      <Route
        element={<RequireAuth controllerName="Identity" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddIdentityUserPage />} />}
        />
      </Route>
      <Route path={USER} element={<IdentityUserLayout />}>
        <Route index element={<EditIdentityUserPage />} />
        {/* <Route
          path={CONTROLLER_CLAIMS}
          element={<ConnectedUserControllerRights />}
        /> */}
        {/* <Route
          path={CHARGE_POINTS_RIGHTS}
          element={<ConnectedUserChargePointRights />}
        />
        <Route path={ROLES} element={<ConnectedUserRoles />} /> */}
      </Route>
    </Route>
  </Route>
);
