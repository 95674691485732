import { StyledResult } from './styles';

type Props = {
  text: string | null | undefined;
};

const UNKNOWN_ERROR = 'Нет текста ошибки';

export function ErrorMessage({ text }: Props) {
  return (
    <StyledResult
      status="error"
      title="Ошибка"
      subTitle={text || UNKNOWN_ERROR}
    />
  );
}
