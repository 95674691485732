import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetCarBrands } from 'shared/api/services/information/rtk';

import { Form } from './form';

export function FormContainer() {
  const {
    isFetching: isCarBrandsFetching,
    error: carBrandsError,
    data: carBrands,
  } = useGetCarBrands();

  const isLoading = isCarBrandsFetching;

  const apiResponseError = [carBrands].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [carBrandsError].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!carBrands?.data) {
    return <EmptyData />;
  }

  return <Form carBrands={carBrands.data} />;
}
