import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { Form } from './form';
import { useGetCarBrand } from 'shared/api/services/information/rtk';

const pageParamsSchema = z.object({
  carBrandId: z.string(),
});

export function FormContainer() {
  const { carBrandId } = useTypedParams(pageParamsSchema);

  const carBrandQuery = useGetCarBrand(carBrandId);

  const isLoading = carBrandQuery.isFetching;

  const apiResponseError = [carBrandQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [carBrandQuery.error].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!carBrandQuery?.data?.data) {
    return <EmptyData />;
  }

  return <Form carBrand={carBrandQuery.data.data} />;
}
