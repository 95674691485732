import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  DateTimeRangePickerFilter,
  InputFilter,
  SelectFilter,
} from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { GetSessionVm } from 'shared/api/services/chargepoint/generated';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import {
  selectTableColumns,
  setTableColumns,
} from 'shared/redux/slices/sessions/slice';

import { SESSIONS_STATUS } from 'entities/session/consts';
import { useAppDispatch } from 'shared/redux/types';

const columnHelper = createColumnHelper<GetSessionVm>();

const DATA_COLUMNS = [
  columnHelper.accessor('createdDate', {
    id: 'createdDate',
    header: 'Время получения',
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="dateCreateFrom"
          dateToName="dateCreateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('closedDate', {
    id: 'closedDate',
    header: 'Время завершения',
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="endDateFrom"
          dateToName="endDateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('lastUpdate', {
    id: 'lastUpdate',
    header: 'Дата обновления',
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="dateFrom"
          dateToName="dateTo"
        />
      ),
    },
  }),
  // TODO: цена
  //
  columnHelper.accessor('energy', {
    id: 'energy',
    header: 'Потребление, кВт*ч',
    meta: {
      filterElement: <InputFilter paramName="energy" disabled />,
    },
  }),
  columnHelper.accessor('instantPower', {
    id: 'instantPower',
    header: 'Текущая мощность, кВт',
    meta: {
      filterElement: <InputFilter paramName="instantPower" disabled />,
    },
  }),
  columnHelper.accessor('instantCurrent', {
    id: 'instantCurrent',
    header: 'Текущий ток (А)',
    meta: {
      filterElement: <InputFilter paramName="instantCurrent" disabled />,
    },
  }),
  columnHelper.accessor('startChargingPercentage', {
    id: 'startChargingPercentage',
    header: '% старта',
    size: 120,
    minSize: 120,
    maxSize: 120,
    meta: {
      filterElement: (
        <InputFilter paramName="startChargingPercentage" disabled />
      ),
    },
  }),
  columnHelper.accessor('chargingPercentage', {
    id: 'chargingPercentage',
    header: '% заряда',
    size: 120,
    minSize: 120,
    maxSize: 120,
    meta: {
      filterElement: <InputFilter paramName="chargingPercentage" disabled />,
    },
  }),
  columnHelper.accessor('maxPower', {
    id: 'maxPower',
    header: 'Максимальная мощность, кВт',
    size: 120,
    minSize: 120,
    maxSize: 120,
    meta: {
      filterElement: <InputFilter paramName="maxPower" disabled />,
    },
  }),
  columnHelper.accessor('maxCurrent', {
    id: 'maxCurrent',
    header: 'Максимальный ток',
    size: 120,
    minSize: 120,
    maxSize: 120,
    meta: {
      filterElement: <InputFilter paramName="maxCurrent" disabled />,
    },
  }),
  columnHelper.accessor('stopReason', {
    id: 'stopReason',
    header: 'Причина завершения',
    size: 120,
    minSize: 120,
    maxSize: 120,
    meta: {
      filterElement: <InputFilter paramName="stopReason" disabled />,
    },
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Статус',
    size: 120,
    minSize: 120,
    maxSize: 120,
    cell: (props) => {
      const val = props.getValue();

      if (val) {
        return SESSIONS_STATUS[val];
      }
    },
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            ...Object.entries(SESSIONS_STATUS).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            }),
          ]}
          paramName="status"
        />
      ),
    },
  }),
  // TODO: коннектор-поля

  // columnHelper.accessor('chargePointId', {
  //   id: 'chargePointId',
  //   header: 'ID ЭЗС',
  //   size: 120,
  //   minSize: 120,
  //   maxSize: 120,
  //   cell: (props) => {
  //     const val = props.getValue();

  //     if (!val) return;

  //     // Не хватает groupId
  //     // return (
  //     //   <Link
  //     //     to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${
  //     //       chargePointGroupId !== 0 ? chargePointGroupId : null
  //     //     }/${cpName}`}
  //     //   >
  //     //     {cpName}
  //     //   </Link>
  //     // );
  //   },
  //   meta: {
  //     filterElement: (
  //       <InputFilter column={{ id: 'dateCreate', header: 'Дата создания' }} />
  //     ),
  //   },
  // }),

  columnHelper.accessor('userId', {
    id: 'userId',
    header: 'Юзер',
    size: 120,
    minSize: 120,
    maxSize: 120,
    meta: {
      filterElement: <InputFilter paramName="userId" disabled />,
    },
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
