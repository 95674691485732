import { z } from 'zod';

import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import {
  useGetBankCards,
  useGetBankCardsCount,
} from 'shared/api/services/payment/enhanced';

import { TableInstance } from './table';

const queryParamsSchema = z.object({
  bankId: z.string().optional(),
  bindingId: z.string().optional(),
  isSelected: z.enum(['true', 'false']).optional(),
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
  orderingField: z.string().optional().catch(undefined),
  orderingType: z.enum(['ASC', 'DESC']).optional(),
});

const countQueryParamsSchema = z.object({
  bankId: z.string().optional(),
  bindingId: z.string().optional(),
  isSelected: z.enum(['true', 'false']).optional(),
});

type Props = {
  userId?: string;
};

export function TableContainer({ userId }: Props) {
  const cardsQueryParams = useTypedQueryParamsCustom(queryParamsSchema);
  const cardsCountQueryParams = useTypedQueryParamsCustom(
    countQueryParamsSchema
  );

  const getIsSelectedParam = () => {
    if (cardsQueryParams.isSelected) {
      return cardsQueryParams.isSelected === 'true' ? true : false;
    }
  };

  const cards = useGetBankCards({
    ...cardsQueryParams,
    isSelected: getIsSelectedParam(),
    offset: Math.max(cardsQueryParams.page - 1, 0) * cardsQueryParams.limit,
    userId,
  });

  const {
    data: cardsCount,
    error: cardsCountError,
    isFetching: isCardsCountFetching,
    isLoading: isCardsCountLoading,
  } = useGetBankCardsCount({
    ...cardsCountQueryParams,
    isSelected: getIsSelectedParam(),
    userId,
  });

  const isLoading = cards.isLoading || isCardsCountLoading;
  const isFetching = cards.isFetching || isCardsCountFetching;

  const apiResponseError = [cards.data, cardsCount].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [cards.error, cardsCountError].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!cards.data?.data || !cardsCount || cardsCount.data === undefined) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={cards.data?.data || []}
      totalCount={cardsCount.data}
    />
  );
}
