import { useAbility } from 'shared/lib/ability/context';

import { Permission } from 'features/sidebar/types';

import { ButtonLayout } from './layout';

type Props = {
  path: string;
  label?: string;
  permissions?: Permission[];
};

export function AddButton({ path, permissions, label }: Props) {
  const ability = useAbility();

  const allowed = permissions
    ? permissions.every((item) => {
        const { controller, right } = item;

        return ability.can(right, controller);
      })
    : true;

  if (!allowed) {
    return null;
  }

  return <ButtonLayout path={path} label={label} />;
}
