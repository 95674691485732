import { ConnectorVm } from 'shared/api/services/chargepoint/generated';

import { AddConnectorButton } from 'features/connector/add';

import { ConnectorCard } from './connector-card';
import { Layout } from './layout';

type Props = {
  chargePointId: string;
  connectors: ConnectorVm[];
};

export function ConnectorListWidget({ chargePointId, connectors }: Props) {
  const connectorsContent =
    connectors.length === 0 ? (
      <div>Нет доступных коннекторов</div>
    ) : (
      <>
        {connectors.map((el) => (
          <ConnectorCard chargePointId={chargePointId} connector={el} />
        ))}
      </>
    );

  return (
    <Layout
      addConnectorSlot={<AddConnectorButton chargePointId={chargePointId} />}
      connectorListSlot={connectorsContent}
    />
  );
}
