import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { UploadProps } from 'antd/lib/upload';
import { DevTool } from '@hookform/devtools';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  getExtensionsString,
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomUploadDragger } from 'shared/ui/form/custom-upload-dragger';

import { ACCEPTED_IMAGE_TYPES } from 'entities/car-brand/consts';

import {
  DEFAULT_VALUES,
  FormInput,
  FormOutput,
  FormSchema,
} from '../consts/schema';
import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';

import { CardFormWrapper, FormRow } from '../../../../common-styles';
import { useAddCarBrand } from 'shared/api/services/information/rtk';

export function Form() {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const [trigger, { isLoading }] = useAddCarBrand();

  const handleSubmit = form.handleSubmit(async (data) => {
    console.log('data', data);

    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper title={FORM_TITLE}>
      <FormProvider {...form}>
        <DevTool control={form.control} />
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields />
            <Form.Buttons />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

Form.Fields = function Fields() {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const props: UploadProps<File> = {
    maxCount: 1,
    multiple: false,
    accept: getExtensionsString(ACCEPTED_IMAGE_TYPES),
    beforeUpload: (_file, _list) => {
      return false;
    },
    listType: 'picture',
  };

  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="name"
            label="Наименование"
            errorMessage={errors.name?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomUploadDragger<FormInput>
            {...props}
            name="file"
            label="Файл логотипа"
            errorMessage={errors.file?.message}
            required
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
