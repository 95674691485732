import { z } from 'zod';

export const FormSchema = z.object({
  name: z.string().min(1),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.infer<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
};
