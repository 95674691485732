import { useState } from 'react';
import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomSelect } from 'shared/ui/form/custom-select';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

import { CardFormWrapper, FormRow } from '../../../../common-styles';
import { GetCarBrandVm } from 'shared/api/services/information/rtk/generated/car-brands';
import { GetCarModelVm } from 'shared/api/services/information/rtk/generated/car-models';
import { useUpdateCarModel } from 'shared/api/services/information/rtk';

type Props = {
  carBrands: GetCarBrandVm[];
  carModel: GetCarModelVm;
};

export function Form({ carBrands, carModel }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...carModel,
    },
  });

  const [trigger, { isLoading }] = useUpdateCarModel();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        id: carModel.id,
        updateCarModelRequest: data,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper title={carModel.name}>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields carBrands={carBrands} isDisabled={isDisabled} />
            <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  carBrands: GetCarBrandVm[];
  isDisabled: boolean;
};

Form.Fields = function Fields({ carBrands, isDisabled }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="name"
            label="Наименование"
            errorMessage={errors.name?.message}
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="carBrandId"
            options={carBrands.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Марка авто"
            errorMessage={errors.carBrandId?.message}
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
