export const selectCurrentToken = (state: RootState) => {
  const sessionAccessToken = state.authSession.accessToken;
  const accessToken = state.auth.accessToken;

  if (accessToken) return accessToken;

  if (sessionAccessToken) return sessionAccessToken;

  return 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoidGVzdC1saW1pdGVkIiwiUm9sZXMiOiLQntC_0LXRgNCw0YLQvtGAINCi0J8gKNGA0LXQtNCw0LrRgtC-0YApIiwiUmVhZCI6WyJCYW5rIiwiQ291bnRyeSIsIkh1YiIsIkluc3RydWN0aW9uIiwiUHVzaE5vdGlmaWNhdGlvbiIsIkRpc2NvdW50IiwiQmFuayIsIlBlcnNvbiIsIlByaXZhdGVNZXRob2RzIl0sIiI6WyJDYXIiLCJDcG8iLCJGaXJtd2FyZSIsIklkZW50aXR5IiwiT2NwaUh1YiIsIlNldHRpbmdzIiwiQ291bnRyeSIsIkNwbyIsIkZpcm13YXJlIiwiR3JvdXAiLCJIdWIiLCJJbnN0cnVjdGlvbiIsIklkZW50aXR5IiwiT2NwaUh1YnMiLCJQdXNoTm90aWZpY2F0aW9uIiwiUmVwb3J0IiwiU2V0dGluZ3MiLCJNYW51YWxDb21tYW5kIiwiRGlzY291bnQiLCJCb251c1NlcnZpY2UiXSwiUmVhZFdyaXRlRXhlY3V0ZSI6WyJDaGFyZ2VQb2ludCIsIkNoYXJnZVRhcmlmZiIsIkNvbm5lY3RvciIsIkdyb3VwIiwiT3duZXIiLCJQZXJzb24iLCJQcml2YXRlTWV0aG9kcyIsIlJlcG9ydCIsIk1hbnVhbENvbW1hbmQiXSwiUmVhZEV4ZWN1dGUiOiJCb251c1NlcnZpY2UiLCJXcml0ZSI6WyJDYXIiLCJDaGFyZ2VUYXJpZmYiLCJDb25uZWN0b3IiLCJPd25lciJdLCJFeGVjdXRlIjpbIkNoYXJnZVBvaW50IiwiTW9iaWxlQXBwIl0sIkdyb3VwcyI6ItCh0LjRgtGA0L7QvdC40LrRgSIsIm5iZiI6MTcyNDg0MDY4MCwiZXhwIjoxODE0ODQwNjgwLCJpYXQiOjE3MjQ4NDA2ODAsImlzcyI6IlBsYXRmb3JtV2ViQXBpIn0.m7HqIAm7D6IJng1bCSkVxIbcR-iLXwkLAcM752kVoeXyXbNefAGf7MUFQ-KkQHJfLXWFO2tUAuwPx5ntajofNA';

  // return '';
};
