import { Button } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

// TODO
export const StyledButton = styled(Button)`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 4px;
  // font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.white};
  height: 40px;
  min-width: 169px;

  &:hover,
  &:focus {
    background-color: ${COLOR.white};
    color: ${COLOR.greenMenu};
    border-color: ${COLOR.greenMenu};
  }

  &.ant-btn-default {
    background-color: ${COLOR.cancelGrey};
    border-color: ${COLOR.cancelGrey};
    color: ${COLOR.darkGrey};

    &:hover {
      background-color: ${COLOR.white};
      border-color: ${COLOR.greenMenu};
      color: ${COLOR.greenMenu};
    }
  }
`;
