import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { CarModelListTable } from 'features/car-model/table';

const pageParamsSchema = z.object({
  carBrandId: z.string(),
});

export function CarBrandCarModelListPage() {
  const { carBrandId } = useTypedParams(pageParamsSchema);

  return <CarModelListTable carBrandId={carBrandId} />;
}
