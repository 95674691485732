import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { Unauthorized } from 'shared/ui';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
  Controller,
  Right,
} from 'entities/authorization';

type Props = {
  controllerName: Controller;
  rightType: Right;
};

export const RequireAuth = ({ controllerName, rightType }: Props) => {
  // const roles = useSelector(selectTokenRoles);
  // const claims = useSelector(selectTokenClaims);

  // const allowed = checkControllerRight(
  //   controllerName,
  //   rightType,
  //   roles,
  //   claims
  // );

  // return allowed ? <Outlet /> : <Unauthorized />;

  return <Outlet />;
};

type UpdatedProps = {
  element: JSX.Element;
  controllerName: Controller;
  rightType: Right;
};

export const UpdatedRequireAuth = ({
  controllerName,
  rightType,
  element,
}: UpdatedProps) => {
  // const roles = useSelector(selectTokenRoles);
  // const claims = useSelector(selectTokenClaims);

  // const allowed = checkControllerRight(
  //   controllerName,
  //   rightType,
  //   roles,
  //   claims
  // );

  // return allowed ? element : <Unauthorized />;

  return <Outlet />;
};
