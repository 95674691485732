import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const DEFAULT_TABLE_PAGE_SIZE = 20;

type State = {
  tableColumns: {
    id: boolean;
    name: boolean;
    legalName: boolean;
    description: boolean;
    phoneNumber: boolean;
    email: boolean;
    kpp: boolean;
  };
  tableLimit: number;
};

const initialState: State = {
  tableColumns: {
    id: true,
    name: true,
    legalName: true,
    description: true,
    phoneNumber: true,
    email: true,
    kpp: true,
  },
  tableLimit: DEFAULT_TABLE_PAGE_SIZE,
};

const slice = createSlice({
  name: 'ownersSlice',
  initialState,
  reducers: {
    setTableColumns: (
      state,
      { payload }: PayloadAction<State['tableColumns']>
    ) => {
      state.tableColumns = payload;
    },
    setTableLimit: (state, { payload }: PayloadAction<number>) => {
      state.tableLimit = payload;
    },
  },
});

export const { setTableColumns, setTableLimit } = slice.actions;

export const selectTableColumns = (state: RootState) =>
  state.owners.tableColumns;
export const selectTableLimit = (state: RootState) => state.owners.tableLimit;

export default slice.reducer;
