import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { EventListTable } from 'features/events/table';

const pageParamsSchema = z.object({
  chargePointId: z.string(),
  groupId: z.string(),
});

export function ChargePointEventsPage() {
  const params = useTypedParams(pageParamsSchema);

  return <EventListTable chargePointId={params.chargePointId} />;
}
