import dayjs from 'dayjs';
import { z } from 'zod';

import { patchApiUserUsersUserIdBody } from 'shared/api/services/user/schemas';
import { FULL_DATE_PATTERN } from 'shared/consts/regex';

export const FormSchema = patchApiUserUsersUserIdBody
  .omit({
    registrationDate: true,
    lockoutEndDate: true,
  })
  .extend({
    registrationDate: z
      .string()
      .regex(FULL_DATE_PATTERN, {
        message: 'Формат даты: DD-MM-YYYY HH:mm:ss',
      })
      .transform((val) => dayjs(val, 'DD-MM-YYYY HH:mm:ss').toISOString()),
  });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
