import {
  getCoreRowModel,
  PaginationState,
  // SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { GetOwnerVm } from 'shared/api/services/chargepoint/generated';
import {
  ServerTableLayout,
  useQueryParamsPagination,
} from 'shared/ui/table-new';

import { useColumns } from './use-columns';

type Props = {
  data: GetOwnerVm[];
  loading: boolean;
  totalCount: number;
};

export function TableInstance({ data, loading, totalCount }: Props) {
  const columns = useColumns();

  const { limit, page } = useQueryParamsPagination();
  // const { orderingField, orderingType } = useQueryParamsSorting();

  const pagination: PaginationState = {
    pageIndex: page,
    pageSize: limit,
  };

  // const sorting: SortingState = [
  //   {
  //     id: orderingField,
  //     desc: orderingType === 'DESC',
  //   },
  // ];

  const table = useReactTable({
    data,
    columns,
    state: { pagination },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalCount / limit),
  });

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      totalCount={totalCount}
    />
  );
}
