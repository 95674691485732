import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInformationMobileAppsById: build.query<
      GetApiInformationMobileAppsByIdApiResponse,
      GetApiInformationMobileAppsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Information/MobileApps/${queryArg}` }),
    }),
    patchApiInformationMobileAppsById: build.mutation<
      PatchApiInformationMobileAppsByIdApiResponse,
      PatchApiInformationMobileAppsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/MobileApps/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateMobileAppRequest,
      }),
    }),
    deleteApiInformationMobileAppsById: build.mutation<
      DeleteApiInformationMobileAppsByIdApiResponse,
      DeleteApiInformationMobileAppsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/MobileApps/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiInformationMobileApps: build.query<
      GetApiInformationMobileAppsApiResponse,
      GetApiInformationMobileAppsApiArg
    >({
      query: () => ({ url: `/Api/Information/MobileApps` }),
    }),
    postApiInformationMobileApps: build.mutation<
      PostApiInformationMobileAppsApiResponse,
      PostApiInformationMobileAppsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/MobileApps`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiInformationMobileAppsByIdApiResponse =
  /** status 200 Success */ MobileAppVmBaseResponse;
export type GetApiInformationMobileAppsByIdApiArg = /** Идентификатор */ string;
export type PatchApiInformationMobileAppsByIdApiResponse =
  /** status 200 Success */ UpdateMobileAppVmBaseResponse;
export type PatchApiInformationMobileAppsByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Модель обновления */
  updateMobileAppRequest: UpdateMobileAppRequest;
};
export type DeleteApiInformationMobileAppsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiInformationMobileAppsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiInformationMobileAppsApiResponse =
  /** status 200 Success */ MobileAppVmIReadOnlyCollectionBaseResponse;
export type GetApiInformationMobileAppsApiArg = void;
export type PostApiInformationMobileAppsApiResponse =
  /** status 200 Success */ AddMobileAppVmBaseResponse;
export type PostApiInformationMobileAppsApiArg =
  /** Модель мобильного приложения */ AddMobileAppRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type MobileAppVm = {
  id: string;
  name: string;
  description?: string | null;
  isDefault: boolean;
};
export type MobileAppVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: MobileAppVm;
};
export type UpdateMobileAppVm = {
  id: string;
  name: string;
  description?: string | null;
  isDefault: boolean;
};
export type UpdateMobileAppVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateMobileAppVm;
};
export type UpdateMobileAppRequest = {
  name?: string | null;
  description?: string | null;
  isDefault?: boolean | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type MobileAppVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: MobileAppVm[] | null;
};
export type AddMobileAppVm = {
  id: string;
  name: string;
  description?: string | null;
  isDefault: boolean;
};
export type AddMobileAppVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddMobileAppVm;
};
export type AddMobileAppRequest = {
  name: string;
  description: string;
  isDefault: boolean;
};
export const {
  useGetApiInformationMobileAppsByIdQuery,
  usePatchApiInformationMobileAppsByIdMutation,
  useDeleteApiInformationMobileAppsByIdMutation,
  useGetApiInformationMobileAppsQuery,
  usePostApiInformationMobileAppsMutation,
} = injectedRtkApi;
