import { Map, YMaps, withYMaps } from '@pbe/react-yandex-maps';

import { GetChargePointVm } from 'shared/api/services/chargepoint/generated';

import './styles.css';

import { MapContainer } from './styles';

import { Pin } from '../pin';

export type MapProps = React.ComponentProps<typeof Map> & {
  // Undocumented feature
  onBoundsChange?: (e: any) => void;
};

type Props = {
  chargePoints: GetChargePointVm[];
  loading?: boolean;
  mapProps: MapProps;
  onPinClick?: (chargePoint: GetChargePointVm) => void;
};

export function ChargePointsMap({
  chargePoints,
  loading = false,
  mapProps,
  onPinClick,
}: Props) {
  const CreatedMap = ({ ymaps }) => {
    const factory = ymaps.templateLayoutFactory;

    return (
      <Map
        {...mapProps}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {chargePoints
          .filter((chargePoint) => chargePoint.isVisible)
          .map((chargePoint) => (
            <Pin
              chargePoint={chargePoint}
              factory={factory}
              key={chargePoint.id}
              onPinClick={
                onPinClick ? () => onPinClick(chargePoint) : undefined
              }
            />
          ))}
      </Map>
    );
  };

  const ConnectedCreatedMap = withYMaps(CreatedMap, true, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <MapContainer>
      <YMaps
        query={{
          lang: 'ru_RU',
          load: 'templateLayoutFactory,control.ZoomControl,control.GeolocationControl,overlay.storage,GeoObject',
        }}
      >
        <ConnectedCreatedMap />
      </YMaps>
    </MapContainer>
  );
}
