import { TabsMenu, StyledButton, TabPanelsContainer } from './styles';

const items = [
  {
    label: 'Техническая информация',
    key: 0,
  },
  { label: 'Настройка карточки ЭЗС', key: 1 },
  { label: 'OCPP конфиг', key: 2 },
];

type Props = {
  activeKey: number;
  onClick: (step: number) => void;
  disabledTabs: number[];
};

export function ChargePointFormTabs({
  onClick,
  activeKey,
  disabledTabs,
}: Props) {
  return (
    <TabPanelsContainer>
      <TabsMenu>
        {items.map(({ key, label }) => (
          <StyledButton
            active={key === activeKey}
            key={key}
            onClick={() => onClick(key)}
            disabled={disabledTabs.includes(key)}
          >
            {label}
          </StyledButton>
        ))}
      </TabsMenu>
    </TabPanelsContainer>
  );
}
