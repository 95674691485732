import { useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';

import { UiPopover } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';

import {
  BaseInfoManualCommandType,
  BASE_INFO_MANUAL_COMMAND_TYPE,
} from 'entities/manual-command';

import { ManualCommandModal } from 'features/manual-command';

// TODO
import { PopoverButtonsContainer } from '../../../../common-styles';

type Props = {
  chargePointId: string;
};

export function Actions({ chargePointId }: Props) {
  const ability = useAbility();

  const navigate = useNavigate();

  const showManualCommandModal = (type: BaseInfoManualCommandType) => {
    NiceModal.show(ManualCommandModal, {
      commandType: type,
      chargePointName: chargePointId, // TODO
      chargePointId,
    });
  };

  const manualCommands = (
    Object.keys(BASE_INFO_MANUAL_COMMAND_TYPE) as unknown as Array<
      keyof typeof BASE_INFO_MANUAL_COMMAND_TYPE
    >
  ).map((type) => {
    return (
      <button
        key={BASE_INFO_MANUAL_COMMAND_TYPE[type]}
        onClick={() => {
          showManualCommandModal(type);
        }}
      >
        {BASE_INFO_MANUAL_COMMAND_TYPE[type]}
      </button>
    );
  });

  const chargePointMutations = (
    <button
      onClick={() => {
        navigate('edit');
      }}
    >
      Редактировать
    </button>
  );

  const content = (
    <>
      {ability.can('Execute', 'PrivateMethods') ? (
        <PopoverButtonsContainer>{manualCommands}</PopoverButtonsContainer>
      ) : null}
      {ability.can('Write', 'ChargePoint') ? (
        <PopoverButtonsContainer>
          {chargePointMutations}
        </PopoverButtonsContainer>
      ) : null}
    </>
  );

  if (
    !ability.can('Execute', 'PrivateMethods') &&
    !ability.can('Write', 'ChargePoint')
  ) {
    return null;
  }

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
