import { Link } from 'react-router-dom';

import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';

import { StyledButton, IconContainer } from './styles';

const DEFAULT_LABEL = 'добавить';

type Props = {
  path: string;
  label?: string;
};

export function ButtonLayout({ path, label = DEFAULT_LABEL }: Props) {
  return (
    <Link to={path}>
      <StyledButton>
        <span>{label}</span>
        <IconContainer>
          <PlusInCircleIcon />
        </IconContainer>
      </StyledButton>
    </Link>
  );
}
