import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { ChargePointListTable } from 'features/charge-point/table';

const pageParamsSchema = z.object({
  groupId: z.string(),
});

export function ChargePointsGroupListPage() {
  const { groupId } = useTypedParams(pageParamsSchema);

  return <ChargePointListTable groupId={groupId} />;
}
