import NiceModal from '@ebay/nice-modal-react';

import { UiPopover } from 'shared/ui';
import { ConnectorVm } from 'shared/api/services/chargepoint/generated';
import { useAbility } from 'shared/lib/ability/context';

import {
  ConnectorManualCommandType,
  CONNECTOR_MANUAL_COMMAND_TYPE,
} from 'entities/manual-command';

import { ManualCommandModal } from 'features/manual-command';
import { EditConnectorModal } from 'features/connector/edit';
import { DeleteConnectorModal } from 'features/connector/delete';

import { PopoverButtonsContainer } from '../../../../../../common-styles';
import { IconContainer } from './styles';
import { EditIcon } from './icons/edit';

type Props = {
  chargePointId: string;
  connector: ConnectorVm;
};

export function Actions({ chargePointId, connector }: Props) {
  const ability = useAbility();

  // TODO: везде удалить chargePointName, transactionId
  // const showManualCommandModal = (
  //   commandType: ConnectorManualCommandType,
  //   chargePointName: string,
  //   transactionId: string,
  //   connectorId: string
  // ) => {
  //   NiceModal.show(ManualCommandModal, {
  //     commandType,
  //     chargePointName: chargePointId,
  //     transactionId: '',
  //     connectorId,
  //   });
  // };

  const showEditConnectorModal = () => {
    NiceModal.show(EditConnectorModal, {
      chargePointId,
      chargePointName: chargePointId,
      connector,
    });
  };

  const showDeleteConnectorModal = () => {
    NiceModal.show(DeleteConnectorModal, {
      chargePointId,
      connectorId: connector.id,
    });
  };

  // const manualCommands = (
  //   Object.keys(CONNECTOR_MANUAL_COMMAND_TYPE) as unknown as Array<
  //     keyof typeof CONNECTOR_MANUAL_COMMAND_TYPE
  //   >
  // ).map((command) => {
  //   return (
  //     <button
  //       key={command}
  //       onClick={() => {
  //         showManualCommandModal(
  //           command,
  //           chargePointId,
  //           '', // TODO: удалить transactionId бывший
  //           String(connector.connectorId)
  //         );
  //       }}
  //     >
  //       {CONNECTOR_MANUAL_COMMAND_TYPE[command]}
  //     </button>
  //   );
  // });

  const connectorMutations = (
    <>
      <button onClick={showEditConnectorModal}>Редактировать</button>
      <button onClick={showDeleteConnectorModal}>Удалить</button>
    </>
  );

  const content = (
    <>
      {/* {ability.can('Execute', 'PrivateMethods') ? (
        <PopoverButtonsContainer>{manualCommands}</PopoverButtonsContainer>
      ) : null} */}
      {ability.can('Write', 'Connector') &&
      ability.can('Read', 'Instruction') ? (
        <PopoverButtonsContainer>{connectorMutations}</PopoverButtonsContainer>
      ) : null}
    </>
  );

  if (
    !ability.can('Execute', 'PrivateMethods') &&
    !ability.can('Write', 'Connector')
  ) {
    return null;
  }

  return (
    <UiPopover content={content}>
      <IconContainer>
        <EditIcon />
      </IconContainer>
    </UiPopover>
  );
}
