import { enhancedApi as api } from '../generated/countries';

const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiInformationCountries: (endpoint) => {
      endpoint.providesTags = [{ type: 'Countries', id: 'LIST' }];
    },
    getApiInformationCountriesById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Countries', id: arg },
      ];
    },
    postApiInformationCountries: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Countries', id: 'LIST' }];
    },
    patchApiInformationCountriesById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Countries', id: 'LIST' },
        {
          type: 'Countries',
          id: arg.id,
        },
      ];
    },
  },
});

export const {
  useGetApiInformationCountriesQuery: useGetCountries,
  useGetApiInformationCountriesByIdQuery: useGetCountry,
  usePostApiInformationCountriesMutation: useAddCountry,
  usePatchApiInformationCountriesByIdMutation: useUpdateCountry,
} = enhancedApi;
