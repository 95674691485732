import { useEffect, useState } from 'react';

import { getInputHtmlSize } from 'shared/lib';

import { StyledJumper } from './styles';

type Props = {
  setPage: (e: number) => void;
  page: string;
};

export function Jumper({ setPage, page }: Props) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(page);
  }, [page]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 0 || e.target.value === '') {
      setValue(e.target.value);
    }
  };

  return (
    <StyledJumper
      value={value}
      onChange={handleValueChange}
      onPressEnter={(e) => {
        const val = Number((e.target as HTMLInputElement).value);

        if (val > 0) {
          setPage(val);
        }
      }}
      htmlSize={getInputHtmlSize(value)}
    />
  );
}
