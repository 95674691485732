import { MainLayout } from 'shared/ui/layout/main';

import { PersonFetchingContextProvider } from '../../user-context';
import { TabMenu } from '../user-tab-menu';

export function UserLayout() {
  return (
    <PersonFetchingContextProvider>
      <MainLayout tabs={<TabMenu />} />
    </PersonFetchingContextProvider>
  );
}
