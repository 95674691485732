import { z } from 'zod';

import { OwnerType } from 'shared/api/services/chargepoint/generated';
import { postApiChargePointOwnersBody } from 'shared/api/services/chargepoint/schemas';

import { LEGAL_NAME_MIN_LENGTH } from 'entities/owner';

const ownerType: z.ZodType<OwnerType> = z.enum(['MAIN', 'AGENT', 'PARTNER']);

export const FormSchema = postApiChargePointOwnersBody
  .omit({ type: true })
  .extend({
    type: ownerType.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    legalName: z.string().min(LEGAL_NAME_MIN_LENGTH),
  });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  email: '',
  type: undefined,
  phoneNumber: '',
  description: '',
  legalName: '',
  inn: '',
  directorName: '',
  kpp: '',
  telegram: '',
};
