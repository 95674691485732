import styled from 'styled-components';

export const IconContainer = styled.div`
  cursor: pointer;
  opacity: 0.5;
  height: 18px;

  &:hover {
    opacity: 1;
    transition: 300ms;
  }
`;
