import { SectionMenuAddButton } from 'shared/ui/section-menu';
import { CAR_BRAND_ROUTES } from 'shared/consts/routes/car-brands';

export function AddButton() {
  return (
    <SectionMenuAddButton
      path={CAR_BRAND_ROUTES.ADD_CAR_BRAND}
      permissions={[
        {
          controller: 'Car',
          right: 'Write',
        },
      ]}
    />
  );
}
