import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const DEFAULT_TABLE_PAGE_SIZE = 20;

type State = {
  tableColumns: {
    id: boolean;
    name: boolean;
    comment: boolean;
  };
  tableLimit: number;
};

const initialState: State = {
  tableColumns: {
    comment: true,
    id: true,
    name: true,
  },
  tableLimit: DEFAULT_TABLE_PAGE_SIZE,
};

const slice = createSlice({
  name: 'userGroupsSlice',
  initialState,
  reducers: {
    setTableColumns: (
      state,
      { payload }: PayloadAction<State['tableColumns']>
    ) => {
      state.tableColumns = payload;
    },
    setTableLimit: (state, { payload }: PayloadAction<number>) => {
      state.tableLimit = payload;
    },
  },
});

export const { setTableColumns, setTableLimit } = slice.actions;

export const selectUserGroupsTableColumns = (state: RootState) =>
  state.userGroups.tableColumns;
export const selectUserGroupsTableLimit = (state: RootState) =>
  state.userGroups.tableLimit;

export default slice.reducer;
