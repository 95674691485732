import {
  MonitoringGridLayout,
  MetricsChartsWrapper,
  MonitoringContentWrapper,
  DashboardsContainer,
  MonitoringFiltersContainer,
} from './styles';
import ConnectedMonitoringMetrics from '../../components/MonitoringMetrics/components/UpdatedConnectedMonitoringMetrics';

import { MonitoringChargePointsMap } from '../../components/MonitoringChargePointsMap';

// import { MonitoringDashboards } from '../../components/MetricsCard/MonitoringDashboards';

import { ShorthandedFilters } from 'widgets/charge-point/filters';
import { selectShowCharts } from 'pages/monitoring/store';
import { useAppSelector } from 'shared/redux/types';

export function MonitoringPage() {
  const showCharts = useAppSelector(selectShowCharts);

  return (
    <MonitoringContentWrapper>
      <MonitoringFiltersContainer>
        <ShorthandedFilters />
      </MonitoringFiltersContainer>
      <MonitoringGridLayout>
        <DashboardsContainer>
          {/* <MonitoringDashboards /> */}
        </DashboardsContainer>
        {showCharts ? (
          <MetricsChartsWrapper>
            <ConnectedMonitoringMetrics type="0" />
            <ConnectedMonitoringMetrics type="1" />
            <ConnectedMonitoringMetrics type="2" />
          </MetricsChartsWrapper>
        ) : null}
        <MonitoringChargePointsMap isFullWidth={!showCharts} />
      </MonitoringGridLayout>
    </MonitoringContentWrapper>
  );
}
