export function GroupsIcon() {
  return (
    <svg viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="6.75"
        y="0.75"
        width="14.5"
        height="14.5"
        rx="4.25"
        fill="white"
        stroke="#28303F"
        strokeWidth="1.5"
      />
      <ellipse
        cx="14"
        cy="10.25"
        rx="3"
        ry="1.25"
        stroke="#28303F"
        strokeLinejoin="round"
      />
      <circle cx="14" cy="6" r="1.5" stroke="#28303F" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4856 8.5C10.7533 8.50153 10.068 8.62004 9.54654 8.82864C9.28547 8.93307 9.04064 9.06992 8.85291 9.24746C8.66412 9.426 8.5 9.68013 8.5 9.99998C8.5 10.3198 8.66412 10.574 8.85291 10.7525C9.04064 10.9301 9.28547 11.0669 9.54654 11.1713C9.85087 11.2931 10.211 11.3841 10.6037 11.4391C10.2281 11.1005 10.0082 10.7015 10.0002 10.274C9.97194 10.2639 9.94449 10.2535 9.91793 10.2429C9.72659 10.1663 9.60529 10.0877 9.54002 10.026C9.52935 10.0159 9.52113 10.0072 9.51482 9.99998C9.52113 9.99276 9.52935 9.9841 9.54002 9.97402C9.60529 9.91228 9.72659 9.83365 9.91793 9.75712C9.98281 9.73116 10.0529 9.70658 10.1278 9.68364C10.3457 9.21079 10.8302 8.79833 11.4856 8.5ZM9.49554 10.0273C9.49546 10.0273 9.49576 10.0264 9.49667 10.0247C9.49608 10.0264 9.49562 10.0273 9.49554 10.0273ZM9.49667 9.97531C9.49576 9.97358 9.49546 9.97271 9.49554 9.97271C9.49562 9.97271 9.49608 9.97357 9.49667 9.97531Z"
        fill="#28303F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9998 10.274C17.9918 10.7015 17.7719 11.1005 17.3964 11.4391C17.789 11.3841 18.1491 11.2931 18.4535 11.1713C18.7145 11.0669 18.9594 10.9301 19.1471 10.7525C19.3359 10.574 19.5 10.3198 19.5 9.99998C19.5 9.68013 19.3359 9.426 19.1471 9.24746C18.9594 9.06992 18.7145 8.93307 18.4535 8.82864C17.932 8.62004 17.2467 8.50153 16.5144 8.5C17.1698 8.79833 17.6543 9.21079 17.8722 9.68364C17.9471 9.70658 18.0172 9.73116 18.0821 9.75712C18.2734 9.83365 18.3947 9.91228 18.46 9.97402C18.4707 9.9841 18.4789 9.99276 18.4852 9.99998C18.4789 10.0072 18.4707 10.0159 18.46 10.026C18.3947 10.0877 18.2734 10.1663 18.0821 10.2429C18.0555 10.2535 18.0281 10.2639 17.9998 10.274ZM18.5045 10.0273C18.5044 10.0273 18.5039 10.0264 18.5033 10.0247C18.5043 10.0264 18.5046 10.0273 18.5045 10.0273ZM18.5033 9.97531C18.5039 9.97357 18.5044 9.97271 18.5045 9.97271C18.5046 9.97271 18.5043 9.97358 18.5033 9.97531Z"
        fill="#28303F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 6C16.7761 6 17 6.22386 17 6.5C17 6.77614 16.7761 7 16.5 7C16.4321 7 16.3673 6.98645 16.3082 6.96191C16.1767 7.27721 15.9824 7.5598 15.741 7.7941C15.9637 7.92496 16.2231 8 16.5 8C17.3284 8 18 7.32843 18 6.5C18 5.67157 17.3284 5 16.5 5C16.4314 5 16.364 5.0046 16.2978 5.01351C16.4279 5.31617 16.5 5.64967 16.5 6Z"
        fill="#28303F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.259 7.7941C12.0176 7.5598 11.8233 7.27721 11.6918 6.96191C11.6327 6.98645 11.5679 7 11.5 7C11.2239 7 11 6.77614 11 6.5C11 6.22386 11.2239 6 11.5 6C11.5 5.64967 11.5721 5.31617 11.7022 5.01351C11.636 5.0046 11.5686 5 11.5 5C10.6716 5 10 5.67157 10 6.5C10 7.32843 10.6716 8 11.5 8C11.7769 8 12.0363 7.92496 12.259 7.7941Z"
        fill="#28303F"
      />
      <rect
        x="0.75"
        y="7.75"
        width="14.5"
        height="14.5"
        rx="4.25"
        fill="white"
        stroke="#28303F"
        strokeWidth="1.5"
      />
      <ellipse
        cx="8"
        cy="17.25"
        rx="3"
        ry="1.25"
        stroke="#28303F"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="13" r="1.5" stroke="#28303F" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4856 15.5C4.75333 15.5015 4.06803 15.62 3.54654 15.8286C3.28547 15.9331 3.04064 16.0699 2.85291 16.2475C2.66412 16.426 2.5 16.6801 2.5 17C2.5 17.3198 2.66412 17.574 2.85291 17.7525C3.04064 17.9301 3.28547 18.0669 3.54654 18.1713C3.85087 18.2931 4.21098 18.3841 4.60366 18.4391C4.2281 18.1005 4.00818 17.7015 4.00022 17.274C3.97194 17.2639 3.94449 17.2535 3.91793 17.2429C3.72659 17.1663 3.60529 17.0877 3.54002 17.026C3.52935 17.0159 3.52113 17.0072 3.51482 17C3.52113 16.9928 3.52935 16.9841 3.54002 16.974C3.60529 16.9123 3.72659 16.8337 3.91793 16.7571C3.98281 16.7312 4.05292 16.7066 4.12778 16.6836C4.34574 16.2108 4.83019 15.7983 5.4856 15.5ZM3.49554 17.0273C3.49546 17.0273 3.49576 17.0264 3.49667 17.0247C3.49608 17.0264 3.49562 17.0273 3.49554 17.0273ZM3.49667 16.9753C3.49576 16.9736 3.49546 16.9727 3.49554 16.9727C3.49562 16.9727 3.49608 16.9736 3.49667 16.9753Z"
        fill="#28303F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 17.274C11.9918 17.7015 11.7719 18.1005 11.3964 18.4391C11.789 18.3841 12.1491 18.2931 12.4535 18.1713C12.7145 18.0669 12.9594 17.9301 13.1471 17.7525C13.3359 17.574 13.5 17.3198 13.5 17C13.5 16.6801 13.3359 16.426 13.1471 16.2475C12.9594 16.0699 12.7145 15.9331 12.4535 15.8286C11.932 15.62 11.2467 15.5015 10.5144 15.5C11.1698 15.7983 11.6543 16.2108 11.8722 16.6836C11.9471 16.7066 12.0172 16.7312 12.0821 16.7571C12.2734 16.8337 12.3947 16.9123 12.46 16.974C12.4707 16.9841 12.4789 16.9928 12.4852 17C12.4789 17.0072 12.4707 17.0159 12.46 17.026C12.3947 17.0877 12.2734 17.1663 12.0821 17.2429C12.0555 17.2535 12.0281 17.2639 11.9998 17.274ZM12.5045 17.0273C12.5044 17.0273 12.5039 17.0264 12.5033 17.0247C12.5043 17.0264 12.5046 17.0273 12.5045 17.0273ZM12.5033 16.9753C12.5039 16.9736 12.5044 16.9727 12.5045 16.9727C12.5046 16.9727 12.5043 16.9736 12.5033 16.9753Z"
        fill="#28303F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 13C10.7761 13 11 13.2239 11 13.5C11 13.7761 10.7761 14 10.5 14C10.4321 14 10.3673 13.9865 10.3082 13.9619C10.1767 14.2772 9.98244 14.5598 9.74104 14.7941C9.96369 14.925 10.2231 15 10.5 15C11.3284 15 12 14.3284 12 13.5C12 12.6716 11.3284 12 10.5 12C10.4314 12 10.364 12.0046 10.2978 12.0135C10.4279 12.3162 10.5 12.6497 10.5 13Z"
        fill="#28303F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25896 14.7941C6.01757 14.5598 5.8233 14.2772 5.69176 13.9619C5.6327 13.9865 5.56793 14 5.5 14C5.22386 14 5 13.7761 5 13.5C5 13.2239 5.22386 13 5.5 13C5.5 12.6497 5.57206 12.3162 5.70217 12.0135C5.63605 12.0046 5.56856 12 5.5 12C4.67157 12 4 12.6716 4 13.5C4 14.3284 4.67157 15 5.5 15C5.77692 15 6.03632 14.925 6.25896 14.7941Z"
        fill="#28303F"
      />
    </svg>
  );
}
