/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * WebApi
 * OpenAPI spec version: 1.0
 */
import type {
  AddCarBrandRequest,
  AddCarBrandVmBaseResponse,
  AddCarModelRequest,
  AddCarModelVmBaseResponse,
  AddCountryRequest,
  AddCountryVmBaseResponse,
  AddMobileAppRequest,
  AddMobileAppVmBaseResponse,
  AddOrUpdateLogoCarBrandVmBaseResponse,
  AddSupportRequest,
  AddSupportVmBaseResponse,
  BaseResponse,
  DeleteCarBrandLogoVmBaseResponse,
  GetApiInformationCountriesParams,
  GetCarBrandVmBaseResponse,
  GetCarBrandVmIReadOnlyCollectionBaseResponse,
  GetCarModelVmBaseResponse,
  GetCarModelVmIReadOnlyCollectionBaseResponse,
  GetCountryVmBaseResponse,
  GetCountryVmIReadOnlyCollectionBaseResponse,
  GetSupportVmBaseResponse,
  GetSupportVmIReadOnlyCollectionBaseResponse,
  MobileAppVmBaseResponse,
  MobileAppVmIReadOnlyCollectionBaseResponse,
  PutApiInformationCarBrandsLogoIdBody,
  UpdateCarBrandRequest,
  UpdateCarBrandVmBaseResponse,
  UpdateCarModelRequest,
  UpdateCarModelVmBaseResponse,
  UpdateCountryRequest,
  UpdateCountryVmBaseResponse,
  UpdateMobileAppRequest,
  UpdateMobileAppVmBaseResponse,
  UpdateSupportRequest,
  UpdateSupportVmBaseResponse
} from './schemas'
import { customInstance } from '../../../../../axios';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getWebApi = () => {
/**
 * @summary Получить бренд
 */
const getApiInformationCarBrandsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCarBrandVmBaseResponse>(
      {url: `/Api/Information/CarBrands/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Обновить бренд
 */
const patchApiInformationCarBrandsId = (
    id: string,
    updateCarBrandRequest: UpdateCarBrandRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateCarBrandVmBaseResponse>(
      {url: `/Api/Information/CarBrands/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCarBrandRequest
    },
      options);
    }
  
/**
 * @summary Удалить бренд
 */
const deleteApiInformationCarBrandsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BaseResponse>(
      {url: `/Api/Information/CarBrands/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить список брендов
 */
const getApiInformationCarBrands = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCarBrandVmIReadOnlyCollectionBaseResponse>(
      {url: `/Api/Information/CarBrands`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить бренд
 */
const postApiInformationCarBrands = (
    addCarBrandRequest: AddCarBrandRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddCarBrandVmBaseResponse>(
      {url: `/Api/Information/CarBrands`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addCarBrandRequest
    },
      options);
    }
  
/**
 * @summary Добавить или обновить логотип бренда
 */
const putApiInformationCarBrandsLogoId = (
    id: string,
    putApiInformationCarBrandsLogoIdBody: PutApiInformationCarBrandsLogoIdBody,
 options?: SecondParameter<typeof customInstance>,) => {const formData = new FormData();
if(putApiInformationCarBrandsLogoIdBody.file !== undefined) {
 formData.append('file', putApiInformationCarBrandsLogoIdBody.file)
 }

      return customInstance<AddOrUpdateLogoCarBrandVmBaseResponse>(
      {url: `/Api/Information/CarBrands/Logo/${id}`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  
/**
 * @summary Удалить файл логотипа
 */
const deleteApiInformationCarBrandsLogoId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DeleteCarBrandLogoVmBaseResponse>(
      {url: `/Api/Information/CarBrands/Logo/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить модель
 */
const getApiInformationCarModelsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCarModelVmBaseResponse>(
      {url: `/Api/Information/CarModels/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Обновить модель
 */
const patchApiInformationCarModelsId = (
    id: string,
    updateCarModelRequest: UpdateCarModelRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateCarModelVmBaseResponse>(
      {url: `/Api/Information/CarModels/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCarModelRequest
    },
      options);
    }
  
/**
 * @summary Удалить модель
 */
const deleteApiInformationCarModelsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BaseResponse>(
      {url: `/Api/Information/CarModels/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить модели
 */
const getApiInformationCarModels = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCarModelVmIReadOnlyCollectionBaseResponse>(
      {url: `/Api/Information/CarModels`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить модель
 */
const postApiInformationCarModels = (
    addCarModelRequest: AddCarModelRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddCarModelVmBaseResponse>(
      {url: `/Api/Information/CarModels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addCarModelRequest
    },
      options);
    }
  
/**
 * @summary Получить страну
 */
const getApiInformationCountriesId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCountryVmBaseResponse>(
      {url: `/Api/Information/Countries/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Обновить страну
 */
const patchApiInformationCountriesId = (
    id: string,
    updateCountryRequest: UpdateCountryRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateCountryVmBaseResponse>(
      {url: `/Api/Information/Countries/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCountryRequest
    },
      options);
    }
  
/**
 * @summary Удалить страну
 */
const deleteApiInformationCountriesId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BaseResponse>(
      {url: `/Api/Information/Countries/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить список стран
 */
const getApiInformationCountries = (
    params?: GetApiInformationCountriesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCountryVmIReadOnlyCollectionBaseResponse>(
      {url: `/Api/Information/Countries`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Добавить страну
 */
const postApiInformationCountries = (
    addCountryRequest: AddCountryRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddCountryVmBaseResponse>(
      {url: `/Api/Information/Countries`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addCountryRequest
    },
      options);
    }
  
/**
 * @summary Получить мобильное приложение
 */
const getApiInformationMobileAppsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MobileAppVmBaseResponse>(
      {url: `/Api/Information/MobileApps/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Обновить существующее мобильное приложение
 */
const patchApiInformationMobileAppsId = (
    id: string,
    updateMobileAppRequest: UpdateMobileAppRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateMobileAppVmBaseResponse>(
      {url: `/Api/Information/MobileApps/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateMobileAppRequest
    },
      options);
    }
  
/**
 * @summary Удалить мобильное приложение
 */
const deleteApiInformationMobileAppsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BaseResponse>(
      {url: `/Api/Information/MobileApps/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить список мобильных приложений
 */
const getApiInformationMobileApps = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MobileAppVmIReadOnlyCollectionBaseResponse>(
      {url: `/Api/Information/MobileApps`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить мобильное приложение
 */
const postApiInformationMobileApps = (
    addMobileAppRequest: AddMobileAppRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddMobileAppVmBaseResponse>(
      {url: `/Api/Information/MobileApps`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addMobileAppRequest
    },
      options);
    }
  
/**
 * @summary Получить саппорт
 */
const getApiInformationSupportId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetSupportVmBaseResponse>(
      {url: `/Api/Information/Support/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Обновить саппорта
 */
const patchApiInformationSupportId = (
    id: string,
    updateSupportRequest: UpdateSupportRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateSupportVmBaseResponse>(
      {url: `/Api/Information/Support/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateSupportRequest
    },
      options);
    }
  
/**
 * @summary Удалить саппорт
 */
const deleteApiInformationSupportId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BaseResponse>(
      {url: `/Api/Information/Support/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить список саппорт
 */
const getApiInformationSupport = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetSupportVmIReadOnlyCollectionBaseResponse>(
      {url: `/Api/Information/Support`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить саппорта
 */
const postApiInformationSupport = (
    addSupportRequest: AddSupportRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddSupportVmBaseResponse>(
      {url: `/Api/Information/Support`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addSupportRequest
    },
      options);
    }
  
return {getApiInformationCarBrandsId,patchApiInformationCarBrandsId,deleteApiInformationCarBrandsId,getApiInformationCarBrands,postApiInformationCarBrands,putApiInformationCarBrandsLogoId,deleteApiInformationCarBrandsLogoId,getApiInformationCarModelsId,patchApiInformationCarModelsId,deleteApiInformationCarModelsId,getApiInformationCarModels,postApiInformationCarModels,getApiInformationCountriesId,patchApiInformationCountriesId,deleteApiInformationCountriesId,getApiInformationCountries,postApiInformationCountries,getApiInformationMobileAppsId,patchApiInformationMobileAppsId,deleteApiInformationMobileAppsId,getApiInformationMobileApps,postApiInformationMobileApps,getApiInformationSupportId,patchApiInformationSupportId,deleteApiInformationSupportId,getApiInformationSupport,postApiInformationSupport}};
export type GetApiInformationCarBrandsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationCarBrandsId']>>>
export type PatchApiInformationCarBrandsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['patchApiInformationCarBrandsId']>>>
export type DeleteApiInformationCarBrandsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationCarBrandsId']>>>
export type GetApiInformationCarBrandsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationCarBrands']>>>
export type PostApiInformationCarBrandsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiInformationCarBrands']>>>
export type PutApiInformationCarBrandsLogoIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['putApiInformationCarBrandsLogoId']>>>
export type DeleteApiInformationCarBrandsLogoIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationCarBrandsLogoId']>>>
export type GetApiInformationCarModelsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationCarModelsId']>>>
export type PatchApiInformationCarModelsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['patchApiInformationCarModelsId']>>>
export type DeleteApiInformationCarModelsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationCarModelsId']>>>
export type GetApiInformationCarModelsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationCarModels']>>>
export type PostApiInformationCarModelsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiInformationCarModels']>>>
export type GetApiInformationCountriesIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationCountriesId']>>>
export type PatchApiInformationCountriesIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['patchApiInformationCountriesId']>>>
export type DeleteApiInformationCountriesIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationCountriesId']>>>
export type GetApiInformationCountriesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationCountries']>>>
export type PostApiInformationCountriesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiInformationCountries']>>>
export type GetApiInformationMobileAppsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationMobileAppsId']>>>
export type PatchApiInformationMobileAppsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['patchApiInformationMobileAppsId']>>>
export type DeleteApiInformationMobileAppsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationMobileAppsId']>>>
export type GetApiInformationMobileAppsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationMobileApps']>>>
export type PostApiInformationMobileAppsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiInformationMobileApps']>>>
export type GetApiInformationSupportIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationSupportId']>>>
export type PatchApiInformationSupportIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['patchApiInformationSupportId']>>>
export type DeleteApiInformationSupportIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationSupportId']>>>
export type GetApiInformationSupportResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationSupport']>>>
export type PostApiInformationSupportResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiInformationSupport']>>>
