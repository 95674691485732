import { useParams } from 'react-router-dom';

import { ConnectedChargePointInfo } from '../../../components/ChargePoint/components/ConnectedChargePointInfo';

export function ChargePointInfoPage() {
  const { chargePointId } = useParams() as {
    chargePointId: string;
  };

  return <ConnectedChargePointInfo chargePointId={chargePointId} />;
}
