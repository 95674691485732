import { useRef } from 'react';
import { Placemark } from '@pbe/react-yandex-maps';

import { CONNECTOR_STATUS_COLOR } from 'entities/connector';
import {
  ChargePointStatus,
  ConnectorVm,
  GetChargePointVm,
} from 'shared/api/services/chargepoint/generated';

const getInnerClass = (status: ChargePointStatus): string => {
  if (status === 'NO_CONNECT') {
    return 'grey';
  }

  if (status === 'AVAILABLE') {
    return 'green';
  }

  return 'red';
};

const getPlacemarkBorder = (connectors: ConnectorVm[]): string => {
  if (connectors.length === 1) {
    return CONNECTOR_STATUS_COLOR[connectors[0].status!]; // костыль
  }

  const stepOffset = 0.5;
  const delimeterSize = 2;
  const step = 360 / connectors.length;

  const gradient = connectors.reduce((acc, { status }, idx, arr) => {
    const firstVal = idx === 0 ? 1.5 : step * idx + 1.5;
    const secondVal = idx === 0 ? step - 1.5 : step * (idx + 1) - 1.5;

    const val = `${acc}${
      CONNECTOR_STATUS_COLOR[status!]
    } ${firstVal}deg ${secondVal}deg, transparent ${
      secondVal + stepOffset
    }deg ${
      secondVal + stepOffset + delimeterSize > 360
        ? 0
        : secondVal + stepOffset + delimeterSize
    }deg`;

    return idx === connectors.length - 1 ? `${val})` : `${val},`;
  }, 'conic-gradient(transparent 0deg 1deg,');

  return gradient;
};

type Props = {
  factory: any; // Тип ?
  chargePoint: GetChargePointVm;
  onPinClick?: () => void;
};

export const Pin = ({ chargePoint, factory, onPinClick }: Props) => {
  const { latitude, longitude, maxPower, status, connectors } = chargePoint;

  const instancePlacemarkRef = useRef();

  const CustomIconLayout = factory.createClass(
    `<div class="container">
      <div class="outer">
        <div class="item" style="background:${
          connectors.length === 0 ? 'brown' : getPlacemarkBorder(connectors)
        }">
          <div class=${`"inner ${
            connectors.length === 0 ? 'brown' : getInnerClass(status)
          }"`}>
            <div class="value">${maxPower}</div>
            <div class="label">кВт</div>
          </div>
        </div>
      </div>
      <div class="triangle">
        <div class=${`"inner-triangle ${
          connectors.length === 0 ? 'brown' : getInnerClass(status)
        }"`}></div>
      </div>
    </div>`,
    {
      build: function () {
        CustomIconLayout.superclass.build.call(this);

        if (onPinClick) {
          this.getData().geoObject.events.add('click', onPinClick, this);
        }

        this.getData().options.set('shape', {
          type: 'Circle',
          coordinates: [latitude, longitude],
          radius: 30,
        });
      },
    }
  );

  return (
    <Placemark
      instanceRef={instancePlacemarkRef}
      geometry={[latitude, longitude]}
      options={{
        iconLayout: CustomIconLayout,
        iconOffset: [-30, -60],
      }}
    />
  );
};
