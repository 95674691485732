import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getQueryParams } from 'shared/lib/router/get-query-params';

import { DEFAULT_LIMIT } from '../consts';

const DEFAULT_PAGE = 0;

// export const useQueryParamsPagination = (
//   initialPage = DEFAULT_PAGE,
//   initialLimit = DEFAULT_LIMIT
// ) => {
//   const [searchParams, setSearchParams] = useSearchParams();

//   const [page, updatePage] = useState(
//     Number(searchParams.get('page')) || initialPage
//   );
//   const [limit, updateLimit] = useState(
//     Number(searchParams.get('limit')) || initialLimit
//   );

//   useEffect(() => {
//     console.log(
//       'EFFECT, page, limit',
//       searchParams.get('page'),
//       searchParams.get('limit')
//     );
//     updatePage(Number(searchParams.get('page')) || page);
//     updateLimit(Number(searchParams.get('limit')) || limit);
//   }, [location, page, limit]);

//   const setPage = (page: number) => {
//     if (page <= 0) {
//       return;
//     }

//     const queryParams = getQueryParams();

//     setSearchParams({ ...queryParams, page: String(page) });
//   };

//   const setLimit = (limit: number) => {
//     setSearchParams({ limit: String(limit) });

//     const queryParams = getQueryParams();

//     setSearchParams({ ...queryParams, limit: String(limit) });
//   };

//   return {
//     page,
//     limit,
//     setPage,
//     setLimit,
//   };
// };

export const useQueryParamsPagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page');
  const limit = searchParams.get('limit');

  const setPage = (page: number) => {
    if (page < 0) {
      return;
    }

    const queryParams = getQueryParams();

    setSearchParams({ ...queryParams, page: String(page + 1) });
  };

  const setLimit = (limit: number) => {
    setSearchParams({ limit: String(limit) });

    const queryParams = getQueryParams();

    setSearchParams({ ...queryParams, limit: String(limit) });
  };

  return {
    page: page ? Number(page) - 1 : DEFAULT_PAGE,
    limit: limit ? Number(limit) : DEFAULT_LIMIT,
    setPage,
    setLimit,
  };
};
