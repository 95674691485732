import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useState } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  FORMAT_TO_DAYS,
  FORMAT_TO_SECONDS,
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  showTimeString,
} from 'shared/lib';
import { GroupDto, UserDto } from 'shared/api/services/user/generated';
import { GetCountryVm } from 'shared/api/services/information/generated';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { useUpdateUser } from 'shared/api/services/user/enhanced';
import { UiDivider } from 'shared/ui/ui-kit';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { PERSON_ROUTES } from 'shared/consts/routes/person';

import { SEX } from 'entities/user';

import { EDIT_ERROR, EDIT_SUCCESS } from '../../consts';

import { CardFormWrapper, FormRow } from '../../../../../common-styles';

import { FormInput, FormOutput, FormSchema } from '../../consts/schema';
import { Title, UserLogin } from './styles';

type Props = {
  countries: GetCountryVm[];
  userGroups: GroupDto[];
  user: UserDto;
};

export function Form({ countries, user, userGroups }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...user,
      registrationDate: user.registrationDate
        ? showTimeString(user.registrationDate, FORMAT_TO_SECONDS)
        : '',
      birthDate: user.birthDate
        ? showTimeString(user.birthDate, FORMAT_TO_DAYS)
        : '',
    },
  });

  const [trigger, { isLoading }] = useUpdateUser();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const navigate = useNavigate();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        userId: user.id,
        updateUserRequestDto: data,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);

      // navigate(`${PERSON_ROUTES.PERSONS_LIST}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper
      title={
        <Title>
          Пользователь <UserLogin>{` ${user.login}`}</UserLogin>
        </Title>
      }
    >
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields
              countries={countries}
              userGroups={userGroups}
              isDisabled={isDisabled}
            />
            <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  countries: GetCountryVm[];
  userGroups: GroupDto[];
  isDisabled: boolean;
};

Form.Fields = function Fields({
  countries,
  userGroups,
  isDisabled,
}: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  console.log('errors', errors);

  return (
    <>
      <FormRow gutter={10}>
        <Col span={6}>
          <CustomInput<FormInput>
            name="registrationDate"
            label="Дата регистрации"
            errorMessage={errors.registrationDate?.message}
            required
          />
        </Col>
        <Col span={6}>
          <CustomInput<FormInput>
            name="login"
            label="Номер телефона"
            errorMessage={errors.login?.message}
            required
          />
        </Col>
        <Col span={6}>
          <CustomInput<FormInput>
            name="email"
            label="Email"
            errorMessage={errors.email?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={6}>
          <CustomInput<FormInput>
            name="lastName"
            label="Фамилия"
            errorMessage={errors.lastName?.message}
          />
        </Col>
        <Col span={6}>
          <CustomInput<FormInput>
            name="firstName"
            label="Имя"
            errorMessage={errors.firstName?.message}
          />
        </Col>
        <Col span={6}>
          <CustomInput<FormInput>
            name="middleName"
            label="Отчество"
            errorMessage={errors.middleName?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={6}>
          <CustomInput<FormInput>
            name="birthDate"
            label="Дата рождения"
            errorMessage={errors.birthDate?.message}
          />
        </Col>
        <Col span={6}>
          <CustomSelect<FormInput>
            name="sex"
            label="Пол"
            errorMessage={errors.sex?.message}
            options={Object.entries(SEX).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
          />
        </Col>
        <Col span={6}>
          <CustomSelect<FormInput>
            name="countryId"
            options={countries.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Страна"
            required
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={6}>
          <CustomSelect<FormInput>
            name="groupId"
            options={userGroups.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Добавить в группу"
            allowClear
          />
        </Col>
      </FormRow>

      <UiDivider />

      <FormRow gutter={10}>
        {/* <Col span={6}>
          <FormItem
            label="Задолженность"
            name="clientsDebt"
            rules={[
              VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER,
              VALIDATION_RULE.REQUIRED,
            ]}
          >
            <UiInput disabled={isFormDisabled} />
          </FormItem>
        </Col> */}
      </FormRow>

      <UiDivider />

      <FormRow gutter={10}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="notesRelatedWithUser"
            label="Комментарий"
            errorMessage={errors.notesRelatedWithUser?.message}
          />
        </Col>
      </FormRow>

      <UiDivider />

      <FormRow style={{ gap: '10px' }}>
        <Col>
          <CustomCheckbox<FormInput>
            name="userAgreementAccepted"
            label="Флаг принятия условий ПС"
          />
        </Col>
        <Col>
          <CustomCheckbox<FormInput> name="isDebug" label="isDebug" />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
