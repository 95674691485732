import { GetChargePointVm } from 'shared/api/services/chargepoint/generated';

import { LightningIcon } from '../icons/lightning';
import { TurtleIcon } from '../icons/turtle';

export const CHARGE_POINT_TYPE_ICON: Record<
  Exclude<Exclude<GetChargePointVm['speedType'], undefined>, 'UNDEFINED'>,
  JSX.Element
> = {
  HIGH_SPEED_DC: <TurtleIcon />,
  LOW_SPEED_AC: <LightningIcon />,
};
