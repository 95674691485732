import { z } from 'zod';

import {
  ConnectorFormat,
  ConnectorStatus,
  ConnectorType,
} from 'shared/api/services/chargepoint/generated';

const type: z.ZodType<ConnectorType> = z.enum([
  'SCHUKO',
  'TYPE2',
  'CCS2',
  'CHADEMO',
  'GBTDC',
  'GBTAC',
  'CCS1',
  'TYPE1',
  'TESLA',
]);

const format: z.ZodType<ConnectorFormat> = z.enum(['SOCKET', 'CABLE']);

const status: z.ZodType<ConnectorStatus> = z.enum([
  'UNDEFINED',
  'AVAILABLE',
  'PREPARING',
  'CHARGING',
  'SUSPENDED_EVSE',
  'SUSPENDED_EV',
  'FINISHING',
  'RESERVED',
  'UNAVAILABLE',
  'FAULTED',
]);

export const FormSchema = z.object({
  type: type,
  format: format,
  status: status,
  innerConnectorId: z.coerce.number(),
  maxPower: z.coerce.number().min(0),
  maxAmperage: z.coerce.number().min(0),
  maxVoltage: z.coerce.number().min(0),
  liter: z.string(),
});

export type FormSchemaType = z.infer<typeof FormSchema>;
