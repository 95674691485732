import styled from 'styled-components';

import { COLOR } from 'shared/consts';

import { StyledInput } from '../../../../../../common-styles';

export const PageSizeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PageSizeLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
`;

export const PageSizeInput = styled(StyledInput)`
  width: 70px; // TODO: переделать на htmlSize
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
`;

export const TotalCount = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
  opacity: 0.5;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
