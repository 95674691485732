import { SESSIONS } from '../names';
import { ADD_PREFIX, GROUPS, MANUAL_COMMANDS, RESERVES } from './common';

export const CHARGE_POINTS = 'charge-points';
export const CHARGE_POINT = ':chargePointName';
export const STATUSES = 'statuses';
export const EVENTS = 'events';

export const CHARGE_POINT_ROUTES = {
  CHARGE_POINTS: `/${CHARGE_POINTS}`,
  CHARGE_POINTS_GROUPS_LIST: `/${CHARGE_POINTS}/${GROUPS}`,
  CHARGE_POINTS_SESSION_LOG: `/${CHARGE_POINTS}/${SESSIONS}`,
  CHARGE_POINTS_RESERVES: `/${CHARGE_POINTS}/${RESERVES}`,
  CHARGE_POINTS_MANUAL_COMMANDS: `/${CHARGE_POINTS}/${MANUAL_COMMANDS}`,
  // Группа ЭЗС
  ADD_CHARGE_POINT: `/${CHARGE_POINTS}/${ADD_PREFIX}`,
  ADD_CHARGE_POINT_GROUP: `/${CHARGE_POINTS}/${GROUPS}/${ADD_PREFIX}`,
};
