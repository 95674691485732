import { postApiInformationCarModelsBody } from 'shared/api/services/information/orval/zod/schemas';
import { z } from 'zod';

export const FormSchema = postApiInformationCarModelsBody;

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  carBrandId: '',
};
