import React from 'react';

import { ChargePointsMap } from 'entities/charge-points-map';
import { MapProps } from 'entities/charge-points-map/ui/map';

import { GetChargePointVm } from 'shared/api/services/chargepoint/generated';

import {
  Address,
  AddressInfo,
  AddressTitle,
  LocationContainer,
  MapContainer,
  StyledCard,
} from './styles';

const DEFAULT_MAP_ZOOM = 13;

type Props = {
  chargePoint: GetChargePointVm;
};

export function LocationCard({ chargePoint }: Props) {
  const { latitude, longitude, address } = chargePoint;

  const mapCenter = [latitude, longitude];

  const mapProps: MapProps = {
    defaultState: {
      center: mapCenter,
      zoom: DEFAULT_MAP_ZOOM,
      controls: ['zoomControl', 'geolocationControl'],
    },
    options: {
      suppressMapOpenBlock: true,
      yandexMapDisablePoiInteractivity: true,
      autoFitToViewport: 'always',
    },
    // modules: [
    //   'templateLayoutFactory',
    //   'control.ZoomControl',
    //   'control.GeolocationControl',
    // ],
  };

  return (
    <StyledCard>
      <LocationContainer>
        <AddressInfo>
          <AddressTitle>Местоположение ЭЗС</AddressTitle>
          <Address>{`Адрес: ${address}`}</Address>
        </AddressInfo>
        <MapContainer>
          <ChargePointsMap chargePoints={[chargePoint]} mapProps={mapProps} />
        </MapContainer>
      </LocationContainer>
    </StyledCard>
  );
}

export default React.memo(LocationCard);
