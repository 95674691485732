import { RESERVES, ROOT, SESSIONS } from 'shared/consts/names';
import { useAbility } from 'shared/lib/ability/context';
import { CustomLink } from 'shared/ui';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';
import { GROUPS, MANUAL_COMMANDS } from 'shared/consts/routes/common';

import { OpenManualCommandButton } from 'features/manual-command/ui/open-manual-command-modal-button';

import { AddChargePointLink } from '../add-link';
import { AddChargePointGroupLink } from '../add-group-link';
import { EVENTS, STATUSES } from 'shared/consts/routes/charge-point';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: <CustomLink to="">Информация об ЭЗС</CustomLink>,
    extraContent: <AddChargePointLink />,
  },
  {
    key: SESSIONS,
    label: <CustomLink to={SESSIONS}>Журнал сессий</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
  },
  {
    key: RESERVES,
    label: <CustomLink to={RESERVES}>Журнал брони</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
    disabled: true,
  },
  {
    key: EVENTS,
    label: <CustomLink to={EVENTS}>События</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
  },
  {
    key: MANUAL_COMMANDS,
    label: <CustomLink to={MANUAL_COMMANDS}>Управление</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
    extraContent: <OpenManualCommandButton />,
    disabled: true,
  },
  {
    key: STATUSES,
    label: <CustomLink to={STATUSES}>Статусы</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
    disabled: true,
  },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
