import { MainLayout } from 'shared/ui/layout/main';

import { ChargePointFetchingContextProvider } from '../../charge-point-context';
import { TabMenu } from '../charge-point-tab-menu';

export function ChargePointLayout() {
  return (
    <ChargePointFetchingContextProvider>
      <MainLayout tabs={<TabMenu />} />
    </ChargePointFetchingContextProvider>
  );
}
