import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useDeleteOwner } from 'shared/api/services/chargepoint/enhanced';
import { GetOwnerVm } from 'shared/api/services/chargepoint/generated';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';

type Props = {
  owner: GetOwnerVm;
};

export const DeleteModal = NiceModal.create(
  ({ owner: { id, name } }: Props) => {
    const modal = useModal();

    const [trigger, { isLoading }] = useDeleteOwner();

    const handleDelete = async () => {
      try {
        const res = await trigger(id).unwrap();

        openSuccessNotification(DELETE_SUCCESS);
      } catch (err) {
        const hasErrorMessage = isErrorWithMessage(err);

        const errorText = hasErrorMessage
          ? err.data.statusMessage
          : DELETE_ERROR;

        openErrorNotification(errorText);
      } finally {
        modal.hide();
      }
    };

    return (
      <UiModal
        title={`Удалить владельца ЭЗС "${name}"?`}
        visible={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
      >
        <ConfirmDialog
          onCancel={modal.hide}
          onConfirm={handleDelete}
          loading={isLoading}
        />
      </UiModal>
    );
  }
);
