import { GetChargePointVm } from 'shared/api/services/chargepoint/generated';

import {
  CHARGE_POINT_STATUS,
  CHARGE_POINT_SPEED_TYPE_BADGE,
} from 'entities/charge-point';

import {
  StyledCard,
  CardHeader,
  StatusBadge,
  ParamLabel,
  Param,
  ParamValue,
  ParamsContainer,
  CardHeaderContent,
  ChargePointName,
  CardContent,
  TypeBadge,
  OwnerInfoContainer,
  OwnerIcon,
  MaxPowerContainer,
  ChargePointTitle,
} from './styles';

type Props = {
  chargePoint: GetChargePointVm;
  actionsSlot: React.ReactNode;
};

export function Layout({ chargePoint, actionsSlot }: Props) {
  const { name, status, speedType, maxPower, address } = chargePoint;

  const renderedMaxPower = () => {
    let base = <ParamValue>{`${maxPower} кВт`}</ParamValue>;

    if (speedType === 'UNDEFINED') {
      return base;
    }

    return (
      <>
        {base}
        <span>&#x2022;</span>
        <TypeBadge
          speedType={speedType}
        >{` ${CHARGE_POINT_SPEED_TYPE_BADGE[speedType]}`}</TypeBadge>
      </>
    );
  };

  return (
    <>
      <StyledCard>
        <CardContent>
          <CardHeader>
            <CardHeaderContent>
              <StatusBadge status={status}>
                {CHARGE_POINT_STATUS[status]}
              </StatusBadge>
              {actionsSlot}
            </CardHeaderContent>
            <ChargePointTitle>
              <ParamLabel>ЭЗС</ParamLabel>
              <ChargePointName>{name}</ChargePointName>
            </ChargePointTitle>
          </CardHeader>
          <ParamsContainer>
            <Param>
              <ParamLabel>Мощность</ParamLabel>
              <MaxPowerContainer>{renderedMaxPower()}</MaxPowerContainer>
            </Param>
            <Param>
              <ParamLabel>Адрес</ParamLabel>
              <ParamValue>{address}</ParamValue>
            </Param>
          </ParamsContainer>
        </CardContent>
      </StyledCard>
    </>
  );
}
