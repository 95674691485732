import { z } from 'zod';
import useWindowFocus from 'use-window-focus';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { getServerErrorText } from 'shared/lib';
import { POLLING_INTERVAL } from 'shared/consts';

import {
  useGetReserveSessions,
  useGetReserveSessionsCount,
} from 'shared/api/services/reserve/enhanced';

import { TableInstance } from './table';

const pageQueryParamsSchema = z.object({
  userId: z.string().uuid().optional(),
  guidTargetReserve: z.string().uuid().optional(),
  beginDateFrom: z.string().datetime().optional(),
  beginDateTo: z.string().datetime().optional(),
  endDateFrom: z.string().datetime().optional(),
  endDateTo: z.string().datetime().optional(),
  lastUpdateFrom: z.string().datetime().optional(),
  lastUpdateTo: z.string().datetime().optional(),
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
  orderingField: z.string().optional().catch(undefined),
  orderingType: z.enum(['ASC', 'DESC']).optional(), // catch?
});

type Props = {
  chargePointId?: string;
  userId?: string;
};

export function TableContainer({ chargePointId, userId }: Props) {
  const isWindowFocused = useWindowFocus();

  const queryParams = useTypedQueryParamsCustom(pageQueryParamsSchema);

  const reserveSessionsQuery = useGetReserveSessions(
    {
      ...queryParams,

      offset: Math.max(queryParams.page - 1, 0) * queryParams.limit,
    },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const reserveSessionsCountQuery = useGetReserveSessionsCount(queryParams, {
    pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
  });

  const isLoading =
    reserveSessionsQuery.isLoading || reserveSessionsCountQuery.isLoading;
  const isFetching =
    (reserveSessionsQuery.isFetching || reserveSessionsCountQuery.isFetching) &&
    (!reserveSessionsQuery.currentData ||
      !reserveSessionsCountQuery.currentData);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [
    reserveSessionsQuery.data,
    reserveSessionsCountQuery.data,
  ].find((el) => el && el.status === 'ERROR');

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  const error = [
    reserveSessionsQuery.error,
    reserveSessionsCountQuery.error,
  ].find((err) => err !== undefined);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (
    !reserveSessionsQuery.data?.data ||
    !reserveSessionsCountQuery.data ||
    reserveSessionsCountQuery.data.data == undefined
  ) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={reserveSessionsQuery.data.data}
      totalCount={reserveSessionsCountQuery.data.data}
    />
  );
}
