import { PregPageIcon } from './images/PrevPageIcon';
import { NextPageIcon } from './images/NextPageIcon';

import { StyledNavButton } from './styles';

type Props = {
  type: 'prev' | 'next';
  disabled: boolean;
  onClick: () => void;
};

export function NavButton({ type, disabled, onClick }: Props) {
  return (
    <StyledNavButton
      onClick={onClick}
      disabled={disabled}
      icon={type === 'prev' ? <PregPageIcon /> : <NextPageIcon />}
    />
  );
}
