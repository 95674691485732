import { useSearchParams } from 'react-router-dom';

import { getQueryParams } from 'shared/lib/router/get-query-params';

export const useQueryParamsSorting = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const orderingField = searchParams.get('orderingField');
  const orderingType = searchParams.get('orderingType');

  const setSorting = (field: string) => {
    const queryParams = getQueryParams();

    if (!orderingField) {
      setSearchParams({
        ...queryParams,
        orderingField: field,
        orderingType: 'DESC',
      });

      return;
    }

    if (field !== orderingField) {
      setSearchParams({
        ...queryParams,
        orderingField: field,
        orderingType: 'DESC',
      });

      return;
    }

    if (orderingType === 'DESC') {
      setSearchParams({
        ...queryParams,
        orderingType: 'ASC',
      });

      return;
    }

    if (orderingType === 'ASC') {
      delete queryParams['orderingField'];
      delete queryParams['orderingType'];

      setSearchParams({ ...queryParams });

      return;
    }
  };

  return {
    orderingField: orderingField || '',
    orderingType: orderingType || '',
    setSorting,
  };
};
