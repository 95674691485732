import { Col, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useState } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useUpdateOwner } from 'shared/api/services/chargepoint/enhanced';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { GetOwnerVm } from 'shared/api/services/chargepoint/generated';
import { useAbility } from 'shared/lib/ability/context';

import { OWNER_TYPE } from 'entities/owner';

import { FormSchema, FormInput, FormOutput } from '../consts/schema';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

import {
  CardFormWrapper,
  FormRow,
  LabelWithHint,
} from '../../../../common-styles';

type Props = {
  owner: GetOwnerVm;
};

export function Form({ owner }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...owner },
  });

  const [trigger, { isLoading }] = useUpdateOwner();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        id: owner.id,
        updateOwnerRequest: data,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper title={owner.name}>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields />
            <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

Form.Fields = function Fields() {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="name"
            label="Наименование"
            errorMessage={errors.name?.message}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="email"
            label="Email"
            errorMessage={errors.email?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="type"
            label="Тип"
            errorMessage={errors.type?.message}
            options={Object.entries(OWNER_TYPE).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="phoneNumber"
            label="Телефон"
            errorMessage={errors.phoneNumber?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="description"
            label="Описание"
            errorMessage={errors.description?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="legalName"
            label="Юридическое наименование"
            errorMessage={errors.legalName?.message}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="directorName"
            label="ФИО руководителя"
            errorMessage={errors.directorName?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="inn"
            label="ИНН"
            errorMessage={errors.inn?.message}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="kpp"
            label="КПП"
            errorMessage={errors.kpp?.message}
            required
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="telegram"
            label="Телеграм"
            errorMessage={errors.telegram?.message}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  const ability = useAbility();

  if (!ability.can('Write', 'Country')) {
    return null;
  }

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
