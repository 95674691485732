import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import type { z } from 'zod';

export function useTypedParams<T extends z.ZodTypeAny>(
  schema: T
): ReturnType<T['parse']> {
  const params = useParams();

  return useMemo(() => schema.parse(params), [params]);
}
