import { enhancedApi as api } from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiCompanyCompanies: (endpoint) => {
      endpoint.providesTags = [{ type: 'Companies', id: 'LIST' }];
    },
    getApiCompanyCompaniesByCompanyId: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Companies', id: arg },
      ];
    },
    postApiCompanyCompanies: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Companies', id: 'LIST' }];
    },
    patchApiCompanyCompaniesByCompanyId: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Companies', id: 'LIST' },
        {
          type: 'Companies',
          id: arg.companyId,
        },
      ];
    },
  },
});

export const {
  useGetApiCompanyCompaniesQuery: useGetCompanies,
  useGetApiCompanyCompaniesByCompanyIdQuery: useGetCompany,
  usePostApiCompanyCompaniesMutation: useAddCompany,
  usePatchApiCompanyCompaniesByCompanyIdMutation: useUpdateCompany,
} = enhancedApi;
