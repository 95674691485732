import { enhancedApi as api } from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiUserUsers: (endpoint) => {
      endpoint.providesTags = [{ type: 'Users', id: 'LIST' }];
    },
    getApiUserUsersByUserId: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Users', id: arg },
      ];
    },
    postApiUserUsers: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Users', id: 'LIST' }];
    },
    patchApiUserUsersByUserId: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Users', id: 'LIST' },
        {
          type: 'Users',
          id: arg.userId,
        },
      ];
    },
    deleteApiUserUsersByUserId: () => {},

    // Группы юзеров
    getApiUserGroups: (endpoint) => {
      endpoint.providesTags = [{ type: 'UserGroups', id: 'LIST' }];
    },
    getApiUserGroupsByGroupId: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'UserGroups', id: arg },
      ];
    },
    postApiUserGroups: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'UserGroups', id: 'LIST' }];
    },
    patchApiUserGroupsByGroupId: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'UserGroups', id: 'LIST' },
        {
          type: 'UserGroups',
          id: arg.groupId,
        },
      ];
    },
    deleteApiUserGroupsByGroupId: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'UserGroups', id: 'LIST' }];
    },
  },
});

export const {
  // Юзеры
  useGetApiUserUsersQuery: useGetUsers,
  useLazyGetApiUserUsersQuery: useLazyGetUsers,
  useGetApiUserUsersByUserIdQuery: useGetUser,
  usePostApiUserUsersMutation: useAddUser,
  usePatchApiUserUsersByUserIdMutation: useUpdateUser,
  useDeleteApiUserUsersByUserIdMutation: useDeleteUser,
  useGetApiUserUsersCountQuery: useGetUsersCount,
  useLazyGetApiUserUsersCountQuery: useLazyGetUsersCount,
  // Группы юзеров
  useGetApiUserGroupsQuery: useGetUserGroups,
  useGetApiUserGroupsByGroupIdQuery: useGetUserGroup,
  usePostApiUserGroupsMutation: useAddUserGroup,
  usePatchApiUserGroupsByGroupIdMutation: useUpdateUserGroup,
  useDeleteApiUserGroupsByGroupIdMutation: useDeleteUserGroup,
} = enhancedApi;
