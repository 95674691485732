import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const DEFAULT_TABLE_PAGE_SIZE = 20;

type State = {
  tableColumns: {
    lastUpdate: boolean;
    groupId: boolean;
    warningText: boolean;
    name: boolean;
    speedType: boolean;
    manufacturer: boolean;
    softwareRevision: boolean;
    address: boolean;
    ownerName: boolean;
  };
  tableLimit: number;
  filters: {
    ownerTypes: string[];
    statuses: string[];
    ownersId: string[];
    // withTestCp: string[];
    withTestCp: boolean;
  };
};

const initialState: State = {
  tableColumns: {
    lastUpdate: true,
    groupId: true,
    warningText: true,
    name: true,
    speedType: true,
    manufacturer: true,
    softwareRevision: true,
    address: true,
    ownerName: true,
  },
  tableLimit: DEFAULT_TABLE_PAGE_SIZE,
  filters: {
    ownerTypes: [],
    statuses: [],
    ownersId: [],
    // withTestCp: [],
    withTestCp: true,
  },
};

const slice = createSlice({
  name: 'chargePointsSlice',
  initialState,
  reducers: {
    setTableColumns: (
      state,
      { payload }: PayloadAction<State['tableColumns']>
    ) => {
      state.tableColumns = payload;
    },
    setTableLimit: (state, { payload }: PayloadAction<number>) => {
      state.tableLimit = payload;
    },
    //
    setOwnerTypes: (state, { payload }: PayloadAction<string[]>) => {
      state.filters.ownerTypes = payload;
    },
    setStatuses: (state, { payload }: PayloadAction<string[]>) => {
      state.filters.statuses = payload;
    },
    setOwnerIds: (state, { payload }: PayloadAction<string[]>) => {
      state.filters.ownersId = payload;
    },
  },
});

export const { setTableColumns, setTableLimit } = slice.actions;

export const selectChargePointsTableColumns = (state: RootState) =>
  state.chargePoints.tableColumns;
export const selectChargePointsTableLimit = (state: RootState) =>
  state.chargePoints.tableLimit;
export const selectOwnerIds = (state: RootState) =>
  state.chargePoints.filters.ownersId;
export const selectOwnerTypes = (state: RootState) =>
  state.chargePoints.filters.ownerTypes;
export const selectStatuses = (state: RootState) =>
  state.chargePoints.filters.statuses;
export const selectWithTestCp = (state: RootState) =>
  state.chargePoints.filters.withTestCp;

export default slice.reducer;
