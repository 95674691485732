import { GetGroupVm } from 'shared/api/services/chargepoint/generated';

export function getOpenKeys(groups: GetGroupVm[], groupId: string): string[] {
  const group = groups.find((elem) => elem.id === groupId);

  if (!group) {
    return [groupId];
  }

  if (!group?.parentId) return [groupId];

  return [groupId, ...getOpenKeys(groups, group.parentId)];
}
