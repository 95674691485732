export function StatusForUserIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 4.375C9.5 5.47957 8.60457 6.375 7.5 6.375V7.375C9.15685 7.375 10.5 6.03185 10.5 4.375H9.5ZM7.5 6.375C6.39543 6.375 5.5 5.47957 5.5 4.375H4.5C4.5 6.03185 5.84315 7.375 7.5 7.375V6.375ZM5.5 4.375C5.5 3.27043 6.39543 2.375 7.5 2.375V1.375C5.84315 1.375 4.5 2.71815 4.5 4.375H5.5ZM7.5 2.375C8.60457 2.375 9.5 3.27043 9.5 4.375H10.5C10.5 2.71815 9.15685 1.375 7.5 1.375V2.375ZM11.375 10.9375C11.375 11.2501 11.1086 11.6678 10.37 12.0371C9.66133 12.3914 8.64733 12.625 7.5 12.625V13.625C8.76891 13.625 9.94242 13.3689 10.8172 12.9315C11.6621 12.5091 12.375 11.833 12.375 10.9375H11.375ZM7.5 12.625C6.35267 12.625 5.33867 12.3914 4.63001 12.0371C3.89145 11.6678 3.625 11.2501 3.625 10.9375H2.625C2.625 11.833 3.33793 12.5091 4.1828 12.9315C5.05758 13.3689 6.23109 13.625 7.5 13.625V12.625ZM3.625 10.9375C3.625 10.6249 3.89145 10.2072 4.63001 9.83792C5.33867 9.48359 6.35267 9.25 7.5 9.25V8.25C6.23109 8.25 5.05758 8.5061 4.1828 8.94349C3.33793 9.36593 2.625 10.042 2.625 10.9375H3.625ZM7.5 9.25C8.64733 9.25 9.66133 9.48359 10.37 9.83792C11.1086 10.2072 11.375 10.6249 11.375 10.9375H12.375C12.375 10.042 11.6621 9.36593 10.8172 8.94349C9.94242 8.5061 8.76891 8.25 7.5 8.25V9.25Z"
        fill="white"
      />
    </svg>
  );
}
