import { z } from 'zod';

import {
  SpeedType,
  ChargePointStatus,
  ChargePointProtocol,
  CountryCode,
} from 'shared/api/services/chargepoint/generated';
import { COLOR } from 'shared/consts';

export const ADDRESS_MAX_LENGTH = 45;

export const CITY_MIN_LENGTH = 3;

export const CHARGE_POINT_SPEED_TYPE: Record<SpeedType, string> = {
  HIGH_SPEED_DC: 'Быстрая',
  LOW_SPEED_AC: 'Медленная',
  UNDEFINED: 'Не определено',
};

export const CHARGE_POINT_STATUS: Record<ChargePointStatus, string> = {
  AVAILABLE: 'Свободна, Доступна',
  UNAVAILABLE: 'Не доступна',
  NO_CONNECT: 'Нет связи',
  FAULTED: 'Неисправна',
};

export const CHARGE_POINT_PROTOCOL: Record<ChargePointProtocol, string> = {
  OCPP16: 'OCPP16',
  OCPP16J: 'OCPP16J',
  OCPP20: 'OCPP20',
};

export const CHARGE_POINT_STATUS_COLOR: Record<ChargePointStatus, string> = {
  NO_CONNECT: COLOR.uninitialized,
  AVAILABLE: COLOR.greenMenu,
  UNAVAILABLE: COLOR.red,
  FAULTED: COLOR.red,
};

export const CHARGE_POINT_SPEED_TYPE_BADGE: Record<
  Exclude<SpeedType, 'UNDEFINED'>,
  string
> = {
  HIGH_SPEED_DC: 'DC',
  LOW_SPEED_AC: 'AC',
};

export const CHARGE_POINT_SPEED_TYPE_COLOR: Record<
  Exclude<SpeedType, 'UNDEFINED'>,
  string
> = {
  LOW_SPEED_AC: COLOR.yellow,
  HIGH_SPEED_DC: COLOR.greenMenu,
};

export const OCPP_CONFIG_REGEX = /^[\w]+=[\w\"]+(\s[\w]+=[\w\"]+)*$/;

const ERROR_TEXT = 'Формат данных: пара ключ=значение на отдельной строке';

export const speedType: z.ZodType<SpeedType> = z.enum([
  'UNDEFINED',
  'LOW_SPEED_AC',
  'HIGH_SPEED_DC',
]);

export const status: z.ZodType<ChargePointStatus> = z.enum([
  'NO_CONNECT',
  'AVAILABLE',
  'FAULTED',
  'UNAVAILABLE',
]);

export const chargePointProtocol: z.ZodType<ChargePointProtocol> = z.enum([
  'OCPP16',
  'OCPP16J',
  'OCPP20',
]);

export const countryCode: z.ZodType<CountryCode> = z.enum([
  'RU',
  'BY',
  'AM',
  'KZ',
  'KG',
]);
