const PARAM_DELIMETER = '\n';
const KEY_VALUE_DELIMETER = '=';

export const buildOcppConfigObject = (
  configString: string | null | undefined
): string | null => {
  if (!configString) {
    return null;
  }

  const configObject = configString.split(PARAM_DELIMETER).reduce((acc, el) => {
    const [key, val] = el.trim().split(KEY_VALUE_DELIMETER);

    acc[key] = val;

    return acc;
  }, {} as Record<string, string>);

  return JSON.stringify(configObject);
};
