import { enhancedApi as api } from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    // ЭЗС
    getApiChargePointChargePoints: (endpoint) => {
      endpoint.providesTags = [{ type: 'ChargePoints', id: 'LIST' }];
    },
    getApiChargePointChargePointsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'ChargePoints', id: arg },
      ];
    },
    postApiChargePointChargePoints: (endpoint) => {
      endpoint.invalidatesTags = [
        { type: 'ChargePoints', id: 'LIST' },
        {
          type: 'ChargePointGroups',
          id: 'LIST',
        },
      ];
    },
    patchApiChargePointChargePointsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'ChargePoints', id: 'LIST' },
        { type: 'ChargePoints', id: arg.id },
        {
          type: 'ChargePointGroups',
          id: 'LIST',
        },
      ];
    },
    // Группы ЭЗС
    getApiChargePointGroups: (endpoint) => {
      endpoint.providesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
    },
    getApiChargePointGroupsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'ChargePointGroups', id: arg },
      ];
    },
    postApiChargePointGroups: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
    },
    patchApiChargePointGroupsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'ChargePointGroups', id: 'LIST' },
        {
          type: 'ChargePointGroups',
          id: arg.id,
        },
      ];
    },
    deleteApiChargePointGroupsById: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
    },
    // Коннекторы
    postApiChargePointChargePointsByChargePointsIdConnector: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        // для коннекторов ранее не делал
        {
          type: 'ChargePoints',
          id: arg.chargePointsId,
        },
      ];
    },
    patchApiChargePointChargePointsByChargePointsIdAndConnectorId: (
      endpoint
    ) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        // для коннекторов ранее не делал
        {
          type: 'ChargePoints',
          id: arg.chargePointsId,
        },
      ];
    },
    deleteApiChargePointChargePointsByChargePointsIdAndConnectorId: (
      endpoint
    ) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        // для коннекторов ранее не делал
        {
          type: 'ChargePoints',
          id: arg.chargePointsId,
        },
      ];
    },
    // Хабы
    getApiChargePointHubs: (endpoint) => {
      endpoint.providesTags = [{ type: 'Hubs', id: 'LIST' }];
    },
    getApiChargePointHubsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Hubs', id: arg },
      ];
    },
    postApiChargePointHubs: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Hubs', id: 'LIST' }];
    },
    patchApiChargePointHubsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Hubs', id: 'LIST' },
        {
          type: 'Hubs',
          id: arg.id,
        },
      ];
    },
    // Оунеры
    getApiChargePointOwners: (endpoint) => {
      endpoint.providesTags = [{ type: 'Owners', id: 'LIST' }];
    },
    getApiChargePointOwnersById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Owners', id: arg },
      ];
    },
    postApiChargePointOwners: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Owners', id: 'LIST' }];
    },
    patchApiChargePointOwnersById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Owners', id: 'LIST' },
        {
          type: 'Owners',
          id: arg.id,
        },
      ];
    },
    deleteApiChargePointOwnersById: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Owners', id: 'LIST' }];
    },
    // Сессии
    getApiChargePointSessions: (endpoint) => {
      endpoint.providesTags = [{ type: 'Sessions', id: 'LIST' }];
    },
    // События
    getApiChargePointEvents: (endpoint) => {
      endpoint.providesTags = [{ type: 'Events', id: 'LIST' }];
    },
    // Фото эзс
    getApiChargePointPhotos: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'ChargePointPhotos', id: arg },
      ];
    },
  },
});

export const {
  // ЭЗС
  useGetApiChargePointChargePointsQuery: useGetChargePoints,
  useLazyGetApiChargePointChargePointsQuery: useLazyGetChargePoints,
  useGetApiChargePointChargePointsCountQuery: useGetChargePointsCount,
  useLazyGetApiChargePointChargePointsCountQuery: useLazyGetChargePointsCount,
  useGetApiChargePointChargePointsByIdQuery: useGetChargePoint,
  usePostApiChargePointChargePointsMutation: useAddChargePoint,
  usePatchApiChargePointChargePointsByIdMutation: useUpdateChargePoint,
  // Коннекторы
  usePostApiChargePointChargePointsByChargePointsIdConnectorMutation:
    useAddConnector,
  usePatchApiChargePointChargePointsByChargePointsIdAndConnectorIdMutation:
    useUpdateConnector,
  useDeleteApiChargePointChargePointsByChargePointsIdAndConnectorIdMutation:
    useDeleteConnector,
  // Группы ЭЗС
  useGetApiChargePointGroupsQuery: useGetChargePointGroups,
  useGetApiChargePointGroupsCountQuery: useGetChargePointGroupsCount,
  useGetApiChargePointGroupsByIdQuery: useGetChargePointGroup,
  usePostApiChargePointGroupsMutation: useAddChargePointGroup,
  usePatchApiChargePointGroupsByIdMutation: useUpdateChargePointGroup,
  useDeleteApiChargePointGroupsByIdMutation: useDeleteChargePointGroup,
  // Хабы
  useGetApiChargePointHubsQuery: useGetHubs,
  useGetApiChargePointHubsByIdQuery: useGetHub,
  usePostApiChargePointHubsMutation: useAddHub,
  usePatchApiChargePointHubsByIdMutation: useUpdateHub,
  // Оунеры
  useGetApiChargePointOwnersQuery: useGetOwners,
  useGetApiChargePointOwnersByIdQuery: useGetOwner,
  useGetApiChargePointOwnersCountQuery: useGetOwnersCount,
  usePostApiChargePointOwnersMutation: useAddOwner,
  usePatchApiChargePointOwnersByIdMutation: useUpdateOwner,
  useDeleteApiChargePointOwnersByIdMutation: useDeleteOwner,
  // Сессии
  useGetApiChargePointSessionsQuery: useGetSessions,
  useGetApiChargePointSessionsCountQuery: useGetSessionsCount,
  // События
  useGetApiChargePointEventsQuery: useGetEvents,
  useGetApiChargePointEventsCountQuery: useGetEventsCount,
  // Фото ЭЗС
  useGetApiChargePointPhotosQuery: useGetChargePointPhotos,
} = enhancedApi;
