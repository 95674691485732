import { Tooltip } from 'antd';

import { StatusLabel, StyledStatusBadge, IconContainer } from './styles';

type Props = {
  label: string;
  bgColor: string;
  icon: React.ReactNode;
  hint: string;
};

export function StatusBadge({ bgColor, label, icon, hint }: Props) {
  return (
    <Tooltip title={hint}>
      <StyledStatusBadge bgColor={bgColor}>
        <IconContainer>{icon}</IconContainer>
        <StatusLabel>{label}</StatusLabel>
      </StyledStatusBadge>
    </Tooltip>
  );
}
