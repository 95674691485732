import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { ROLE, ROLES, USERS } from 'shared/consts/routes/role';
import {
  ADD_PREFIX,
  CHARGE_POINTS_RIGHTS,
  CONTROLLER_CLAIMS,
} from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';

// import { ConnectedRoleChargePointRights } from '../../../components/Security/Role/components/ConnectedRoleChargePointRights';
// import { ConnectedRoleControllerRights } from '../../../components/Security/Role/components//ConnectedRoleControllerRights';

import { IdentityRolesLayout } from 'entities/identity-role/ui/roles-layout';
import { IdentityRoleLayout } from 'entities/identity-role/ui/role-layout';

import { AddIdentityRolePage } from 'pages/identity-role/add';
import { EditIdentityRolePage } from 'pages/identity-role/edit';

import { IdentityRolesMenu } from 'features/identity-role/menu';
import { RoleListTable } from 'features/identity-role/table';
import { RoleUserListTable } from 'features/identity-role/users-table';

export const RoleRoutes = (
  <Route element={<RequireAuth controllerName="Identity" rightType="Read" />}>
    <Route
      path={ROLES}
      element={<SectionLayout menu={<IdentityRolesMenu />} />}
    >
      <Route element={<IdentityRolesLayout />}>
        <Route index element={<RoleListTable />} />
      </Route>

      <Route
        element={<RequireAuth controllerName="Identity" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddIdentityRolePage />} />}
        />
      </Route>
      <Route path={ROLE} element={<IdentityRoleLayout />}>
        <Route index element={<EditIdentityRolePage />} />
        {/* <Route
          path={CONTROLLER_CLAIMS}
          element={<ConnectedRoleControllerRights />}
        />
        <Route
          path={CHARGE_POINTS_RIGHTS}
          element={<ConnectedRoleChargePointRights />}
        /> */}
        <Route path={USERS} element={<RoleUserListTable />} />
      </Route>
    </Route>
  </Route>
);
