import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const DEFAULT_TABLE_PAGE_SIZE = 20;

type State = {
  tableColumns: {
    registrationDate: boolean;
    login: boolean;
    email: boolean;
    userAgreementAccepted: boolean;
    notesRelatedWithUser: boolean;
  };
  tableLimit: number;
};

const initialState: State = {
  tableColumns: {
    registrationDate: true,
    login: true,
    email: true,
    userAgreementAccepted: true,
    notesRelatedWithUser: true,
  },
  tableLimit: DEFAULT_TABLE_PAGE_SIZE,
};

const slice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    setTableColumns: (
      state,
      { payload }: PayloadAction<State['tableColumns']>
    ) => {
      state.tableColumns = payload;
    },
    setTableLimit: (state, { payload }: PayloadAction<number>) => {
      state.tableLimit = payload;
    },
  },
  // selectors: {
  //   selectTableColumns: (state) => state.tableColumns,
  //   selectTableLimit: (state) => state.tableLimit,
  // },
});

export const { setTableColumns, setTableLimit } = slice.actions;

export const selectUsersTableColumns = (state: RootState) =>
  state.users.tableColumns;
export const selectUsersTableLimit = (state: RootState) =>
  state.users.tableLimit;

export default slice.reducer;
