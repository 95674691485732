import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { ChargePointListTable } from 'features/charge-point/table';

const pageParamsSchema = z.object({
  ownerId: z.string(),
});

export function OwnerChargePointListPage() {
  const { ownerId } = useTypedParams(pageParamsSchema);

  return <ChargePointListTable ownersId={[ownerId]} />;
}
