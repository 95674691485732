export function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M2.25 15.75H15.75M10.3383 3.98378C10.3383 3.98378 10.3383 5.20975 11.5643 6.43572C12.7902 7.66169 14.0162 7.66169 14.0162 7.66169M5.48972 13.4911L8.06425 13.1233C8.43562 13.0702 8.77977 12.8981 9.04503 12.6329L15.2422 6.43572C15.9193 5.75864 15.9193 4.66087 15.2422 3.98378L14.0162 2.75781C13.3391 2.08073 12.2414 2.08073 11.5643 2.75781L5.36712 8.95497C5.10186 9.22023 4.92979 9.56438 4.87674 9.93575L4.50894 12.5103C4.42721 13.0824 4.9176 13.5728 5.48972 13.4911Z"
        stroke="#2F2F2F"
        stroke-linecap="round"
      />
    </svg>
  );
}
