export function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M12 6H6M12 6C13.6569 6 15 7.34315 15 9V13.5C15 15.1569 13.6569 16.5 12 16.5H6C4.34315 16.5 3 15.1569 3 13.5V9C3 7.34315 4.34315 6 6 6M12 6V4.5C12 2.84315 10.6569 1.5 9 1.5C7.34315 1.5 6 2.84315 6 4.5V6M9 12V10.5"
        stroke="#F86767"
        stroke-linecap="round"
      />
    </svg>
  );
}
