import { z } from 'zod';
import { UploadFile } from 'antd';

import { hasAcceptedExtension } from 'shared/lib';

import { ACCEPTED_IMAGE_TYPES, MAX_FILE_SIZE } from 'entities/car-brand/consts';
import { postApiInformationCarBrandsBody } from 'shared/api/services/information/orval/zod/schemas';

export const FormSchema = postApiInformationCarBrandsBody.extend({
  file: z
    .array(z.custom<UploadFile>())
    .refine((files) => {
      return files.every((file) => file.originFileObj instanceof File);
    })
    .refine((files) => {
      return files.length == 1;
    }, 'Image is required.')
    .transform((files) => files[0])
    .transform((val, ctx) => {
      if (!val.originFileObj) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Missing original files',
        });

        return z.NEVER;
      }

      return val.originFileObj;
    })
    .refine((file) => {
      const { size } = file;

      return size <= MAX_FILE_SIZE;
    }, `Max file size is 1MB.`)
    .refine((file) => {
      const { name } = file;

      return hasAcceptedExtension(ACCEPTED_IMAGE_TYPES, name);
    }, 'Unsupported file type.')
    .optional(),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
