import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiIdentityUsersLogin: build.mutation<
      PostApiIdentityUsersLoginApiResponse,
      PostApiIdentityUsersLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Users/Login`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiIdentityUsersRefreshTokens: build.mutation<
      PostApiIdentityUsersRefreshTokensApiResponse,
      PostApiIdentityUsersRefreshTokensApiArg
    >({
      query: () => ({
        url: `/Api/Identity/Users/RefreshTokens`,
        method: 'POST',
      }),
    }),
    postApiIdentityUsersByLoginLockoutUser: build.mutation<
      PostApiIdentityUsersByLoginLockoutUserApiResponse,
      PostApiIdentityUsersByLoginLockoutUserApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Users/${queryArg}/LockoutUser`,
        method: 'POST',
      }),
    }),
    postApiIdentityUsersByLoginUnlockUser: build.mutation<
      PostApiIdentityUsersByLoginUnlockUserApiResponse,
      PostApiIdentityUsersByLoginUnlockUserApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Users/${queryArg}/UnlockUser`,
        method: 'POST',
      }),
    }),
    getApiIdentityUsersByUserName: build.query<
      GetApiIdentityUsersByUserNameApiResponse,
      GetApiIdentityUsersByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/Users/${queryArg}` }),
    }),
    deleteApiIdentityUsersByUserName: build.mutation<
      DeleteApiIdentityUsersByUserNameApiResponse,
      DeleteApiIdentityUsersByUserNameApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Users/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiIdentityUsers: build.query<
      GetApiIdentityUsersApiResponse,
      GetApiIdentityUsersApiArg
    >({
      query: () => ({ url: `/Api/Identity/Users` }),
    }),
    postApiIdentityUsers: build.mutation<
      PostApiIdentityUsersApiResponse,
      PostApiIdentityUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Users`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    patchApiIdentityUsers: build.mutation<
      PatchApiIdentityUsersApiResponse,
      PatchApiIdentityUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Users`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    postApiIdentityUserRoles: build.mutation<
      PostApiIdentityUserRolesApiResponse,
      PostApiIdentityUserRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/UserRoles`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    putApiIdentityUserRoles: build.mutation<
      PutApiIdentityUserRolesApiResponse,
      PutApiIdentityUserRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/UserRoles`,
        method: 'PUT',
        body: queryArg,
      }),
    }),
    deleteApiIdentityUserRoles: build.mutation<
      DeleteApiIdentityUserRolesApiResponse,
      DeleteApiIdentityUserRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/UserRoles`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityUserRolesByUserName: build.query<
      GetApiIdentityUserRolesByUserNameApiResponse,
      GetApiIdentityUserRolesByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/UserRoles/${queryArg}` }),
    }),
    postApiIdentityUserGroups: build.mutation<
      PostApiIdentityUserGroupsApiResponse,
      PostApiIdentityUserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/UserGroups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteApiIdentityUserGroups: build.mutation<
      DeleteApiIdentityUserGroupsApiResponse,
      DeleteApiIdentityUserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/UserGroups`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityUserClaimsByUserName: build.query<
      GetApiIdentityUserClaimsByUserNameApiResponse,
      GetApiIdentityUserClaimsByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/UserClaims/${queryArg}` }),
    }),
    postApiIdentityUserClaims: build.mutation<
      PostApiIdentityUserClaimsApiResponse,
      PostApiIdentityUserClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/UserClaims`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteApiIdentityUserClaims: build.mutation<
      DeleteApiIdentityUserClaimsApiResponse,
      DeleteApiIdentityUserClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/UserClaims`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityRolesByRoleName: build.query<
      GetApiIdentityRolesByRoleNameApiResponse,
      GetApiIdentityRolesByRoleNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/Roles/${queryArg}` }),
    }),
    deleteApiIdentityRolesByRoleName: build.mutation<
      DeleteApiIdentityRolesByRoleNameApiResponse,
      DeleteApiIdentityRolesByRoleNameApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Roles/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiIdentityRoles: build.query<
      GetApiIdentityRolesApiResponse,
      GetApiIdentityRolesApiArg
    >({
      query: () => ({ url: `/Api/Identity/Roles` }),
    }),
    postApiIdentityRoles: build.mutation<
      PostApiIdentityRolesApiResponse,
      PostApiIdentityRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Roles`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    patchApiIdentityRoles: build.mutation<
      PatchApiIdentityRolesApiResponse,
      PatchApiIdentityRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Roles`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    getApiIdentityRoleClaimsByRoleName: build.query<
      GetApiIdentityRoleClaimsByRoleNameApiResponse,
      GetApiIdentityRoleClaimsByRoleNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/RoleClaims/${queryArg}` }),
    }),
    postApiIdentityRoleClaims: build.mutation<
      PostApiIdentityRoleClaimsApiResponse,
      PostApiIdentityRoleClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/RoleClaims`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteApiIdentityRoleClaims: build.mutation<
      DeleteApiIdentityRoleClaimsApiResponse,
      DeleteApiIdentityRoleClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/RoleClaims`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityUserRightsByUserName: build.query<
      GetApiIdentityUserRightsByUserNameApiResponse,
      GetApiIdentityUserRightsByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/UserRights/${queryArg}` }),
    }),
    getApiIdentityRoleRightsByRoleName: build.query<
      GetApiIdentityRoleRightsByRoleNameApiResponse,
      GetApiIdentityRoleRightsByRoleNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/RoleRights/${queryArg}` }),
    }),
    postApiIdentityUserRights: build.mutation<
      PostApiIdentityUserRightsApiResponse,
      PostApiIdentityUserRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/UserRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiIdentityGroupsByGroupName: build.query<
      GetApiIdentityGroupsByGroupNameApiResponse,
      GetApiIdentityGroupsByGroupNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/Groups/${queryArg}` }),
    }),
    getApiIdentityGroups: build.query<
      GetApiIdentityGroupsApiResponse,
      GetApiIdentityGroupsApiArg
    >({
      query: () => ({ url: `/Api/Identity/Groups` }),
    }),
    postApiIdentityGroups: build.mutation<
      PostApiIdentityGroupsApiResponse,
      PostApiIdentityGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Groups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    patchApiIdentityGroups: build.mutation<
      PatchApiIdentityGroupsApiResponse,
      PatchApiIdentityGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Groups`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    deleteApiIdentityGroups: build.mutation<
      DeleteApiIdentityGroupsApiResponse,
      DeleteApiIdentityGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/Groups`,
        method: 'DELETE',
        params: { GroupId: queryArg.groupId, GroupName: queryArg.groupName },
      }),
    }),
    headApiIdentitySystemActionsLogOutAllUsers: build.mutation<
      HeadApiIdentitySystemActionsLogOutAllUsersApiResponse,
      HeadApiIdentitySystemActionsLogOutAllUsersApiArg
    >({
      query: () => ({
        url: `/Api/Identity/System/Actions/LogOutAllUsers`,
        method: 'HEAD',
      }),
    }),
    postApiIdentitySystemActionsGetSicretKey: build.mutation<
      PostApiIdentitySystemActionsGetSicretKeyApiResponse,
      PostApiIdentitySystemActionsGetSicretKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/System/Actions/GetSicretKey`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiIdentityUsersLoginApiResponse =
  /** status 200 Success */ WebApiResponseLoginResponse;
export type PostApiIdentityUsersLoginApiArg = WebApiRequestLoginRequest;
export type PostApiIdentityUsersRefreshTokensApiResponse =
  /** status 200 Success */ WebApiResponseRefreshTokensResponse;
export type PostApiIdentityUsersRefreshTokensApiArg = void;
export type PostApiIdentityUsersByLoginLockoutUserApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type PostApiIdentityUsersByLoginLockoutUserApiArg = string;
export type PostApiIdentityUsersByLoginUnlockUserApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type PostApiIdentityUsersByLoginUnlockUserApiArg = string;
export type GetApiIdentityUsersByUserNameApiResponse =
  /** status 200 Success */ WebApiResponseGetUserResponse;
export type GetApiIdentityUsersByUserNameApiArg = string;
export type DeleteApiIdentityUsersByUserNameApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityUsersByUserNameApiArg = string;
export type GetApiIdentityUsersApiResponse =
  /** status 200 Success */ WebApiResponseGetUsersResponse;
export type GetApiIdentityUsersApiArg = void;
export type PostApiIdentityUsersApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type PostApiIdentityUsersApiArg = WebApiRequestAddUserRequest;
export type PatchApiIdentityUsersApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type PatchApiIdentityUsersApiArg = WebApiRequestUpdateUserRequest;
export type PostApiIdentityUserRolesApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type PostApiIdentityUserRolesApiArg = WebApiRequestAddUserRoleRequest;
export type PutApiIdentityUserRolesApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type PutApiIdentityUserRolesApiArg = WebApiRequestUpdateUserRoleRequest;
export type DeleteApiIdentityUserRolesApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityUserRolesApiArg =
  WebApiRequestDeleteUserRoleRequest;
export type GetApiIdentityUserRolesByUserNameApiResponse =
  /** status 200 Success */ WebApiResponseGetUserRolesResponse;
export type GetApiIdentityUserRolesByUserNameApiArg = string;
export type PostApiIdentityUserGroupsApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type PostApiIdentityUserGroupsApiArg = WebApiRequestAddUserGroupRequest;
export type DeleteApiIdentityUserGroupsApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityUserGroupsApiArg =
  WebApiRequestDeleteUserGroupRequest;
export type GetApiIdentityUserClaimsByUserNameApiResponse =
  /** status 200 Success */ WebApiResponseGetRoleClaimsResponse;
export type GetApiIdentityUserClaimsByUserNameApiArg = string;
export type PostApiIdentityUserClaimsApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type PostApiIdentityUserClaimsApiArg = WebApiRequestAddUserClaimRequest;
export type DeleteApiIdentityUserClaimsApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityUserClaimsApiArg =
  WebApiRequestDeleteUserClaimRequest;
export type GetApiIdentityRolesByRoleNameApiResponse =
  /** status 200 Success */ WebApiResponseGetRoleResponse;
export type GetApiIdentityRolesByRoleNameApiArg = string;
export type DeleteApiIdentityRolesByRoleNameApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityRolesByRoleNameApiArg = string;
export type GetApiIdentityRolesApiResponse =
  /** status 200 Success */ WebApiResponseGetRolesResponse;
export type GetApiIdentityRolesApiArg = void;
export type PostApiIdentityRolesApiResponse =
  /** status 200 Success */ WebApiResponseAddRoleResponse;
export type PostApiIdentityRolesApiArg = WebApiRequestAddRoleRequest;
export type PatchApiIdentityRolesApiResponse =
  /** status 200 Success */ WebApiResponseUpdateRoleResponse;
export type PatchApiIdentityRolesApiArg = WebApiRequestUpdateRoleRequest;
export type GetApiIdentityRoleClaimsByRoleNameApiResponse =
  /** status 200 Success */ WebApiResponseGetRoleClaimsResponse;
export type GetApiIdentityRoleClaimsByRoleNameApiArg = string;
export type PostApiIdentityRoleClaimsApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type PostApiIdentityRoleClaimsApiArg = WebApiRequestAddRoleClaimRequest;
export type DeleteApiIdentityRoleClaimsApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityRoleClaimsApiArg =
  WebApiRequestDeleteRoleClaimRequest;
export type GetApiIdentityUserRightsByUserNameApiResponse =
  /** status 200 Success */ WebApiResponseGetRightsResponse;
export type GetApiIdentityUserRightsByUserNameApiArg = string;
export type GetApiIdentityRoleRightsByRoleNameApiResponse =
  /** status 200 Success */ WebApiResponseGetRightsResponse;
export type GetApiIdentityRoleRightsByRoleNameApiArg = string;
export type PostApiIdentityUserRightsApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type PostApiIdentityUserRightsApiArg = WebApiRequestSetUserRightsRequest;
export type GetApiIdentityGroupsByGroupNameApiResponse =
  /** status 200 Success */ WebApiResponseGetGroupResponse;
export type GetApiIdentityGroupsByGroupNameApiArg = string;
export type GetApiIdentityGroupsApiResponse =
  /** status 200 Success */ WebApiResponseGetGroupsResponse;
export type GetApiIdentityGroupsApiArg = void;
export type PostApiIdentityGroupsApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type PostApiIdentityGroupsApiArg = WebApiRequestAddGroupRequest;
export type PatchApiIdentityGroupsApiResponse =
  /** status 200 Success */ WebApiResponseUpdateGroupResponse;
export type PatchApiIdentityGroupsApiArg = WebApiRequestUpdateGroupRequest;
export type DeleteApiIdentityGroupsApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityGroupsApiArg = {
  groupId?: string;
  groupName?: string;
};
export type HeadApiIdentitySystemActionsLogOutAllUsersApiResponse =
  /** status 200 Success */ WebApiMiddlewareResponseBase;
export type HeadApiIdentitySystemActionsLogOutAllUsersApiArg = void;
export type PostApiIdentitySystemActionsGetSicretKeyApiResponse =
  /** status 200 Success */ string;
export type PostApiIdentitySystemActionsGetSicretKeyApiArg =
  WebApiRequestLoginRequest;
export type WebApiMiddlewareStatus = 'Success' | 'Error' | 'PartUnavailable';
export type WebApiMiddlewareUnavailableServices =
  | 'ChargePointService'
  | 'CpWsController'
  | 'CpSoapController'
  | 'BillingService'
  | 'PaymentService'
  | 'FiscalService'
  | 'SberPay'
  | 'ArmeniaPay'
  | 'EPay'
  | 'FiscalRussia'
  | 'FiscalArmenia'
  | 'BonusService'
  | 'DiscountService'
  | 'MobileApiBff'
  | 'UserService'
  | 'ReserveService'
  | 'OcpiService'
  | 'SmsService'
  | 'PushNotificationService'
  | 'SystemInformationService'
  | 'IdentityService'
  | 'AnalyticsService';
export type WebApiResponseLoginResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  accessToken?: string | null;
  refreshToken?: string | null;
};
export type WebApiRequestLoginRequest = {
  login?: string | null;
  password?: string | null;
};
export type WebApiResponseRefreshTokensResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  accessToken?: string | null;
  refreshToken?: string | null;
};
export type WebApiMiddlewareResponseBase = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
};
export type ApplicationDtoClaimDto = {
  type?: string | null;
  value?: string | null;
};
export type ApplicationDtoUserDto = {
  id?: string;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  email?: string | null;
  groups?: string[] | null;
  roles?: string[] | null;
  claims?: ApplicationDtoClaimDto[] | null;
  lockoutEnd?: string | null;
  userLockoutEnd?: string | null;
  isSystem?: boolean;
};
export type WebApiResponseGetUserResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  user?: ApplicationDtoUserDto;
};
export type WebApiResponseGetUsersResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  users?: ApplicationDtoUserDto[] | null;
};
export type WebApiRequestAddUserRequest = {
  userName?: string | null;
  email?: string | null;
  password?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  isSystem?: boolean;
};
export type WebApiRequestUpdateUserRequest = {
  id?: string;
  userName?: string | null;
  email?: string | null;
  newPassword?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
};
export type WebApiRequestAddUserRoleRequest = {
  userName?: string | null;
  roles?: string[] | null;
};
export type WebApiRequestUpdateUserRoleRequest = {
  userName?: string | null;
  roles?: string[] | null;
};
export type WebApiRequestDeleteUserRoleRequest = {
  userName?: string | null;
  roleName?: string | null;
};
export type WebApiDtoRoleDto = {
  id?: string;
  name?: string | null;
};
export type WebApiResponseGetUserRolesResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  roles?: WebApiDtoRoleDto[] | null;
};
export type WebApiRequestAddUserGroupRequest = {
  userName?: string | null;
  groupName?: string | null;
};
export type WebApiRequestDeleteUserGroupRequest = {
  userName?: string | null;
  groupId?: string | null;
  groupName?: string | null;
};
export type WebApiDtoClaimDto = {
  type?: string | null;
  value?: string | null;
};
export type WebApiResponseGetRoleClaimsResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  claims?: WebApiDtoClaimDto[] | null;
};
export type WebApiRequestAddUserClaimRequest = {
  userName?: string | null;
  claimType?: string | null;
  claimValue?: string | null;
};
export type WebApiRequestDeleteUserClaimRequest = {
  userName?: string | null;
  claimType?: string | null;
  claimValue?: string | null;
};
export type ApplicationDtoUserReduceInfo = {
  id?: string;
  userName?: string | null;
};
export type ApplicationDtoRoleWithUsers = {
  id?: string;
  name?: string | null;
  users?: ApplicationDtoUserReduceInfo[] | null;
};
export type WebApiResponseGetRoleResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  role?: ApplicationDtoRoleWithUsers;
};
export type WebApiDtoRoleWithUsersDto = {
  id?: string;
  name?: string | null;
  users?: ApplicationDtoUserReduceInfo[] | null;
};
export type WebApiResponseGetRolesResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  roles?: WebApiDtoRoleWithUsersDto[] | null;
};
export type WebApiResponseAddRoleResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
};
export type WebApiRequestAddRoleRequest = {
  name?: string | null;
};
export type ApplicationIdentityEntityRole = {
  id?: string;
  name?: string | null;
  normalizedName?: string | null;
  concurrencyStamp?: string | null;
};
export type WebApiResponseUpdateRoleResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  role?: ApplicationIdentityEntityRole;
};
export type WebApiRequestUpdateRoleRequest = {
  id?: string;
  name?: string | null;
};
export type WebApiRequestAddRoleClaimRequest = {
  roleName?: string | null;
  claimType?: string | null;
  claimValue?: string | null;
};
export type WebApiRequestDeleteRoleClaimRequest = {
  roleName?: string | null;
  claimType?: string | null;
  claimValue?: string | null;
};
export type ApplicationModelsControllerRweRight = {
  controllerName?: string | null;
  read?: boolean;
  write?: boolean;
  execute?: boolean;
};
export type WebApiResponseGetRightsResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  rights?: ApplicationModelsControllerRweRight[] | null;
};
export type WebApiRequestSetUserRightsRequest = {
  userName?: string | null;
  controllerRights?: ApplicationModelsControllerRweRight[] | null;
};
export type WebApiDtoUserReduceInfoDto = {
  id?: string;
  userName?: string | null;
};
export type WebApiDtoGroupDto = {
  id?: string;
  name?: string | null;
  users?: WebApiDtoUserReduceInfoDto[] | null;
};
export type WebApiResponseGetGroupResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  group?: WebApiDtoGroupDto;
};
export type WebApiResponseGetGroupsResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  groups?: WebApiDtoGroupDto[] | null;
};
export type WebApiRequestAddGroupRequest = {
  name?: string | null;
};
export type ApplicationIdentityEntityUser = {
  id?: string;
  userName?: string | null;
  normalizedUserName?: string | null;
  email?: string | null;
  normalizedEmail?: string | null;
  emailConfirmed?: boolean;
  passwordHash?: string | null;
  securityStamp?: string | null;
  concurrencyStamp?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  twoFactorEnabled?: boolean;
  lockoutEnd?: string | null;
  lockoutEnabled?: boolean;
  accessFailedCount?: number;
  groups?: ApplicationIdentityEntityGroup[] | null;
  userLockoutEnd?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  isSystem?: boolean;
};
export type ApplicationIdentityEntityGroup = {
  id?: string;
  name?: string | null;
  users?: ApplicationIdentityEntityUser[] | null;
};
export type WebApiResponseUpdateGroupResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  group?: ApplicationIdentityEntityGroup;
};
export type WebApiRequestUpdateGroupRequest = {
  id?: string;
  name?: string | null;
};
export const {
  usePostApiIdentityUsersLoginMutation,
  usePostApiIdentityUsersRefreshTokensMutation,
  usePostApiIdentityUsersByLoginLockoutUserMutation,
  usePostApiIdentityUsersByLoginUnlockUserMutation,
  useGetApiIdentityUsersByUserNameQuery,
  useDeleteApiIdentityUsersByUserNameMutation,
  useGetApiIdentityUsersQuery,
  usePostApiIdentityUsersMutation,
  usePatchApiIdentityUsersMutation,
  usePostApiIdentityUserRolesMutation,
  usePutApiIdentityUserRolesMutation,
  useDeleteApiIdentityUserRolesMutation,
  useGetApiIdentityUserRolesByUserNameQuery,
  usePostApiIdentityUserGroupsMutation,
  useDeleteApiIdentityUserGroupsMutation,
  useGetApiIdentityUserClaimsByUserNameQuery,
  usePostApiIdentityUserClaimsMutation,
  useDeleteApiIdentityUserClaimsMutation,
  useGetApiIdentityRolesByRoleNameQuery,
  useDeleteApiIdentityRolesByRoleNameMutation,
  useGetApiIdentityRolesQuery,
  usePostApiIdentityRolesMutation,
  usePatchApiIdentityRolesMutation,
  useGetApiIdentityRoleClaimsByRoleNameQuery,
  usePostApiIdentityRoleClaimsMutation,
  useDeleteApiIdentityRoleClaimsMutation,
  useGetApiIdentityUserRightsByUserNameQuery,
  useGetApiIdentityRoleRightsByRoleNameQuery,
  usePostApiIdentityUserRightsMutation,
  useGetApiIdentityGroupsByGroupNameQuery,
  useGetApiIdentityGroupsQuery,
  usePostApiIdentityGroupsMutation,
  usePatchApiIdentityGroupsMutation,
  useDeleteApiIdentityGroupsMutation,
  useHeadApiIdentitySystemActionsLogOutAllUsersMutation,
  usePostApiIdentitySystemActionsGetSicretKeyMutation,
} = injectedRtkApi;
