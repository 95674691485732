import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { UiTextArea } from 'shared/ui/ui-kit';

import {
  FieldErrorContainer,
  FormItemContainer,
  FormItemLabel,
} from '../styles';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
} & React.ComponentProps<typeof UiTextArea>;

export function CustomTextarea<T extends FieldValues>({
  label,
  required = false,
  disabled,
  errorMessage,
  name,
  ...rest
}: Props<T>) {
  const { control } = useFormContext();

  return (
    <FormItemContainer>
      <FormItemLabel required={required}>{label}</FormItemLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <UiTextArea {...field} disabled={disabled} {...rest} />
        )}
      />
      <FieldErrorContainer>{errorMessage}</FieldErrorContainer>
    </FormItemContainer>
  );
}
