import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import {
  useGetUserGroup,
  useGetUserGroups,
} from 'shared/api/services/user/enhanced';

import { Form } from './form';

const pageParamsSchema = z.object({
  groupId: z.string(),
});

export function UpdateFormContainer() {
  const { groupId } = useTypedParams(pageParamsSchema);

  const {
    isFetching: isUserGroupFetching,
    error: userGroupError,
    data: userGroup,
  } = useGetUserGroup(groupId);

  const {
    isFetching: isUserGroupsFetching,
    error: userGroupsError,
    data: userGroups,
  } = useGetUserGroups();

  const error = [userGroupError, userGroupsError].find(
    (err) => err !== undefined
  );

  const isLoading = isUserGroupFetching || isUserGroupsFetching;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!userGroup?.data || !userGroups?.data) {
    return <EmptyData />;
  }

  return <Form userGroups={userGroups.data} userGroup={userGroup.data} />;
}
