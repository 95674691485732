import { HUB_ROUTES } from 'shared/consts/routes/hub';
import { SectionMenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <SectionMenuAddButton
      path={HUB_ROUTES.ADD_HUB}
      permissions={[
        {
          controller: 'Hub',
          right: 'Write',
        },
      ]}
    />
  );
}
