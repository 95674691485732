import { Cell, Header, Row, Table } from '@tanstack/react-table';

import { ClientPagination } from '../../pagination/client';
import { TableLayout } from '../table-layout';

type Props = {
  table: Table<any>;
  loading: boolean;
  handleRowDoubleClick?: (row: Row<any>) => void;
  tableRef?: React.MutableRefObject<null>;
};

export function ClientTableLayout({
  table,
  loading,
  handleRowDoubleClick,
  tableRef,
}: Props) {
  const getTdClassName = (cell: Cell<any, unknown>) => {
    return cell.column.getIsSorted() !== false ? 'sorted' : '';
  };

  const handleSortChange = (header: Header<any, unknown>) => {
    return header.column.getToggleSortingHandler();
  };

  return (
    <TableLayout
      table={table}
      loading={loading}
      getTdClassName={getTdClassName}
      handleSortChange={handleSortChange}
      handleRowDoubleClick={handleRowDoubleClick}
      tableRef={tableRef}
      tableControls={<ClientPagination table={table} />}
    />
  );
}
