import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { CardListTable } from 'features/user/cards-table';

const pageParamsSchema = z.object({
  userId: z.string(),
});

export function UserCardListPage() {
  const { userId } = useTypedParams(pageParamsSchema);

  return <CardListTable userId={userId} />;
}
