import { Route } from 'react-router-dom';

import {
  CHARGE_POINTS,
  EVENTS,
  STATUSES,
} from 'shared/consts/routes/charge-point';
import {
  ADD_PREFIX,
  GROUP,
  GROUPS,
  MANUAL_COMMANDS,
  RESERVES,
} from 'shared/consts/routes/common';
import { MainContentLayout, SectionLayout } from 'shared/ui/layout';

import { RequireAuth } from 'entities/authorization';

import {
  ChargePointCommandsPage,
  ChargePointEventsPage,
  ChargePointInfoPage,
  ChargePointListPage,
  ChargePointReservesPage,
  ChargePointsCommandsPage,
  ChargePointSessionLogPage,
  ChargePointsGroupCommandsPage,
  ChargePointsGroupListPage,
  ChargePointsGroupReservesPage,
  ChargePointsGroupsListPage,
  ChargePointsSessionLogPage,
  ChargePointStatusesPage,
} from 'pages/charge-point';

import { ChargePointLayout } from 'entities/charge-point/ui/charge-point-layout';

import { ChargePointsMenu } from 'features/charge-point/menu';

import { EditChargePointGroupPage } from 'pages/charge-point-group/edit';
import { AddChargePointPage } from 'pages/charge-point/add';
import { EditChargePointPage } from 'pages/charge-point/edit';
import { AddChargePointGroupPage } from 'pages/charge-point-group/add';
import { ChargePointGroupSessionsPage } from 'pages/charge-point-group/sessions';
import { SESSIONS } from 'shared/consts/names';
import {
  ChargePointsGroupLayout,
  ChargePointsLayout,
} from 'entities/charge-point';
import { ChargePointGroupLayout } from 'entities/charge-point-group';
import { ChargePointsReserveSessionsPage } from 'pages/charge-point/charge-points-reserve-sessions';

export const ChargePointRoutes = (
  <Route
    element={<RequireAuth controllerName="ChargePoint" rightType="Read" />}
  >
    <Route
      path={CHARGE_POINTS}
      element={<SectionLayout menu={<ChargePointsMenu />} />}
    >
      <Route element={<ChargePointsLayout />}>
        <Route index element={<ChargePointListPage />} />
        <Route
          element={<RequireAuth controllerName="Group" rightType="Write" />}
        >
          <Route path={GROUPS} element={<ChargePointsGroupsListPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={SESSIONS} element={<ChargePointsSessionLogPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route
            path={RESERVES}
            element={<ChargePointsReserveSessionsPage />}
          />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route
            path={MANUAL_COMMANDS}
            element={<ChargePointsCommandsPage />}
          />
        </Route>
      </Route>

      <Route
        element={<RequireAuth controllerName="ChargePoint" rightType="Write" />}
      >
        <Route path={ADD_PREFIX} element={<AddChargePointPage />} />
      </Route>

      <Route element={<RequireAuth controllerName="Group" rightType="Write" />}>
        <Route path={`groups/:groupId`} element={<ChargePointGroupLayout />}>
          <Route index element={<EditChargePointGroupPage />} />
        </Route>
      </Route>

      <Route element={<RequireAuth controllerName="Group" rightType="Write" />}>
        <Route
          path={`${GROUPS}/${ADD_PREFIX}`}
          element={<MainContentLayout element={<AddChargePointGroupPage />} />}
        />
      </Route>

      <Route element={<RequireAuth controllerName="Group" rightType="Read" />}>
        <Route path={GROUP} element={<ChargePointsGroupLayout />}>
          <Route index element={<ChargePointsGroupListPage />} />
          <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route path={SESSIONS} element={<ChargePointGroupSessionsPage />} />
          </Route>
          {/* <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route
              path={RESERVES}
              element={<ChargePointsGroupReservesPage />}
            />
          </Route> */}
          <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route
              path={MANUAL_COMMANDS}
              element={<ChargePointsGroupCommandsPage />}
            />
          </Route>
        </Route>
      </Route>

      {/* Рауты отдельной ЭЗС */}
      <Route path={':groupId/:chargePointId'} element={<ChargePointLayout />}>
        <Route index element={<ChargePointInfoPage />} />
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={SESSIONS} element={<ChargePointSessionLogPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={RESERVES} element={<ChargePointReservesPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={EVENTS} element={<ChargePointEventsPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={MANUAL_COMMANDS} element={<ChargePointCommandsPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={STATUSES} element={<ChargePointStatusesPage />} />
        </Route>
      </Route>

      <Route
        element={<RequireAuth controllerName="ChargePoint" rightType="Write" />}
      >
        <Route
          path=":groupId/:chargePointId/edit"
          element={<EditChargePointPage />}
        />
      </Route>
    </Route>
  </Route>
);
