import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';
import { ConnectorVm } from 'shared/api/services/chargepoint/generated';

import { Form } from '../ui/form';

type Props = {
  connector: ConnectorVm;
  chargePointId: string;
};

export const Modal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      title="Редактировать коннектор"
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <Form {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
