import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { DateTimeRangePickerFilter, InputFilter } from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import {
  selectTableColumns,
  setTableColumns,
} from 'shared/redux/slices/reserve-sessions/slice';
import { GetSessionResponse } from 'shared/api/services/reserve/generated';

const columnHelper = createColumnHelper<GetSessionResponse>();

const DATA_COLUMNS = [
  columnHelper.accessor('guidTargetReserve', {
    id: 'guidTargetReserve',
    header: 'ID объекта резерва',
    meta: {
      filterElement: <InputFilter paramName="guidTargetReserve" />,
    },
  }),
  columnHelper.accessor('userId', {
    id: 'userId',
    header: 'ID пользователя',
    meta: {
      filterElement: <InputFilter paramName="userId" />,
    },
  }),
  columnHelper.accessor('dateFromReserve', {
    id: 'dateFromReserve',
    header: 'Начало резерва',
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="beginDateFrom"
          dateToName="beginDateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('dateToReserve', {
    id: 'dateToReserve',
    header: 'Конец резерва',
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="endDateFrom"
          dateToName="endDateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('lastUpdate', {
    id: 'lastUpdate',
    header: 'Дата обновления',
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="lastUpdateFrom"
          dateToName="lastUpdateTo"
        />
      ),
    },
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useAppSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
