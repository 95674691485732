import { z } from 'zod';

import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';
import {
  useGetChargePoint,
  useGetChargePointGroups,
  useGetHubs,
  useGetOwners,
} from 'shared/api/services/chargepoint/enhanced';
import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { Form } from './form';

const pageParamsSchema = z.object({
  chargePointId: z.string(),
});

export function FormContainer() {
  const { chargePointId } = useTypedParams(pageParamsSchema);

  const {
    isFetching: isChargePointLoading,
    error: chargePointError,
    data: chargePoint,
  } = useGetChargePoint(chargePointId);

  const {
    data: owners,
    isFetching: isOwnersLoading,
    error: ownersError,
  } = useGetOwners({});

  const {
    data: hubs,
    isFetching: isHubsLoading,
    error: hubsError,
  } = useGetHubs({});

  const {
    data: chargePointGroups,
    isFetching: isChargePointGroupsLoading,
    error: chargePointGroupsError,
    currentData,
  } = useGetChargePointGroups({});

  const isLoading =
    isOwnersLoading ||
    isHubsLoading ||
    isChargePointGroupsLoading ||
    isChargePointLoading;

  const error = [
    ownersError,
    hubsError,
    chargePointGroupsError,
    chargePointError,
  ].find((err) => err !== undefined);

  const apiResponseError = [owners, hubs, chargePointGroups, chargePoint].find(
    (el) => el && el.status === 'ERROR'
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (
    !hubs?.data ||
    !owners?.data ||
    !chargePointGroups?.data ||
    !chargePoint?.data
  ) {
    return <EmptyData />;
  }

  return (
    <Form
      groups={chargePointGroups.data}
      hubs={hubs.data}
      owners={owners.data}
      chargePoint={chargePoint.data}
    />
  );
}
