import { Route } from 'react-router-dom';

import { ChapterLayout } from 'widgets/layout/chapter';
import { MonitoringSidebar } from 'features/sidebar';

import NotFound from '../../../pages/NotFound';

import { OwnerRoutes } from '../../../app/router/routes/owner';
import { MonitoringPage } from '../../../pages/MonitoringPage';
import { PushNotificationRoutes } from '../../../app/router/routes/push-notification';
import { HubRoutes } from '../../../app/router/routes/hub';

// TODO
import { PersonRoutes } from '../../../components/User/routes';
import { ChargePointRoutes } from '../../../components/ChargePoint/routes';

export const MonitoringRoutes = (
  <Route path="/" element={<ChapterLayout siderbar={<MonitoringSidebar />} />}>
    <Route index element={<MonitoringPage />} />
    {ChargePointRoutes}
    {HubRoutes}
    {OwnerRoutes}
    {PersonRoutes}
    {/* {PushNotificationRoutes} */}
    <Route path="*" element={<NotFound />} />
  </Route>
);
