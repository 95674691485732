/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * PaymentService.WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * @summary Принимает команду на оплату (списание) средств с карты пользователя.
Результат вернется асинхронно сообщением в очереди.
 */
export const postApiPaymentActionsPayHeader = zod.object({
  idempotencyKey: zod.string().uuid().optional(),
});

export const postApiPaymentActionsPayBody = zod.object({
  userId: zod.string().uuid(),
  billingId: zod.string().uuid(),
  countryId: zod.string().min(1),
  invoiceSum: zod.number(),
  phone: zod.string().min(1),
  email: zod.string().min(1),
});

export const postApiPaymentActionsPayResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Привязка банковской карты пользователя к лк.
 */
export const postApiPaymentActionsBindingBody = zod.object({
  userId: zod.string().uuid(),
  countryId: zod.string().min(1),
  phone: zod.string().min(1),
  email: zod.string().nullish(),
});

export const postApiPaymentActionsBindingResponse = zod.object({
  data: zod
    .object({
      orderNumber: zod.string().min(1),
      formUrl: zod.string().min(1),
      returnUrl: zod.string().min(1),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Отвязка банковской карты пользователя.
 */
export const postApiPaymentActionsUnbindBody = zod.object({
  cardId: zod.string().uuid(),
});

export const postApiPaymentActionsUnbindResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Холдирование средств с карты пользователя.
Результат будет выпущен асинхронно сообщением в очереди.
 */
export const postApiPaymentActionsHoldHeader = zod.object({
  idempotencyKey: zod.string().uuid().optional(),
});

export const postApiPaymentActionsHoldBody = zod.object({
  userId: zod.string().uuid(),
  billingId: zod.string().uuid(),
  countryId: zod.string().min(1),
  holdSum: zod.number(),
  phone: zod.string().min(1),
  email: zod.string().nullish(),
});

export const postApiPaymentActionsHoldResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Списание средств (заранее холдированных).
Результат будет выпущен асинхронно сообщением в очереди.
 */
export const postApiPaymentActionsDepositHeader = zod.object({
  idempotencyKey: zod.string().uuid().optional(),
});

export const postApiPaymentActionsDepositBody = zod.object({
  userId: zod.string().uuid(),
  billingId: zod.string().uuid(),
  debetSum: zod.number(),
});

export const postApiPaymentActionsDepositResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Отменяется ранее (последний) захолдированный платеж в полном объеме.
Результат вернется асинхронно сообщением в очереди.
 */
export const postApiPaymentActionsReverseHeader = zod.object({
  idempotencyKey: zod.string().uuid().optional(),
});

export const postApiPaymentActionsReverseBody = zod.object({
  userId: zod.string().uuid(),
  billingId: zod.string().uuid(),
});

export const postApiPaymentActionsReverseResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Установка "основной" карты пользователя.
 */
export const postApiPaymentActionsSelectcardBody = zod.object({
  cardId: zod.string().uuid(),
});

export const postApiPaymentActionsSelectcardResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const getApiPaymentAppInfoResponse = zod.string();

/**
 * @summary Получить банк по идентификатору.
 */
export const getApiPaymentBanksIdParams = zod.object({
  id: zod.string(),
});

export const getApiPaymentBanksIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      countryId: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Обновить банк.
 */
export const patchApiPaymentBanksIdParams = zod.object({
  id: zod.string(),
});

export const patchApiPaymentBanksIdBody = zod.object({
  name: zod.string().nullish(),
});

export const patchApiPaymentBanksIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      countryId: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удалить банк.
 */
export const deleteApiPaymentBanksIdParams = zod.object({
  id: zod.string(),
});

export const deleteApiPaymentBanksIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить банки по фильтру.
 */
export const getApiPaymentBanksQueryParams = zod.object({
  requestDto: zod.object({}).optional(),
});

export const getApiPaymentBanksResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().min(1),
        name: zod.string().min(1),
        countryId: zod.string().min(1),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создать банк.
 */
export const postApiPaymentBanksBody = zod.object({
  name: zod.string().min(1),
  countryId: zod.string().min(1),
});

export const postApiPaymentBanksResponse = zod.object({
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      countryId: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить запрос на привязку банковской карты по идентификатору.
 */
export const getApiPaymentBindingLogsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiPaymentBindingLogsIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      bankId: zod.string().min(1),
      status: zod.enum([
        'NEW',
        'IN_PROCESS',
        'PAID',
        'ERROR',
        'HOLD',
        'DEPOSITED',
        'REVERSE',
        'ERROR_PAYMENT',
        'UNBINDED',
      ]),
      orderNumber: zod.string().min(1),
      bankOrderId: zod.string().nullish(),
      formUrl: zod.string().nullish(),
      createdDate: zod.string().datetime(),
      errorReason: zod
        .enum([
          'UNDEFINED',
          'NOT_ENOUGH_MONEY',
          'PAY_SERVICE_ERROR',
          'BANK_SERVICE_IS_UNAVAILABLE',
          'TIMEOUT_ERROR',
          'UNKNOWN_ERROR',
        ])
        .optional(),
      errorText: zod.string().nullish(),
      responseBank: zod.string().nullish(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить запросы на привязку банковских карт по фильтру.
 */
export const getApiPaymentBindingLogsQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  Status: zod
    .enum([
      'NEW',
      'IN_PROCESS',
      'PAID',
      'ERROR',
      'HOLD',
      'DEPOSITED',
      'REVERSE',
      'ERROR_PAYMENT',
      'UNBINDED',
    ])
    .optional(),
  OrderNumber: zod.string().optional(),
  BankOrderId: zod.string().optional(),
  ErrorReason: zod
    .enum([
      'UNDEFINED',
      'NOT_ENOUGH_MONEY',
      'PAY_SERVICE_ERROR',
      'BANK_SERVICE_IS_UNAVAILABLE',
      'TIMEOUT_ERROR',
      'UNKNOWN_ERROR',
    ])
    .optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiPaymentBindingLogsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userId: zod.string().uuid(),
        bankId: zod.string().min(1),
        status: zod.enum([
          'NEW',
          'IN_PROCESS',
          'PAID',
          'ERROR',
          'HOLD',
          'DEPOSITED',
          'REVERSE',
          'ERROR_PAYMENT',
          'UNBINDED',
        ]),
        orderNumber: zod.string().min(1),
        bankOrderId: zod.string().nullish(),
        formUrl: zod.string().nullish(),
        createdDate: zod.string().datetime(),
        errorReason: zod
          .enum([
            'UNDEFINED',
            'NOT_ENOUGH_MONEY',
            'PAY_SERVICE_ERROR',
            'BANK_SERVICE_IS_UNAVAILABLE',
            'TIMEOUT_ERROR',
            'UNKNOWN_ERROR',
          ])
          .optional(),
        errorText: zod.string().nullish(),
        responseBank: zod.string().nullish(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить количество запросов на привязку банковских карт по фильтру.
 */
export const getApiPaymentBindingLogsCountQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  Status: zod
    .enum([
      'NEW',
      'IN_PROCESS',
      'PAID',
      'ERROR',
      'HOLD',
      'DEPOSITED',
      'REVERSE',
      'ERROR_PAYMENT',
      'UNBINDED',
    ])
    .optional(),
  OrderNumber: zod.string().optional(),
  BankOrderId: zod.string().optional(),
  ErrorReason: zod
    .enum([
      'UNDEFINED',
      'NOT_ENOUGH_MONEY',
      'PAY_SERVICE_ERROR',
      'BANK_SERVICE_IS_UNAVAILABLE',
      'TIMEOUT_ERROR',
      'UNKNOWN_ERROR',
    ])
    .optional(),
});

export const getApiPaymentBindingLogsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить банковскую карту по идентификатору.
 */
export const getApiPaymentCardsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiPaymentCardsIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      bindingId: zod.string().min(1),
      maskedPan: zod.string().min(1),
      expirationString: zod.string().min(1),
      expirationDate: zod.string().datetime(),
      isSelected: zod.boolean(),
      createdDate: zod.string().datetime(),
      bank: zod.object({
        id: zod.string().min(1),
        name: zod.string().min(1),
        countryId: zod.string().min(1),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      }),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить банковские карты по фильтру.
 */
export const getApiPaymentCardsQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  BindingId: zod.string().optional(),
  IsSelected: zod.boolean().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiPaymentCardsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userId: zod.string().uuid(),
        bindingId: zod.string().min(1),
        maskedPan: zod.string().min(1),
        expirationString: zod.string().min(1),
        expirationDate: zod.string().datetime(),
        isSelected: zod.boolean(),
        createdDate: zod.string().datetime(),
        bank: zod.object({
          id: zod.string().min(1),
          name: zod.string().min(1),
          countryId: zod.string().min(1),
          createdDate: zod.string().datetime(),
          lastUpdate: zod.string().datetime(),
        }),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить количество банковских карт по фильтру.
 */
export const getApiPaymentCardsCountQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  BindingId: zod.string().optional(),
  IsSelected: zod.boolean().optional(),
});

export const getApiPaymentCardsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить оплату по идентификатору.
 */
export const getApiPaymentPaymentsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiPaymentPaymentsIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      billingId: zod.string().uuid(),
      userId: zod.string().uuid(),
      bankId: zod.string().min(1),
      cardId: zod.string().uuid(),
      operation: zod.enum(['PAYMENT', 'HOLDING']),
      orderNumber: zod.string().min(1),
      bankOrderId: zod.string().nullish(),
      invoiceSum: zod.number(),
      holdSum: zod.number().nullish(),
      description: zod.string().nullish(),
      status: zod.enum([
        'NEW',
        'IN_PROCESS',
        'PAID',
        'ERROR',
        'HOLD',
        'DEPOSITED',
        'REVERSE',
        'ERROR_PAYMENT',
        'UNBINDED',
      ]),
      errorReason: zod
        .enum([
          'UNDEFINED',
          'NOT_ENOUGH_MONEY',
          'PAY_SERVICE_ERROR',
          'BANK_SERVICE_IS_UNAVAILABLE',
          'TIMEOUT_ERROR',
          'UNKNOWN_ERROR',
        ])
        .optional(),
      errorText: zod.string().nullish(),
      responseBank: zod.string().nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      idempotencyKey: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить оплаты по фильтру.
 */
export const getApiPaymentPaymentsQueryParams = zod.object({
  BillingId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  CardId: zod.string().uuid().optional(),
  OrderNumber: zod.string().optional(),
  BankOrderId: zod.string().optional(),
  Status: zod
    .enum([
      'NEW',
      'IN_PROCESS',
      'PAID',
      'ERROR',
      'HOLD',
      'DEPOSITED',
      'REVERSE',
      'ERROR_PAYMENT',
      'UNBINDED',
    ])
    .optional(),
  ErrorReason: zod
    .enum([
      'UNDEFINED',
      'NOT_ENOUGH_MONEY',
      'PAY_SERVICE_ERROR',
      'BANK_SERVICE_IS_UNAVAILABLE',
      'TIMEOUT_ERROR',
      'UNKNOWN_ERROR',
    ])
    .optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiPaymentPaymentsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        billingId: zod.string().uuid(),
        userId: zod.string().uuid(),
        bankId: zod.string().min(1),
        cardId: zod.string().uuid(),
        operation: zod.enum(['PAYMENT', 'HOLDING']),
        orderNumber: zod.string().min(1),
        bankOrderId: zod.string().nullish(),
        invoiceSum: zod.number(),
        holdSum: zod.number().nullish(),
        description: zod.string().nullish(),
        status: zod.enum([
          'NEW',
          'IN_PROCESS',
          'PAID',
          'ERROR',
          'HOLD',
          'DEPOSITED',
          'REVERSE',
          'ERROR_PAYMENT',
          'UNBINDED',
        ]),
        errorReason: zod
          .enum([
            'UNDEFINED',
            'NOT_ENOUGH_MONEY',
            'PAY_SERVICE_ERROR',
            'BANK_SERVICE_IS_UNAVAILABLE',
            'TIMEOUT_ERROR',
            'UNKNOWN_ERROR',
          ])
          .optional(),
        errorText: zod.string().nullish(),
        responseBank: zod.string().nullish(),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
        idempotencyKey: zod.string().uuid(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить количество оплат по фильтру.
 */
export const getApiPaymentPaymentsCountQueryParams = zod.object({
  BillingId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  BankId: zod.string().optional(),
  CardId: zod.string().uuid().optional(),
  OrderNumber: zod.string().optional(),
  BankOrderId: zod.string().optional(),
  Status: zod
    .enum([
      'NEW',
      'IN_PROCESS',
      'PAID',
      'ERROR',
      'HOLD',
      'DEPOSITED',
      'REVERSE',
      'ERROR_PAYMENT',
      'UNBINDED',
    ])
    .optional(),
  ErrorReason: zod
    .enum([
      'UNDEFINED',
      'NOT_ENOUGH_MONEY',
      'PAY_SERVICE_ERROR',
      'BANK_SERVICE_IS_UNAVAILABLE',
      'TIMEOUT_ERROR',
      'UNKNOWN_ERROR',
    ])
    .optional(),
});

export const getApiPaymentPaymentsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});
