import {
  useGetCarBrands,
  useGetCarModels,
} from 'shared/api/services/information/rtk';
import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { TableInstance } from './table';

type Props = {
  carBrandId?: string;
};

export function TableContainer({ carBrandId }: Props) {
  const {
    data: carModels,
    error: carModelsError,
    isLoading: isCarModelsLoading,
    isFetching: isCarModelsFetching,
  } = useGetCarModels();

  const {
    data: carBrands,
    error: carBrandsError,
    isLoading: isCarBrandsLoading,
    isFetching: isCarBrandsFetching,
  } = useGetCarBrands();

  // Метод пока не принимает carBrandId - фильтр

  const isLoading = isCarModelsLoading || isCarBrandsLoading;
  const isFetching = isCarModelsFetching || isCarBrandsFetching;

  const apiResponseError = [carModels, carBrands].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [carModelsError, carBrandsError].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!carModels?.data || !carBrands?.data) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      data={
        carBrandId
          ? carModels.data.filter((el) => el.carBrandId === carBrandId)
          : carModels.data
      }
      loading={isFetching}
      carBrands={carBrands.data || []}
    />
  );
}
