import { GetCarBrandVm } from 'shared/api/services/information/generated';

import {
  PhotoPreview,
  PhotoItemContainer,
  PhotoInfoContainer,
  CarBrandLogoTitle,
} from './styles';

type Props = {
  carBrand: GetCarBrandVm;
};

export function CarBrandLogo({ carBrand }: Props) {
  const { logoUrl } = carBrand;

  if (!logoUrl) {
    return null;
  }

  return (
    <>
      <CarBrandLogoTitle>Текущий логотип</CarBrandLogoTitle>
      <PhotoItemContainer>
        <PhotoInfoContainer>
          <PhotoPreview src={`http://${logoUrl}`} />
        </PhotoInfoContainer>
      </PhotoItemContainer>
    </>
  );
}
