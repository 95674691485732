export interface AuthState {
  accessToken: string;
  refreshToken?: string;
}

export const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
};

export type LoginResponse = ResponseBase & {
  accessToken: string;
  refreshToken: string;
  firstName: string;
  lastName: string;
  middleName: string;
};
