import { Cell, Header, Row, Table } from '@tanstack/react-table';

import { useQueryParamsSorting } from 'shared/ui/table-new/hooks/use-query-params-sorting';

import { ServerPagination } from '../../pagination/server';
import { TableLayout } from '../table-layout';

type Props = {
  table: Table<any>;
  loading: boolean;
  handleRowDoubleClick?: (row: Row<any>) => void;
  tableRef?: React.MutableRefObject<null>;
  exportButton?: JSX.Element;
  totalCount: number;
};

export function ServerTableLayout({
  table,
  loading,
  handleRowDoubleClick,
  tableRef,
  exportButton,
  totalCount,
}: Props) {
  const { orderingField, orderingType, setSorting } = useQueryParamsSorting();

  const getTdClassName = (cell: Cell<any, unknown>) => {
    return orderingField === cell.column.id && orderingType !== null
      ? 'sorted'
      : '';
  };

  const handleSortChange = (header: Header<any, unknown>) => {
    return () => setSorting(header.id);
  };

  return (
    <TableLayout
      table={table}
      loading={loading}
      getTdClassName={getTdClassName}
      handleSortChange={handleSortChange}
      handleRowDoubleClick={handleRowDoubleClick}
      tableRef={tableRef}
      tableControls={
        <>
          <ServerPagination table={table} totalCount={totalCount} />
          {exportButton}
        </>
      }
    />
  );
}
