import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledStatusBadge = styled.div<{ bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px 3px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR.white};
  background-color: ${(props) => props.bgColor};
  backdrop-filter: blur(0.5px);
  border-radius: 20px;
  height: 20px;
`;

export const IconContainer = styled.div`
  height: 15px;
`;

export const StatusLabel = styled.p``;
