import { CardContent, CardHeader, StyledCard, Title } from './styles';

type Props = {
  addConnectorSlot: React.ReactNode;
  connectorListSlot: React.ReactNode;
};

export function Layout({ addConnectorSlot, connectorListSlot }: Props) {
  return (
    <StyledCard>
      <CardHeader>
        <Title>Коннекторы</Title>
        {addConnectorSlot}
      </CardHeader>
      <CardContent>{connectorListSlot}</CardContent>
    </StyledCard>
  );
}
