import { z } from 'zod';

import { EmployeesBalancePeriod } from 'shared/api/services/company/generated';
import { nonEmptyString } from 'shared/lib/schema';

const balanceRefreshPeriod: z.ZodType<EmployeesBalancePeriod> = z.enum([
  'FIRST_DAY_MONTH',
]);

export const FormSchema = z.object({
  name: nonEmptyString,
  legalName: nonEmptyString,
  email: nonEmptyString,
  contractData: nonEmptyString,
  address: nonEmptyString,
  phoneNumber: nonEmptyString,
  logoUrl: nonEmptyString,
  inn: nonEmptyString,
  kpp: nonEmptyString,
  // coerce
  companyBalanceForPeriod: z.number().optional(),
  employeeBalanceForPeriod: z.number().optional(),
  balanceRefreshPeriod: balanceRefreshPeriod.optional(),
  isBalanceRefreshLocked: z.boolean(),
  isDefault: z.boolean(),
});

export type FormSchemaType = z.infer<typeof FormSchema>;
