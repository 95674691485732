import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Col } from 'antd';

import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomSelect } from 'shared/ui/form/custom-select';
import {
  GetGroupVm,
  GetOwnerVm,
} from 'shared/api/services/chargepoint/generated';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';

import {
  CHARGE_POINT_PROTOCOL,
  CHARGE_POINT_SPEED_TYPE,
  CHARGE_POINT_STATUS,
} from 'entities/charge-point/consts';

import {
  TechInfoFormSchema as FormSchema,
  TechInfoFormInput as FormInput,
  TechInfoFormOutput as FormOutput,
} from '../../consts/schema';
import { FormRow } from '../../../../../common-styles';

type Props = {
  owners: GetOwnerVm[];
  groups: GetGroupVm[];
  submit: (data: FormOutput) => void;
  data: FormInput;
};

export function Form({ owners, groups, data, submit }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...data },
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    submit(data);
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <Form.Fields owners={owners} groups={groups} />
        <Form.Buttons />
      </form>
    </FormProvider>
  );
}

type FieldsProps = {
  owners: GetOwnerVm[];
  groups: GetGroupVm[];
};

Form.Fields = function Fields({ owners, groups }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="name"
            label="Название"
            errorMessage={errors.name?.message}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="chargeBoxIdentity"
            label="Charge Point identity"
            errorMessage={errors.chargeBoxIdentity?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="ownerId"
            label="Владелец"
            options={owners.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            allowClear
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="groupId"
            label="Группа ЭЗС"
            options={groups.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            allowClear
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="city"
            label="Город"
            errorMessage={errors.city?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="address"
            label="Адрес"
            errorMessage={errors.address?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="manufacturer"
            label="Производитель"
            errorMessage={errors.manufacturer?.message}
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="maxPower"
            label="Максимальная мощность"
            errorMessage={errors.maxPower?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="speedType"
            label="Тип ЭЗС"
            options={Object.entries(CHARGE_POINT_SPEED_TYPE).map((entry) => {
              const [key, label] = entry;

              return {
                value: key,
                label,
              };
            })}
            required
          />
        </Col>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="status"
            label="Статус"
            options={Object.entries(CHARGE_POINT_STATUS).map((entry) => {
              const [key, label] = entry;

              return {
                value: key,
                label,
              };
            })}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomCheckbox<FormInput>
            name="multiconnectorSupport"
            label="Поддержка мультиконнектора"
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="chargePointProtocol"
            label="Протокол ЭЗС"
            options={Object.entries(CHARGE_POINT_PROTOCOL).map((entry) => {
              const [key, label] = entry;

              return {
                value: key,
                label,
              };
            })}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="ipAddress"
            label="IP-адрес"
            errorMessage={errors.ipAddress?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="simNumber"
            label="Номер SIM"
            errorMessage={errors.simNumber?.message}
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="commentary"
            label="Комментарий"
            errorMessage={errors.commentary?.message}
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
