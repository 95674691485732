import { z } from 'zod';

import { nonEmptyString } from 'shared/lib/schema';

export const FormSchema = z.object({
  name: nonEmptyString,
  description: nonEmptyString,
  isDefault: z.boolean(),
});

export type FormSchemaType = z.infer<typeof FormSchema>;
