import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetUserGroups } from 'shared/api/services/user/enhanced';

import { Form } from './form';

export function FormContainer() {
  // @ts-ignore
  const { isFetching, error, data } = useGetUserGroups();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data?.data) {
    return <EmptyData />;
  }

  return <Form userGroups={data.data} />;
}
