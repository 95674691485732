import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetIdentityGroup } from 'shared/api/services/identity/rtk/enhanced';
import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { TableInstance } from './table';

const pageParamsSchema = z.object({
  groupName: z.string(),
});

export function TableContainer() {
  const { groupName } = useTypedParams(pageParamsSchema);

  const identityGroupQuery = useGetIdentityGroup(groupName);

  const isLoading = identityGroupQuery.isLoading;
  const isFetching = identityGroupQuery.isFetching;

  const apiResponseError = [identityGroupQuery.data].find(
    (el) => el && el.status === 'Error'
  );

  const error = [identityGroupQuery.error].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!identityGroupQuery.data?.group) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={identityGroupQuery.data?.group.users || []}
    />
  );
}
