import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import {
  useGetUser,
  useGetUserGroups,
} from 'shared/api/services/user/enhanced';
import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { Form } from './form/form';
import { useGetCountries } from 'shared/api/services/information/rtk';

const pageParamsSchema = z.object({
  userId: z.string(),
});

export function FormContainer() {
  const { userId } = useTypedParams(pageParamsSchema);

  const {
    data: user,
    isFetching: isUserLoading,
    error: userError,
  } = useGetUser(userId);

  const {
    data: countries,
    isLoading: isCountriesLoading,
    error: countriesError,
  } = useGetCountries();

  const {
    data: userGroups,
    isLoading: isUserGroupsLoading,
    error: userGroupsError,
    // @ts-ignore
  } = useGetUserGroups();

  const isLoading = isCountriesLoading || isUserGroupsLoading || isUserLoading;

  const error = [countriesError, userGroupsError, userError].find(
    (err) => err !== undefined
  );

  const apiResponseError = [user, countries, userGroups].find(
    (el) => el && el.status === 'ERROR'
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (!userGroups?.data || !countries?.data || !user?.data) {
    return <EmptyData />;
  }

  return (
    <Form
      countries={countries.data}
      userGroups={userGroups.data}
      user={user.data}
    />
  );
}
