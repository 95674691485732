import { createColumnHelper } from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { tryParseJSONObject } from 'shared/lib';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { GetOwnerVm } from 'shared/api/services/chargepoint/generated';
import {
  selectTableColumns,
  setTableColumns,
} from 'shared/redux/slices/owners/slice';

import { Actions } from './actions';
import { useAppDispatch } from 'shared/redux/types';

const columnHelper = createColumnHelper<GetOwnerVm>();

const DATA_COLUMNS = [
  columnHelper.accessor('id', {
    id: 'id',
    header: 'Id',
    size: 100,
    minSize: 100,
    maxSize: 100,
    enableSorting: false,
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Наименование',
    size: 200,
    minSize: 200,
    maxSize: 200,
    enableSorting: false,
  }),
  columnHelper.accessor('legalName', {
    id: 'legalName',
    header: 'Юридическое наименование',
    size: 200,
    minSize: 200,
    maxSize: 200,
    enableSorting: false,
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: 'Описание',
    size: 225,
    minSize: 225,
    maxSize: 225,
    enableSorting: false,
  }),
  // TODO: тариф (когда на бэке будет)
  columnHelper.accessor('phoneNumber', {
    id: 'phoneNumber',
    header: 'Телефон',
    size: 120,
    minSize: 120,
    maxSize: 120,
    enableSorting: false,
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: 'Email',
    // TODO: уточнить про список
    cell: (props) => {
      const email = props.row.original.email;

      if (!email) return '';

      const parsedEmail = tryParseJSONObject(email);

      return parsedEmail ? parsedEmail.join(', ') : '';
    },
    size: 150,
    minSize: 150,
    maxSize: 150,
    enableSorting: false,
  }),
  columnHelper.accessor('kpp', {
    id: 'kpp',
    header: 'КПП',
    size: 120,
    minSize: 120,
    maxSize: 120,
    enableSorting: false,
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      const owner = props.row.original;

      return <Actions owner={owner} />;
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
