import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import {
  useGetChargePointGroup,
  useGetChargePointGroups,
} from 'shared/api/services/chargepoint/enhanced';

import { Form } from './form';
import { useGetMobileApps } from 'shared/api/services/information/rtk';

const pageParamsSchema = z.object({
  groupId: z.string(),
});

export function FormContainer() {
  const { groupId } = useTypedParams(pageParamsSchema);

  const {
    isFetching: isGroupFetching,
    error: groupError,
    data: group,
  } = useGetChargePointGroup(groupId);

  const {
    isFetching: isGroupsFetching,
    error: groupsError,
    data: groups,
  } = useGetChargePointGroups({});

  const {
    data: mobileApps,
    error: mobileAppsError,
    isFetching: isMobileAppsFetching,
  } = useGetMobileApps();

  const error = [groupError, groupsError, mobileAppsError].find(
    (err) => err !== undefined
  );

  const isLoading = isGroupFetching || isGroupsFetching || isMobileAppsFetching;

  const apiResponseError = [groups, mobileApps, group].find(
    (el) => el && el.status == 'ERROR'
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (!group?.data || !groups?.data || !mobileApps?.data) {
    return <EmptyData />;
  }

  return (
    <Form
      groups={groups.data}
      group={group.data}
      mobileApps={mobileApps.data}
    />
  );
}
