import { ROOT } from 'shared/consts/names';
import { CHARGE_POINTS } from 'shared/consts/routes/charge-point';
import { OWNERS } from 'shared/consts/routes/owner';
import { useAbility } from 'shared/lib/ability/context';
import { CustomLink } from 'shared/ui';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: (
      <CustomLink to="" key={OWNERS}>
        Список владельцев
      </CustomLink>
    ),
  },
  {
    key: CHARGE_POINTS,
    label: (
      <CustomLink to={CHARGE_POINTS} key={CHARGE_POINTS}>
        Список ЭЗС
      </CustomLink>
    ),
  },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
