import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import {
  selectTableColumns,
  setTableColumns,
} from 'shared/redux/slices/identity-group-users/slice';
import { WebApiDtoUserReduceInfoDto } from 'shared/api/services/identity/generated';

const columnHelper = createColumnHelper<WebApiDtoUserReduceInfoDto>();

const DATA_COLUMNS = [
  columnHelper.accessor('userName', {
    id: 'userName',
    header: 'Логин',
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useAppSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return null;
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
