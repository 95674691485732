import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { useGetBank } from 'shared/api/services/payment/enhanced';
import { useGetCountries } from 'shared/api/services/information/rtk';

import { Form } from './form';

const pageParamsSchema = z.object({
  bankId: z.string(),
});

export function FormContainer() {
  const { bankId } = useTypedParams(pageParamsSchema);

  const {
    isFetching: isCountriesFetching,
    error: countriesError,
    data: countries,
  } = useGetCountries(undefined);

  const {
    data: bank,
    isFetching: isBankFetching,
    error: bankError,
  } = useGetBank(bankId);

  const isLoading = isCountriesFetching || isBankFetching;

  const apiResponseError = [countries, bank].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [countriesError, bankError].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!bank?.data || !countries?.data) {
    return <EmptyData />;
  }

  return <Form countries={countries.data} bank={bank.data} />;
}
