import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInformationCountriesById: build.query<
      GetApiInformationCountriesByIdApiResponse,
      GetApiInformationCountriesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Information/Countries/${queryArg}` }),
    }),
    patchApiInformationCountriesById: build.mutation<
      PatchApiInformationCountriesByIdApiResponse,
      PatchApiInformationCountriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/Countries/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateCountryRequest,
      }),
    }),
    deleteApiInformationCountriesById: build.mutation<
      DeleteApiInformationCountriesByIdApiResponse,
      DeleteApiInformationCountriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/Countries/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiInformationCountries: build.query<
      GetApiInformationCountriesApiResponse,
      GetApiInformationCountriesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/Countries`,
        params: { mobileAppIds: queryArg },
      }),
    }),
    postApiInformationCountries: build.mutation<
      PostApiInformationCountriesApiResponse,
      PostApiInformationCountriesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/Countries`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiInformationCountriesByIdApiResponse =
  /** status 200 Success */ GetCountryVmBaseResponse;
export type GetApiInformationCountriesByIdApiArg = /** Идентификатор */ string;
export type PatchApiInformationCountriesByIdApiResponse =
  /** status 200 Success */ UpdateCountryVmBaseResponse;
export type PatchApiInformationCountriesByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Новые параметры */
  updateCountryRequest: UpdateCountryRequest;
};
export type DeleteApiInformationCountriesByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiInformationCountriesByIdApiArg =
  /** Идентификатор */ string;
export type GetApiInformationCountriesApiResponse =
  /** status 200 Success */ GetCountryVmIReadOnlyCollectionBaseResponse;
export type GetApiInformationCountriesApiArg = string[];
export type PostApiInformationCountriesApiResponse =
  /** status 200 Success */ AddCountryVmBaseResponse;
export type PostApiInformationCountriesApiArg =
  /** Параметры */ AddCountryRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type Code = 'RU' | 'BY' | 'AM' | 'KZ' | 'KG';
export type Language = 'RU' | 'BY' | 'AM' | 'KZ' | 'KG';
export type GetCountryVm = {
  id: string;
  name: string;
  displayName: string;
  currencySymbol: string;
  currencyText: string;
  description?: string | null;
  code: Code;
  phonePrefix: string;
  urlFlag: string;
  language: Language;
  phoneMask: string;
  privacyPolicyUrl: string;
  isDefault: boolean;
  mobileAppIds: string[];
};
export type GetCountryVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCountryVm;
};
export type UpdateCountryVm = {
  id: string;
  name: string;
  displayName: string;
  currencySymbol: string;
  currencyText: string;
  description?: string | null;
  code: Code;
  phonePrefix: string;
  urlFlag: string;
  language: Language;
  phoneMask: string;
  privacyPolicyUrl: string;
  isDefault: boolean;
  mobileAppIds: string[];
};
export type UpdateCountryVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateCountryVm;
};
export type UpdateCountryRequest = {
  name?: string | null;
  displayName?: string | null;
  currencySymbol?: string | null;
  currencyText?: string | null;
  description?: string | null;
  code?: Code;
  phonePrefix?: string | null;
  urlFlag?: string | null;
  language?: Language;
  phoneMask?: string | null;
  privacyPolicyUrl?: string | null;
  isDefault?: boolean | null;
  mobileAppIds?: string[] | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetCountryVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCountryVm[] | null;
};
export type AddCountryVm = {
  id: string;
  name: string;
  displayName: string;
  currencySymbol: string;
  currencyText: string;
  description?: string | null;
  code: Code;
  phonePrefix: string;
  urlFlag: string;
  language: Language;
  phoneMask: string;
  privacyPolicyUrl: string;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
};
export type AddCountryVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddCountryVm;
};
export type AddCountryRequest = {
  id: string;
  name: string;
  displayName: string;
  currencySymbol: string;
  currencyText: string;
  description: string;
  code: Code;
  phonePrefix: string;
  urlFlag: string;
  language: Language;
  phoneMask: string;
  privacyPolicyUrl: string;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
};
export const {
  useGetApiInformationCountriesByIdQuery,
  usePatchApiInformationCountriesByIdMutation,
  useDeleteApiInformationCountriesByIdMutation,
  useGetApiInformationCountriesQuery,
  usePostApiInformationCountriesMutation,
} = injectedRtkApi;
