import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetIdentityGroup } from 'shared/api/services/identity/rtk/enhanced';

import { Form } from './form';

const pageParamsSchema = z.object({
  groupName: z.string(),
});

export function FormContainer() {
  const { groupName } = useTypedParams(pageParamsSchema);

  const groupQuery = useGetIdentityGroup(groupName);

  if (groupQuery.isFetching) {
    return <div>Loading...</div>;
  }

  if (groupQuery.error) {
    return <ErrorMessage text={getServerErrorText(groupQuery.error)} />;
  }

  if (!groupQuery.data?.group) {
    return <EmptyData />;
  }

  return <Form group={groupQuery.data.group} />;
}
