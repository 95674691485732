import React, { useMemo } from 'react';

import { AdvancedPropertiesCard } from 'entities/charge-point/ui/advanced-properties';

import { ConnectedChargedTotal } from 'features/charge-point/charged-total';

import { ConnectedChargePointPhotos } from 'widgets/charge-point/photos';
import { LocationCard } from 'widgets/charge-point/location/ui';

import {
  ChargedSummaryContainer,
  ChargePointInfoGridLayout,
  GridColumnContainer,
} from './styles';
import { GetChargePointVm } from 'shared/api/services/chargepoint/generated';

import { TechInfoCard } from 'entities/charge-point';

import { BaseInfoWidget } from 'widgets/charge-point/base-info';
import { ConnectorListWidget } from 'widgets/charge-point/connector-list';

type Props = {
  chargePoint: GetChargePointVm;
};

export function ChargePointInfo({ chargePoint }: Props) {
  const {
    id,
    name,
    speedType,
    // type,
    maxPower,
    address,
    connectors,
    chargePointProtocol,
    status,
    owner,
  } = chargePoint;

  return (
    <>
      <ChargePointInfoGridLayout>
        <BaseInfoWidget chargePoint={chargePoint} />
        <ConnectorListWidget chargePointId={id} connectors={connectors} />
        {/* <ChargedSummaryContainer>
          <ConnectedChargedTotal
            key={connectors.length}
            chargePointName={chargePointName}
          />
        </ChargedSummaryContainer> */}
        <GridColumnContainer className="grid-col-span-2">
          <TechInfoCard chargePoint={chargePoint} />
        </GridColumnContainer>
        <GridColumnContainer>
          <LocationCard chargePoint={chargePoint} />
          {/* <ConnectedChargePointPhotos chargePointId={id} /> */}
        </GridColumnContainer>
      </ChargePointInfoGridLayout>
    </>
  );
}

export default React.memo(ChargePointInfo);
