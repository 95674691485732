import { Button } from 'antd';
import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const LogoutButtonContainer = styled.div`
  position: relative;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 82px;
  background-color: ${COLOR.white};
  border-top: ${DEFAULT_BORDER} !important;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & p {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    color: ${COLOR.black};
    opacity: 0.5;
  }
`;

export const IconContainer = styled.div`
  width: 21px;
  height: 21px;
`;
