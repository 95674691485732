import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';
import { OWNER_ROUTES } from 'shared/consts/routes/owner';
import { useAbility } from 'shared/lib/ability/context';

export function AddOwnerLink() {
  const ability = useAbility();

  if (!ability.can('Write', 'Owner')) {
    return null;
  }

  return (
    <Link to={OWNER_ROUTES.ADD_OWNER}>
      <NavigationTabButton title="Добавить владельца" />
    </Link>
  );
}
