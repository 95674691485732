import { CustomLink } from 'shared/ui';

import { Label, IconContainer, MenuItemWrapper, StyledMenu } from './styles';
import { NavMenuItem } from './types';

type Props = {
  items: NavMenuItem[];
};

export function NavMenu({ items }: Props) {
  return (
    <StyledMenu>
      {items.map(({ icon, label, path }) => (
        <CustomLink to={path} key={path}>
          <MenuItemWrapper>
            <IconContainer>{icon}</IconContainer>
            <Label>{label}</Label>
          </MenuItemWrapper>
        </CustomLink>
      ))}
    </StyledMenu>
  );
}
