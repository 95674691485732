import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { COUNTRY_ROUTES } from 'shared/consts/routes/country';

import { SECTION } from 'entities/navigation';

import { AddButton } from './add-button';
import { useGetCountries } from 'shared/api/services/information/rtk';

const { label, path } = SECTION['countries'];

export function ConnectedCountriesMenu() {
  // @ts-ignore
  const { data, isFetching, error } = useGetCountries();

  // if (data && data.statusCode !== 0) {
  //   return <NavMenuError text={data.statusDescription} />;
  // }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = data?.data || [];

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={path}
      absolutePath={COUNTRY_ROUTES.COUNTRIES}
      label={label}
      addButton={<AddButton />}
    />
  );
}
