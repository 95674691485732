import { useGetIdentityGroups } from 'shared/api/services/identity/rtk';
import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { Form } from './form';

export function FormContainer() {
  const identityGroupsQuery = useGetIdentityGroups();

  const isLoading = identityGroupsQuery.isFetching;

  const apiResponseError = [identityGroupsQuery.data].find(
    (el) => el && el.status === 'Error'
  );

  const error = [identityGroupsQuery.error].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!identityGroupsQuery?.data?.groups) {
    return <EmptyData />;
  }

  return <Form groups={identityGroupsQuery.data.groups} />;
}
