import { z } from 'zod';

import { patchApiChargePointChargePointsIdBody } from 'shared/api/services/chargepoint/schemas';
import { LATITUDE, LONGITUDE, requiredNumberInString } from 'shared/lib/schema';

import {
  ADDRESS_MAX_LENGTH,
  chargePointProtocol,
  CITY_MIN_LENGTH,
  countryCode,
  speedType,
  status,
} from 'entities/charge-point';

export const FormSchema = patchApiChargePointChargePointsIdBody
  .omit({
    speedType: true,
    chargePointProtocol: true,
    status: true,
    countryCode: true,
    city: true,
    address: true,
    latitude: true,
    longitude: true,
    maxPower: true,
  })
  .extend({
    speedType: speedType.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    chargePointProtocol: chargePointProtocol.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    status: status.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    countryCode: countryCode.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    city: z.string().min(CITY_MIN_LENGTH),
    address: z.string().max(ADDRESS_MAX_LENGTH),
    latitude: LATITUDE,
    longitude: LONGITUDE,
    maxPower: requiredNumberInString.pipe(z.number().gte(0)),
  });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const TechInfoFormSchema = FormSchema.pick({
  name: true,
  chargeBoxIdentity: true,
  ownerId: true,
  groupId: true,
  city: true,
  address: true,
  manufacturer: true,
  maxPower: true,
  speedType: true,
  status: true,
  multiconnectorSupport: true,
  chargePointProtocol: true,
  ipAddress: true,
  simNumber: true,
  commentary: true,
});

export type TechInfoFormInput = z.input<typeof TechInfoFormSchema>;
export type TechInfoFormOutput = z.output<typeof TechInfoFormSchema>;

export const CardSettingsFormSchema = FormSchema.pick({
  latitude: true,
  longitude: true,
  isVisible: true,
  isTestCp: true,
  hubId: true,
  dipLinkCharby: true,
  userLocationCheckDistance: true,
  workingTime: true,
  publicDescription: true,
  warningText: true,
});

export type CardSettingsFormInput = z.input<typeof CardSettingsFormSchema>;
export type CardSettingsFormOutput = z.output<typeof CardSettingsFormSchema>;

export const OcppConfigFormSchema = FormSchema.pick({
  ocppConfig: true,
});

export type OcppConfigFormInput = z.input<typeof OcppConfigFormSchema>;
export type OcppConfigFormOutput = z.output<typeof OcppConfigFormSchema>;
