import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetIdentityRole } from 'shared/api/services/identity/rtk/enhanced';

import { Form } from './form';

const pageParamsSchema = z.object({
  roleName: z.string(),
});

export function FormContainer() {
  const { roleName } = useTypedParams(pageParamsSchema);

  const roleQuery = useGetIdentityRole(roleName);

  if (roleQuery.isFetching) {
    return <div>Loading...</div>;
  }

  if (roleQuery.error) {
    return <ErrorMessage text={getServerErrorText(roleQuery.error)} />;
  }

  if (!roleQuery.data?.role) {
    return <EmptyData />;
  }

  return <Form role={roleQuery.data.role} />;
}
