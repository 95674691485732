import { useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import { NavigationTabButton } from 'shared/ui';

import { ManualCommandModal } from 'features/manual-command';
import { useAbility } from 'shared/lib/ability/context';

export function OpenManualCommandButton() {
  const ability = useAbility();

  const { chargePointName, groupId } = useParams() as {
    chargePointName?: string;
    groupId: string;
  };

  if (!ability.can('Execute', 'PrivateMethods')) {
    return null;
  }

  const onClick = () => {
    NiceModal.show(ManualCommandModal, { chargePointName });
  };

  return <NavigationTabButton title="Добавить действие" onClick={onClick} />;
}
