import { useSelector } from 'react-redux';
import { useState } from 'react';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type {
  CheckboxOptionType,
  CheckboxValueType,
} from 'antd/es/checkbox/Group';

import {
  selectOwners,
  selectOwnerTypes,
  selectStatusTypes,
  setOwnerTypes,
} from '../../../../../redux/slices/filters/filtersSlice';
import { OWNER_TYPE } from '../../consts';
import { ConnectedChargePointFilters } from '../connected-filters';

import FiltersIcon from '../../assets/filters.svg';

import {
  FilterCheckbox,
  FiltersButton,
  FiltersCheckboxGroup,
  FiltersContainer,
  RedPoint,
} from './styles';
import { UiModal } from 'shared/ui';
import { useAppDispatch } from 'shared/redux/types';

const plainOptions: CheckboxOptionType[] = Object.keys(OWNER_TYPE).map(
  (el) => ({ value: el, label: OWNER_TYPE[el] })
);

export function ShorthandedFilters() {
  const ownerTypes = useSelector(selectOwnerTypes);
  const statusTypes = useSelector(selectStatusTypes);
  const owners = useSelector(selectOwners);

  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);

  const dispatch = useAppDispatch();

  const checkAll = plainOptions.length === ownerTypes.length;

  const indeterminate =
    ownerTypes.length > 0 && ownerTypes.length < plainOptions.length;

  const onChange = (list: CheckboxValueType[]) => {
    dispatch(setOwnerTypes(list));
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    dispatch(
      setOwnerTypes(e.target.checked ? plainOptions.map((el) => el.value) : [])
    );
  };

  const showRedPoint = ownerTypes.length || statusTypes.length || owners.length;

  const closeFiltersModal = () => setIsFiltersModalVisible(false);
  const openFiltersModal = () => setIsFiltersModalVisible(true);

  return (
    <>
      <UiModal visible={isFiltersModalVisible} onCancel={closeFiltersModal}>
        <ConnectedChargePointFilters closeModal={closeFiltersModal} />
      </UiModal>
      <FiltersContainer>
        <FilterCheckbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Все
        </FilterCheckbox>
        <FiltersCheckboxGroup
          options={plainOptions}
          value={ownerTypes}
          onChange={onChange}
        />
        <FiltersButton onClick={openFiltersModal}>
          <img src={FiltersIcon} />
          {showRedPoint ? <RedPoint /> : null}
        </FiltersButton>
      </FiltersContainer>
    </>
  );
}
