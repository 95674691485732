import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { useAddOwner } from 'shared/api/services/chargepoint/enhanced';

import { OWNER_TYPE } from 'entities/owner';

import {
  DEFAULT_VALUES,
  FormSchema,
  FormInput,
  FormOutput,
} from '../consts/schema';
import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';

import { CardFormWrapper, FormRow } from '../../../../common-styles';

export function Form() {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...DEFAULT_VALUES },
  });

  const [trigger, { isLoading }] = useAddOwner();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper title={FORM_TITLE}>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields />
            <Form.Buttons />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

Form.Fields = function Fields() {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="name"
            label="Наименование"
            errorMessage={errors.name?.message}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="email"
            label="Email"
            errorMessage={errors.email?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="type"
            label="Тип"
            errorMessage={errors.type?.message}
            options={Object.entries(OWNER_TYPE).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="phoneNumber"
            label="Телефон"
            errorMessage={errors.phoneNumber?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="description"
            label="Описание"
            errorMessage={errors.description?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="legalName"
            label="Юридическое наименование"
            errorMessage={errors.legalName?.message}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="directorName"
            label="ФИО руководителя"
            errorMessage={errors.directorName?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="inn"
            label="ИНН"
            errorMessage={errors.inn?.message}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="kpp"
            label="КПП"
            errorMessage={errors.kpp?.message}
            required
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="telegram"
            label="Телеграм"
            errorMessage={errors.telegram?.message}
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
