import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

// import { useChargePointFetchingContext } from 'entities/charge-point/charge-point-layout';

import { SessionListTable } from 'features/sessions/table';

const pageParamsSchema = z.object({
  chargePointId: z.string().optional(),
});

export function ChargePointSessionLogPage() {
  const { chargePointId } = useTypedParams(pageParamsSchema);

  // const { handleSetFetchingTab } = useChargePointFetchingContext();

  return <SessionListTable chargePointId={chargePointId} />;
}
