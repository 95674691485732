import React from 'react';

import { ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetChargePoints } from 'shared/api/services/chargepoint/enhanced';

import { ChargePointsLocations } from '../charge-points-locations';

export function ConnectedChargePointsLocations() {
  const { data, isFetching, error } = useGetChargePoints({});

  if (data && data.status === 'ERROR') {
    return <ErrorMessage text={data.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <ChargePointsLocations
      loading={isFetching}
      chargePoints={data?.data ?? []}
    />
  );
}

export default React.memo(ConnectedChargePointsLocations);
