import { enhancedApi as api } from '../generated/car-models';

const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiInformationCarModels: (endpoint) => {
      endpoint.providesTags = [{ type: 'CarModels', id: 'LIST' }];
    },
    getApiInformationCarModelsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'CarModels', id: arg },
      ];
    },
    postApiInformationCarModels: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'CarModels', id: 'LIST' }];
    },
    patchApiInformationCarModelsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'CarModels', id: 'LIST' },
        {
          type: 'CarModels',
          id: arg.id,
        },
      ];
    },
    deleteApiInformationCarModelsById: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'CarModels', id: 'LIST' }];
    },
  },
});

export const {
  useGetApiInformationCarModelsQuery: useGetCarModels,
  useGetApiInformationCarModelsByIdQuery: useGetCarModel,
  usePostApiInformationCarModelsMutation: useAddCarModel,
  usePatchApiInformationCarModelsByIdMutation: useUpdateCarModel,
  useDeleteApiInformationCarModelsByIdMutation: useDeleteCarModel,
} = enhancedApi;
