import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAddIdentityRole } from 'shared/api/services/identity/rtk/enhanced';
import { WebApiDtoGroupDto } from 'shared/api/services/identity/rtk/generated';
import { CustomSelect } from 'shared/ui/form/custom-select';

import {
  DEFAULT_VALUES,
  FormInput,
  FormOutput,
  FormSchema,
} from '../consts/schema';
import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';

import { CardFormWrapper, FormRow } from '../../../../common-styles';

type Props = {
  groups: WebApiDtoGroupDto[];
};

export function Form({ groups }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...DEFAULT_VALUES },
  });

  // Кастомный хук нужен
  const [trigger, { isLoading }] = useAddIdentityRole();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper title={FORM_TITLE}>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields groups={groups} />
            <Form.Buttons />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  groups: WebApiDtoGroupDto[];
};

Form.Fields = function Fields({ groups }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="userName"
            label="Логин"
            errorMessage={errors.userName?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="lastName"
            label="Фамилия"
            errorMessage={errors.lastName?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="firstName"
            label="Имя"
            errorMessage={errors.firstName?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="middleName"
            label="Отчество"
            errorMessage={errors.middleName?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="email"
            label="Email"
            errorMessage={errors.email?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="password"
            label="Пароль"
            type="password"
            autoComplete="new-password"
            errorMessage={errors.password?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="groupName"
            options={groups.map(({ id, name }) => ({
              value: name,
              label: name,
            }))}
            label="Группа"
            errorMessage={errors.groupName?.message}
            required
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
