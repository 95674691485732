import { useSelector } from 'react-redux';

import {
  selectOwnerIds,
  selectOwnerTypes,
  selectStatuses,
  selectWithTestCp,
} from 'shared/redux/slices/charge-points/slice';

import { ChargePointListTable } from 'features/charge-point/table';

export function ChargePointListWidget() {
  const ownerTypes = useSelector(selectOwnerTypes);
  const ownerIds = useSelector(selectOwnerIds);
  const statuses = useSelector(selectStatuses);
  const withTestCp = useSelector(selectWithTestCp);

  // TODO: типизировать status и ownerTypes потом

  return (
    <ChargePointListTable
      ownerTypes={ownerTypes.length ? ownerTypes : undefined}
      ownersId={ownerIds.length ? ownerIds : undefined}
      status={statuses.length ? statuses : undefined}
      withTestCp={withTestCp}
    />
  );
}
