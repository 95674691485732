import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Col } from 'antd';

import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { GetHubVm } from 'shared/api/services/chargepoint/generated';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';

import {
  CardSettingsFormSchema as FormSchema,
  CardSettingsFormInput as FormInput,
  CardSettingsFormOutput as FormOutput,
} from '../../consts/schema';

import { FormRow } from '../../../../../common-styles';

type Props = {
  hubs: GetHubVm[];
  submit: (data: FormOutput) => void;
  data: FormInput;
};

export function Form({ hubs, data, submit }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...data },
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    submit(data);
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <Form.Fields hubs={hubs} />
        <Form.Buttons />
      </form>
    </FormProvider>
  );
}

type FieldsProps = {
  hubs: GetHubVm[];
};

Form.Fields = function Fields({ hubs }: FieldsProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="latitude"
            label="Географическая широта"
            errorMessage={errors.latitude?.message}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="longitude"
            label="Географическая долгота"
            errorMessage={errors.longitude?.message}
            required
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomCheckbox<FormInput>
            name="isVisible"
            label="Статус ЭЗС на карте"
          />
        </Col>
        <Col span={12}>
          <CustomCheckbox<FormInput> name="isTestCp" label="Тестовая ЭЗС" />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="hubId"
            label="Хаб"
            options={hubs.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="dipLinkCharby"
            label="dipLinkCharby"
            errorMessage={errors.dipLinkCharby?.message}
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="userLocationCheckDistance"
            label="Максимальное расстояние до пользователя"
            errorMessage={errors.userLocationCheckDistance?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="workingTime"
            label="Время работы ЭЗС"
            errorMessage={errors.workingTime?.message}
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="publicDescription"
            label="Публичное описание"
            errorMessage={errors.publicDescription?.message}
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="warningText"
            label="Текст предупреждения для пользователя"
            errorMessage={errors.warningText?.message}
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
