import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { useUpdateConnector } from 'shared/api/services/chargepoint/enhanced';
import {
  ConnectorType,
  ConnectorVm,
} from 'shared/api/services/chargepoint/generated';

import {
  CONNECTOR_FORMAT,
  CONNECTOR_STATUS,
  CONNECTOR_TYPE,
  CONNECTOR_TYPE_ICON,
} from 'entities/connector';

import { FormSchema, FormSchemaType } from '../../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../../consts';

import { FormRow } from '../../../../../common-styles';

import { ImageContainer, ImageText, Image, FormLayout } from './styles';

type Props = {
  chargePointId: string;
  closeModal: () => void;
  connector: ConnectorVm;
};

export function Form({ chargePointId, closeModal, connector }: Props) {
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...connector },
  });

  const type = form.watch('type');

  const [trigger, { isLoading }] = useUpdateConnector();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        chargePointsId: chargePointId,
        connectorId: connector.id,
        updateConnectorRequest: data,
      }).unwrap();

      if (res.status === 'ERROR') {
        openErrorNotification(res.statusMessage);

        return;
      }

      openSuccessNotification(EDIT_SUCCESS);

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <FormLayout>
          <Form.ConnectorImage type={type} />
          <form onSubmit={handleSubmit}>
            <Form.Fields />
            <Form.Buttons />
          </form>
        </FormLayout>
      </Spin>
    </FormProvider>
  );
}

type ImageProps = {
  type?: ConnectorType;
};

Form.ConnectorImage = function ConnectorImage({ type }: ImageProps) {
  const content = !type ? (
    <ImageText>Выбрать тип коннектора</ImageText>
  ) : (
    <Image src={CONNECTOR_TYPE_ICON[type]} />
  );

  return <ImageContainer>{content}</ImageContainer>;
};

Form.Fields = function Fields() {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormSchemaType>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput
            control={control}
            name="maxPower"
            label="Мощность"
            errorMessage={errors.maxPower?.message}
            required
          />
        </Col>
        <Col span={12}>
          <CustomSelect
            control={control}
            name="type"
            label="Тип"
            errorMessage={errors.type?.message}
            options={Object.entries(CONNECTOR_TYPE).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect
            control={control}
            name="status"
            label="Статус"
            errorMessage={errors.status?.message}
            options={Object.entries(CONNECTOR_STATUS).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput
            control={control}
            name="innerConnectorId"
            label="ID"
            errorMessage={errors.innerConnectorId?.message}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput
            control={control}
            name="liter"
            label="Литер"
            errorMessage={errors.liter?.message}
            required
          />
        </Col>
        <Col span={12}>
          <CustomSelect
            control={control}
            name="format"
            label="Формат"
            errorMessage={errors.status?.message}
            options={Object.entries(CONNECTOR_FORMAT).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput
            control={control}
            name="maxVoltage"
            label="Max V"
            errorMessage={errors.maxVoltage?.message}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput
            control={control}
            name="maxAmperage"
            label="Max A"
            errorMessage={errors.maxAmperage?.message}
            required
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
