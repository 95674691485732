import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import {
  ADD_PREFIX,
  GROUP,
  GROUPS,
  RESERVES,
} from 'shared/consts/routes/common';
import {
  BANK_CARD_SESSIONS,
  BINDINGS,
  PERSON,
  PERSONS,
  PERSONS_LIST,
  PROFILE,
  TRANSACTIONS,
} from 'shared/consts/routes/person';

import { RequireAuth } from 'entities/authorization';

import { UserGroupsMenu } from 'features/user-group/menu';

import { UserGroupListPage } from 'pages/user-group/list';
import { UserListPage } from 'pages/user/list';
import { EditUserGroupPage } from 'pages/user-group/edit';
import { UserGroupUserListPage } from 'pages/user-group/user-list';
import { AddUserPage } from 'pages/user/add';
import { AddUserGroupPage } from 'pages/user-group/add';
import { UserGroupSessionsPage } from 'pages/user-group/sessions';
import { UsersSessionsPage } from 'pages/user/sessions';
import { UserSessionsPage } from 'pages/user/user-sessions';
import { EditUserPage } from 'pages/user/edit';
import { SESSIONS } from 'shared/consts/names';
import { UserLayout } from 'entities/user/ui/user-layout';
import { UsersLayout } from 'entities/user/ui/users-layout';
import { UserGroupLayout } from 'entities/user-group';
import { UserCardListPage } from 'pages/user/cards-list';

export const PersonRoutes = (
  <Route element={<RequireAuth controllerName="Person" rightType="Read" />}>
    <Route path={PERSONS} element={<SectionLayout menu={<UserGroupsMenu />} />}>
      <Route element={<UsersLayout />}>
        <Route index element={<UserListPage />} />
        <Route
          element={<RequireAuth controllerName="Group" rightType="Read" />}
        >
          <Route path={GROUPS} element={<UserGroupListPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={SESSIONS} element={<UsersSessionsPage />} />
        </Route>
        {/* <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={RESERVES} element={<PersonsReservesPage />} />
        </Route> */}
      </Route>

      <Route element={<RequireAuth controllerName="Group" rightType="Read" />}>
        <Route path={GROUP} element={<UserGroupLayout />}>
          <Route index element={<EditUserGroupPage />} />
          <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route path={PERSONS_LIST} element={<UserGroupUserListPage />} />
          </Route>
          <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route path={SESSIONS} element={<UserGroupSessionsPage />} />
          </Route>
          {/* <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route path="reserves" element={<PersonsGroupReservesPage />} />
          </Route> */}
        </Route>
      </Route>

      <Route element={<RequireAuth controllerName="Group" rightType="Write" />}>
        <Route
          path={`${GROUPS}/${ADD_PREFIX}`}
          element={<MainContentLayout element={<AddUserGroupPage />} />}
        />
      </Route>

      <Route
        element={<RequireAuth controllerName="Person" rightType="Write" />}
      >
        <Route path={ADD_PREFIX} element={<AddUserPage />} />
      </Route>

      <Route path={`${GROUP}/${PERSON}`} element={<UserLayout />}>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route index element={<UserSessionsPage />} />
        </Route>
        {/* <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={RESERVES} element={<PersonReservesPage />} />
        </Route> */}
        <Route path={BINDINGS} element={<UserCardListPage />} />
        <Route
          path={BANK_CARD_SESSIONS}
          element={<div>PersonBankCardSessionsPage</div>}
        />
        <Route path={PROFILE} element={<EditUserPage />} />
        {/* <Route path={TRANSACTIONS} element={<PersonTransactionLogPage />} /> */}
      </Route>
    </Route>
  </Route>
);
