import { NavButton } from './button';

import { PaginationContrplsContainer } from './styles';

type Props = {
  prevDisabled: boolean;
  nextDisabled: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
};

export function NavButtons({
  prevDisabled,
  nextDisabled,
  onPrevClick,
  onNextClick,
}: Props) {
  return (
    <PaginationContrplsContainer>
      <NavButton type="prev" onClick={onPrevClick} disabled={prevDisabled} />
      <NavButton type="next" onClick={onNextClick} disabled={nextDisabled} />
    </PaginationContrplsContainer>
  );
}
