import { MainLayout } from 'shared/ui/layout/main';

import { PersonsGroupFetchingContextProvider } from '../../context';
import { TabMenu } from '../tab-menu';

export function UserGroupLayout() {
  return (
    <PersonsGroupFetchingContextProvider>
      <MainLayout tabs={<TabMenu />} />
    </PersonsGroupFetchingContextProvider>
  );
}
