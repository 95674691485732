import { ROLES } from 'shared/consts/routes/role';
import { USERS } from 'shared/consts/routes/user';
import { GROUPS } from 'shared/consts/routes/group';
import { useAbility } from 'shared/lib/ability/context';

import { SectionNavMenuItem } from 'features/sidebar/types';
import { UsersIcon } from 'features/sidebar/icons/users';
import { GroupsIcon } from 'features/sidebar/icons/groups';
import { RolesIcon } from 'features/sidebar/icons/roles';

import { SidebarLayout } from '../layout';

const ITEMS: SectionNavMenuItem[] = [
  {
    label: 'Пользователи',
    path: USERS,
    icon: <UsersIcon />,
    permissions: [
      {
        controller: 'Identity',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Группы',
    path: GROUPS,
    icon: <GroupsIcon />,
    permissions: [
      {
        controller: 'Identity',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Роли',
    path: ROLES,
    icon: <RolesIcon />,
    permissions: [
      {
        controller: 'Identity',
        right: 'Read',
      },
    ],
  },
];

export function SecuritySidebar() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter((item) =>
    item.permissions.every((item) => {
      const { controller, right } = item;

      return ability.can(right, controller);
    })
  );

  return <SidebarLayout items={allowedItems} />;
}
