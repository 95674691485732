import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const StyledLayout = styled.div`
  grid-area: sider;
  overflow-x: hidden;
  border-right: ${DEFAULT_BORDER};
  display: flex;
  flex-direction: column;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${COLOR.white};
  flex-grow: 1;
`;
