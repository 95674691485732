import styled from 'styled-components';
import { MoreOutlined } from '@ant-design/icons';

import { COLOR } from 'shared/consts';
import { getRGBAColor } from 'shared/lib';
import {
  ChargePointStatus,
  SpeedType,
} from 'shared/api/services/chargepoint/generated';

import {
  CHARGE_POINT_STATUS_COLOR,
  CHARGE_POINT_SPEED_TYPE_COLOR,
} from 'entities/charge-point';

export const StyledCard = styled.article`
  padding: 20px;
  max-width: 267px;
  background: #ffffff;
  border-radius: 8px;
  border: none;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const CardHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
`;

export const ChargePointName = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const ParamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ChargePointTitle = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-end;
`;

export const Param = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ParamLabel = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
`;

export const ParamValue = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
`;

export const TypeBadge = styled.p<{
  speedType: Exclude<SpeedType, 'UNDEFINED'>;
}>`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => CHARGE_POINT_SPEED_TYPE_COLOR[props.speedType]};
`;

export const StatusBadge = styled.div<{ status: ChargePointStatus }>`
  padding: 2px 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  background-color: ${(props) =>
    getRGBAColor(CHARGE_POINT_STATUS_COLOR[props.status], '0.15')};
  color: ${(props) => CHARGE_POINT_STATUS_COLOR[props.status]};
`;

export const StyledMoreOutlined = styled(MoreOutlined)`
  margin-left: auto;
`;

export const OwnerInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OwnerIcon = styled.img``;

export const MaxPowerContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const TariffsDetailsTitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const TariffsDetailsTitle = styled.p`
  color: ${COLOR.black};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const TariffDetailsIcon = styled.img`
  cursor: pointer;
`;

export const TariffDetailsContainer = styled.div`
  display: flex;
  gap: 7px;
`;
