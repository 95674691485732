import { baseApi as api } from '../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiReserveActionReserveByGuidTargetReserve: build.mutation<
      PostApiReserveActionReserveByGuidTargetReserveApiResponse,
      PostApiReserveActionReserveByGuidTargetReserveApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Action/Reserve/${queryArg.guidTargetReserve}`,
        method: 'POST',
        body: queryArg.addReserveRequest,
      }),
    }),
    postApiReserveActionResetByGuidTargetReserve: build.mutation<
      PostApiReserveActionResetByGuidTargetReserveApiResponse,
      PostApiReserveActionResetByGuidTargetReserveApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Action/Reset/${queryArg.guidTargetReserve}`,
        method: 'POST',
        params: { UserId: queryArg.userId },
      }),
    }),
    postApiReserveActionExtendByGuidTargetReserve: build.mutation<
      PostApiReserveActionExtendByGuidTargetReserveApiResponse,
      PostApiReserveActionExtendByGuidTargetReserveApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Action/Extend/${queryArg.guidTargetReserve}`,
        method: 'POST',
        params: { timeSpan: queryArg.timeSpan, userId: queryArg.userId },
      }),
    }),
    getApiReserveReserves: build.query<
      GetApiReserveReservesApiResponse,
      GetApiReserveReservesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Reserves`,
        params: {
          UserId: queryArg.userId,
          GuidTargetReserve: queryArg.guidTargetReserve,
          IsActive: queryArg.isActive,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    getApiReserveReservesById: build.query<
      GetApiReserveReservesByIdApiResponse,
      GetApiReserveReservesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Reserve/Reserves/${queryArg}` }),
    }),
    getApiReserveReservesCount: build.query<
      GetApiReserveReservesCountApiResponse,
      GetApiReserveReservesCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Reserves/Count`,
        params: {
          UserId: queryArg.userId,
          GuidTargetReserve: queryArg.guidTargetReserve,
          IsActive: queryArg.isActive,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    getApiReserveSessionsById: build.query<
      GetApiReserveSessionsByIdApiResponse,
      GetApiReserveSessionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Reserve/Sessions/${queryArg}` }),
    }),
    getApiReserveSessions: build.query<
      GetApiReserveSessionsApiResponse,
      GetApiReserveSessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Sessions`,
        params: {
          UserId: queryArg.userId,
          GuidTargetReserve: queryArg.guidTargetReserve,
          BeginDateFrom: queryArg.beginDateFrom,
          BeginDateTo: queryArg.beginDateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          LastUpdateFrom: queryArg.lastUpdateFrom,
          LastUpdateTo: queryArg.lastUpdateTo,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    getApiReserveSessionsCount: build.query<
      GetApiReserveSessionsCountApiResponse,
      GetApiReserveSessionsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Sessions/Count`,
        params: {
          UserId: queryArg.userId,
          GuidTargetReserve: queryArg.guidTargetReserve,
          BeginDateFrom: queryArg.beginDateFrom,
          BeginDateTo: queryArg.beginDateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          LastUpdateFrom: queryArg.lastUpdateFrom,
          LastUpdateTo: queryArg.lastUpdateTo,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiReserveActionReserveByGuidTargetReserveApiResponse =
  /** status 200 Success */ AddReserveResponseBaseResponse;
export type PostApiReserveActionReserveByGuidTargetReserveApiArg = {
  /** Идентификатор обьекта резерва */
  guidTargetReserve: string;
  /** Параметры */
  addReserveRequest: AddReserveRequest;
};
export type PostApiReserveActionResetByGuidTargetReserveApiResponse =
  /** status 200 Success */ ResetReserveResponseBaseResponse;
export type PostApiReserveActionResetByGuidTargetReserveApiArg = {
  /** Идентификатор обьекта резерва */
  guidTargetReserve: string;
  /** Фильтр, разрешающий снять резерв если юзер совпадает с текущем юзером брони */
  userId?: string;
};
export type PostApiReserveActionExtendByGuidTargetReserveApiResponse =
  /** status 200 Success */ ExtendReserveResponseBaseResponse;
export type PostApiReserveActionExtendByGuidTargetReserveApiArg = {
  /** Идентификатор обьекта резерва */
  guidTargetReserve: string;
  /** Временной интервал на который надо увеличить резерв относительно текущего времени */
  timeSpan?: string;
  userId?: string;
};
export type GetApiReserveReservesApiResponse =
  /** status 200 Success */ GetReservesResponseListBaseResponse;
export type GetApiReserveReservesApiArg = {
  userId?: string;
  guidTargetReserve?: string[];
  isActive?: boolean;
  limit?: number;
  offset?: number;
};
export type GetApiReserveReservesByIdApiResponse =
  /** status 200 Success */ GetReservesResponseBaseResponse;
export type GetApiReserveReservesByIdApiArg =
  /** Идентификатор резерва */ string;
export type GetApiReserveReservesCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiReserveReservesCountApiArg = {
  userId?: string;
  guidTargetReserve?: string[];
  isActive?: boolean;
  limit?: number;
  offset?: number;
};
export type GetApiReserveSessionsByIdApiResponse =
  /** status 200 Success */ GetSessionResponseBaseResponse;
export type GetApiReserveSessionsByIdApiArg =
  /** Идентификатор обьекта резерва */ string;
export type GetApiReserveSessionsApiResponse =
  /** status 200 Success */ GetSessionResponseListBaseResponse;
export type GetApiReserveSessionsApiArg = {
  userId?: string;
  guidTargetReserve?: string;
  beginDateFrom?: string;
  beginDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  lastUpdateFrom?: string;
  lastUpdateTo?: string;
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type GetApiReserveSessionsCountApiResponse =
  /** status 200 Success */ Int32BaseResponse;
export type GetApiReserveSessionsCountApiArg = {
  userId?: string;
  guidTargetReserve?: string;
  beginDateFrom?: string;
  beginDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  lastUpdateFrom?: string;
  lastUpdateTo?: string;
  offset?: number;
  limit?: number;
};
export type Status = 0 | 1 | 2;
export type UnavailableServices =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21;
export type AddReserveResponse = {
  id: string;
  guidTargetReserve: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
};
export type AddReserveResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddReserveResponse;
};
export type AddReserveRequest = {
  userId: string;
  timeSpan: string;
};
export type ResetReserveResponse = {
  id: string;
  guidTargetReserve: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
};
export type ResetReserveResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: ResetReserveResponse;
};
export type ExtendReserveResponse = {
  id: string;
  guidTargetReserve: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
};
export type ExtendReserveResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: ExtendReserveResponse;
};
export type GetReservesResponse = {
  id: string;
  guidTargetReserve: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
};
export type GetReservesResponseListBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetReservesResponse[] | null;
};
export type GetReservesResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetReservesResponse;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type GetSessionResponse = {
  id: string;
  guidTargetReserve: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
  lastUpdate: string;
};
export type GetSessionResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionResponse;
};
export type GetSessionResponseListBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionResponse[] | null;
};
export type OrderBy = 'ASC' | 'DESC';
export type Int32BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export const {
  usePostApiReserveActionReserveByGuidTargetReserveMutation,
  usePostApiReserveActionResetByGuidTargetReserveMutation,
  usePostApiReserveActionExtendByGuidTargetReserveMutation,
  useGetApiReserveReservesQuery,
  useGetApiReserveReservesByIdQuery,
  useGetApiReserveReservesCountQuery,
  useGetApiReserveSessionsByIdQuery,
  useGetApiReserveSessionsQuery,
  useGetApiReserveSessionsCountQuery,
} = injectedRtkApi;
